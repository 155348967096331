import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthContextProvider } from './context/AuthContext';
import i18next from 'i18next'
import { I18nextProvider } from 'react-i18next';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <I18nextProvider i18n = {i18next}>
   <React.StrictMode>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
      </React.StrictMode>
  </I18nextProvider>
);


