import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Bu satır zaten projenizde mevcut olmalı
import '../../App.css'

// İkonunuzu import edin
import successIcon from '../../images/icons/Radio.svg'; // İkonun dosya yolunu belirtin

export const notifyVerificationLinkSent = () => {
  toast.success(
    <>
      <img src={successIcon} alt="success" className="custom-toast-icon" />
      Verification link sent to team member's email!
    </>, 
    {
      className: 'custom-toast-success',
      icon: false // Varsayılan ikonu devre dışı bırakın
    }
  );
};

export const notifyNotificationSent = () => {
  toast.success(
    <>
      <img src={successIcon} alt="success" className="custom-toast-icon" />
      Notification Sent Successfully!
    </>, 
    {
      className: 'custom-toast-success',
      icon: false // Varsayılan ikonu devre dışı bırakın
    }
  );
};

export const notifyProfileImageUpdated = () => {
  toast.success(
    <>
      <img src={successIcon} alt="success" className="custom-toast-icon" />
      Profile image updated successfully!
    </>, 
    {
      className: 'custom-toast-success',
      icon: false // Varsayılan ikonu devre dışı bırakın
    }
  );
};

export const notifyImageUpdated = () => {
  toast.success(
    <>
      <img src={successIcon} alt="success" className="custom-toast-icon" />
      Image updated successfully!
    </>, 
    {
      className: 'custom-toast-success',
      icon: false // Varsayılan ikonu devre dışı bırakın
    }
  );
};

export const notifyProfileUpdated = () => {
  toast.success(
    <>
      <img src={successIcon} alt="success" className="custom-toast-icon" />
      Profile Updated Successfully!
    </>, 
    {
      className: 'custom-toast-success',
      icon: false // Varsayılan ikonu devre dışı bırakın
    }
  );
};

export const notifyTeamMemberRemoved = () => {
  toast.success(
    <>
      <img src={successIcon} alt="success" className="custom-toast-icon" />
      Team member removed successfully!
    </>, 
    {
      className: 'custom-toast-success',
      icon: false // Varsayılan ikonu devre dışı bırakın
    }
  );
};

export const notifyTeamMemberRegistered = () => {
  toast.success(
    <>
      <img src={successIcon} alt="success" className="custom-toast-icon" />
      Team member registered successfully!
    </>, 
    {
      className: 'custom-toast-success',
      icon: false // Varsayılan ikonu devre dışı bırakın
    }
  );
};

export const notifyPasswordResetEmailSent = () => {
  toast.success(
    <>
      <img src={successIcon} alt="success" className="custom-toast-icon" />
      Password reset email sent. Check your inbox.
    </>, 
    {
      className: 'custom-toast-success',
      icon: false // Varsayılan ikonu devre dışı bırakın
    }
  );
};

export const notifyRegistrationSuccessful = () => {
  toast.success(
    <>
      <img src={successIcon} alt="success" className="custom-toast-icon" />
      Registration successful! Please check your email to verify your account.
    </>, 
    {
      className: 'custom-toast-success',
      icon: false // Varsayılan ikonu devre dışı bırakın
    }
  );
};
