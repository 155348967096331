import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from "../../firebase";
import { db } from "../../firebase";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { toast } from 'react-toastify';
import { t } from 'i18next';
import CircularProgress from '@mui/material/CircularProgress';

const InviteHandler = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const clientId = urlParams.get('clientID');

    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const doctorUid = user.uid;
        try {
          const doctorRef = doc(db, 'doctors', doctorUid);

          await updateDoc(doctorRef, {
            clients: arrayUnion(clientId)
          });

          toast.success(t('patientAdded'));
          navigate('/');
        } catch (error) {
          console.error('Hata:', error);
          toast.error(t('errorOccurred', { error: error.message }));
        } finally {
          setLoading(false);
        }
      } else {
        localStorage.setItem('clientId', clientId);
        localStorage.setItem('redirectAfterLogin', window.location.pathname + window.location.search);
        setLoading(false);
        navigate('/login');
      }
    });
  }, [navigate]);

  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      {loading ? (
        <CircularProgress />
      ) : (
        <p>İşleminiz gerçekleştiriliyor, lütfen bekleyiniz...</p>
      )}
    </div>
  );
};

export default InviteHandler;
