import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom"; 
import logo from '../../images/icons/Content.svg';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./login.css";
import './ForgetPassword.css';
import arrow from '../../images/icons/arrow-left.svg';

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleForgetPassword = async (e) => {
    e.preventDefault();

    try {
      const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: `${window.location.origin}/reset-password`,
        // This must be true.
        handleCodeInApp: true,
      };

      await sendPasswordResetEmail(auth, email, actionCodeSettings);
      toast.success(t('Reset link sent! Check your email.'));
      navigate('/checkemail');
    } catch (error) {
      toast.error(t('Error sending reset link. Please try again.'));
      console.error(error);
    }
  };

  return (
    <div className="login">
      <div className="container">
        <div className="subcont">
          <div className="login-header">
            <img src={logo} alt="Realhealth Logo" className="logo-img" />
            <span className="logo-title">Realhealth</span>
          </div>
          <div className="form-container">
            <h2 className="form-title-forget text-center">{t('Forgot Password?')}</h2>
            <p className="form-description-forget text-center" style={{color:'#677184'}}>{t('No worries, we’ll send you reset instructions.')}</p>
            <div className="button-group-forget">
            <label htmlFor="email" className="input-label">{t("Email")}</label>
              <form onSubmit={handleForgetPassword}>
                <input 
                  type="email" 
                  placeholder={t("Enter your email")} 
                  className="form-input" 
                  value={email}
                  onChange={(e) => setEmail(e.target.value)} 
                />
                <button type="submit" className="form-button">{t('Send Reset Link')}</button>
                <a className="forget-password" style={{color:'#677184'}} href="/login">
                  <img src={arrow} alt="arrow" width={'20px'} height={'20px'} /> {t("Back to log in")}
                </a>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
