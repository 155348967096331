import React from 'react'
import NavbarMenu from '../../components/navbar/NavbarMenu'
import ProfilePage from '../../components/profile/ProfilePage'

function Home() {

  return (
    <div>
      <NavbarMenu/>
      <ProfilePage/>
    </div>
  )
}

export default Home