const healthDataen = {
    healthkit: {
      healthScore: "Health Score",
      activityEnergy: "Activity Energy",
      bloodPressure: "Blood Pressure",
      bodyFatPercente: "Body Fat Percentage",
      bodyMassIndex: "Body Mass Index",
      cardioRecovery: "Cardio Recovery",
      exerciseTime: "Exercise Time",
      flightsClimbed: "Flights Climbed",
      heartRate: "Heart Rate",
      heartRateVeriability: "Heart Rate Variability",
      leanBodyMass: "Lean Body Mass",
      oxygenSaturation: "Oxygen Saturation",
      respiratoryRate: "Respiratory Rate",
      restingEnergy: "Resting Energy",
      restingHeartRate: "Resting Heart Rate",
      standHours: "Stand Hours",
      steps: "Steps",
      vo2Max: "VO2 Max",
      walkingDistance: "Walking Distance",
      walkingHeartRate: "Walking Heart Rate"
    },
    units: {
      vo2Max: "mL/kg/min",
      heartRate: "bpm",
      oxygenSaturation: "%",
      standHours: "hours",
      heartRateVeriability: "ms",
      walkingDistance: "meters",
      steps: "steps",
      walkingHeartRate: "bpm",
      respiratoryRate: "breaths/min",
      restingHeartRate: "bpm",
      bloodPressure: "mmHg",
      restingEnergy: "kcal",
      flightsClimbed: "flights",
      exerciseTime: "minutes",
      activityEnergy: "kcal",
      cardioRecovery: "kcal"
    },
    categories: {
        'General Health Metrics': 'General Health Metrics',
        'Exercise and Activity': 'Exercise and Activity',
        'Heart and Respiratory Metrics': 'Heart and Respiratory Metrics',
        'Body Composition': 'Body Composition',
        'Metabolic Metrics': 'Metabolic Metrics'
      }
  };
  export default healthDataen;