// BloodComponent.jsx
import React, { useEffect, useState,useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FcmBloodSender from '../../notification/FcmBloodSender';
import CommentList from '../../notification/CommentList';
import months from '../../constants/months';
import './userDetails.css'
const BloodComponent = ({ user, doctor }) => {
  const { t } = useTranslation();
  const [selectedBloodTestId, setSelectedBloodTestId] = useState(null);
  const [selectedDatesCount, setSelectedDatesCount] = useState(0);

  if (!user || !user.ArtificialIntelligenceBloodData) {
    return <div className="mt-3">{t("No Blood Data Available")}</div>;
  }

  return (
    <div>
      <div className="blood-component">
        <h3 className="component-title">{t("Blood Test Data")}</h3>
        <CompareBloodTests
          bloodTestData={user.ArtificialIntelligenceBloodData}
          setSelectedBloodTestId={setSelectedBloodTestId}
          setSelectedDatesCount={setSelectedDatesCount}
        />
      </div>
      <div className="blood-comment">
        {selectedDatesCount === 1 && (<h3>{t("Comment")}</h3>)}
        <div className="fcm-area">
          {selectedDatesCount === 1 && (
            <FcmBloodSender
              token={user.deviceToken}
              doctorId={doctor.id}
              userId={user.id}
              region={user.region}
              bloodTestId={selectedBloodTestId}
            />
          )}
        </div>
        <div className="last-comment">
          {selectedDatesCount === 1 && (
            <CommentList
              userId={user.id}
              doctorId={doctor.id}
              region={user.region}
              bloodTestId={selectedBloodTestId}
            />
          )}
        </div>
      </div>
    </div>
  );
};

  const CompareBloodTests = ({ bloodTestData, setSelectedBloodTestId, setSelectedDatesCount }) => {
    const { t } = useTranslation();
    const [selectedDates, setSelectedDates] = useState([]);
    const [testsToShow, setTestsToShow] = useState(new Map());
    const [searchQuery, setSearchQuery] = useState('');
    const [availableDates, setAvailableDates] = useState([]);
    const componentMounted = useRef(false);
    const [allTestDetails, setAllTestDetails] = useState(new Map());
    const formatBloodTimestamp = (timestamp, index = 0) => {
      if (!timestamp) return t("Wrong Date Format");

      try {
        const dateParts = timestamp.split("/");
        if (dateParts.length !== 3) throw new Error("Geçersiz tarih formatı");

        const day = parseInt(dateParts[0], 10);
        const month = parseInt(dateParts[1], 10);
        const year = parseInt(dateParts[2], 10);

        const errorMessage = t("Geçersiz tarih değerleri");
        if (isNaN(day) || isNaN(month) || isNaN(year))
          throw new Error(errorMessage);

        // Saat bilgisi ekleme
        const hour = Math.floor(index / 60);
        const minute = index % 60;

        if (isNaN(hour) || isNaN(minute)) throw new Error("Wrong time values");

        const formattedDate = `${day} ${months[month - 1]} ${year} ${hour}:${minute < 10 ? "0" + minute : minute
          }`;

        return formattedDate;
      } catch (error) {
        console.error("Error:", error);
        return t("Invalid Date");
      }
    };
    useEffect(() => {
      if (!bloodTestData) return;

      const allTestDetails = processTestEntries(bloodTestData);

      // Generate date entries with Date objects for sorting
      const dateEntries = Object.keys(bloodTestData).map((id) => {
        const timestamp = bloodTestData[id].timestamp;
        const formattedDate = formatBloodTimestamp(timestamp);

        const [dayStr, monthStr, yearStr] = timestamp.split("/");
        const day = parseInt(dayStr, 10);
        const month = parseInt(monthStr, 10) - 1; // JS months are 0-based
        const year = parseInt(yearStr, 10);

        const dateObj = new Date(year, month, day);

        return { id, formattedDate, dateObj };
      });

      // Sort the date entries by dateObj in descending order
      dateEntries.sort((a, b) => b.dateObj - a.dateObj);

      const dates = dateEntries.map(entry => entry.formattedDate);
      setAvailableDates([...new Set(dates)]);

      // Only set the default date on initial mount
      if (!componentMounted.current && dates.length > 0) {
        setSelectedDates([dates[0]]);
        componentMounted.current = true;
      }

      setAllTestDetails(allTestDetails);
    }, [bloodTestData]);

    useEffect(() => {
      if (!allTestDetails) return;

      updateTestsToShow(allTestDetails, selectedDates);
    }, [allTestDetails, selectedDates]);

    useEffect(() => {
      setSelectedDatesCount(selectedDates.length);
      if (selectedDates.length === 1) {
        const bloodTestId = Object.keys(bloodTestData).find(id => {
          const { timestamp } = bloodTestData[id];
          const formattedDate = formatBloodTimestamp(timestamp);
          return formattedDate === selectedDates[0];
        });
        setSelectedBloodTestId(bloodTestId);
      } else {
        setSelectedBloodTestId(null);
      }
    }, [selectedDates, bloodTestData, setSelectedDatesCount, setSelectedBloodTestId]);
    function processTestEntries(testEntries) {
      const uniqueTestDetails = new Map();
      for (const [id, { result, timestamp }] of Object.entries(testEntries)) {
        const formattedDate = formatBloodTimestamp(timestamp);

        result.split("€").forEach((testString) => {
          const [testName, testValue, testUnit, testRange] = testString.split("$");
          if (testName && testUnit && testRange) {
            const testKey = `${testName}-${testUnit}-${testRange}`;

            if (!uniqueTestDetails.has(testKey)) {
              uniqueTestDetails.set(testKey, {
                testName,
                testUnit,
                testRange,
                dates: new Set(),
                values: new Map(),
              });
            }

            uniqueTestDetails.get(testKey).dates.add(formattedDate);
            if (!uniqueTestDetails.get(testKey).values.has(formattedDate)) {
              uniqueTestDetails.get(testKey).values.set(formattedDate, []);
            }
            uniqueTestDetails.get(testKey).values.get(formattedDate).push(testValue);
          }
        });
      }
      return uniqueTestDetails;
    }

    function updateTestsToShow(allTestDetails, selectedDates) {
      let filteredTests = new Map();

      if (selectedDates.length === 0) {
        setTestsToShow(filteredTests); // Empty Map
        return;
      }

      allTestDetails.forEach((value, key) => {
        if (selectedDates.some(date => value.dates.has(date))) {
          filteredTests.set(key, value);
        }
      });
      setTestsToShow(filteredTests);
    }


    const handleDateChange = (e) => {
      const date = e.target.value;
      if (date && !selectedDates.includes(date)) {
        setSelectedDates((prev) => [...prev, date].sort((a, b) => new Date(a) - new Date(b)));
        e.target.value = "";
      }
    };

    const removeSelectedDate = (dateToRemove) => {
      setSelectedDates((prev) => prev.filter(date => date !== dateToRemove));
    };

    function isValueOutOfRange(values, minRange, maxRange) {
      if (!values) return false;

      for (const value of values) {
        const numericValue = parseFloat(value.replace(",", "."));
        if (numericValue < parseFloat(minRange) || numericValue > parseFloat(maxRange)) {
          return true;
        }
      }
      return false;
    }

    const filteredTestsToShow = Array.from(testsToShow.keys()).filter(key =>
      key.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
      <div>
        <div className="row">
          <div className="col-md-12 d-flex justify-content-between align-items-center p-5">
            <div className="search-bar-container">
              <input
                type="text"
                className="form-control"
                id="searchInput"
                placeholder={t("Search Blood Data")}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className="selected-dates-container ms-2">
              {selectedDates.map((date) => (
                <div key={date} className="selected-date">
                  {date}
                  <button
                    type="button"
                    className="btn btn-sm btn-danger ms-2"
                    onClick={() => removeSelectedDate(date)}
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
            <div className="d-flex flex-wrap align-items-center">
              {availableDates.length > 0 ? (
                <select className="form-select ms-2" onChange={handleDateChange} defaultValue="">
                  <option value="" disabled>{t("Select date")}</option>
                  {availableDates.map((date) => (
                    <option key={date} value={date}>
                      {date}
                    </option>
                  ))}
                </select>
              ) : (
                <p>{t("No blood test data available.")}</p>
              )}
            </div>
          </div>
          <div className="table-responsive col-12">
            <table className="table table-striped table-hover table-bordered blood-data-table">
              <thead>
                <tr>
                  <th>{t("Test Name")}</th>
                  {selectedDates.map((date) => (
                    <th key={date}>{date}</th>
                  ))}
                  <th>{t("Reference Range")}</th>
                  <th>{t("Unit")}</th>
                </tr>
              </thead>
              <tbody>
                {filteredTestsToShow.length > 0 ? (
                  filteredTestsToShow.map((key) => {
                    const { testName, testUnit, testRange, values } = testsToShow.get(key);
                    const [minRange, maxRange] = testRange.split("-");
                    return (
                      <tr key={key}>
                        <td>{testName}</td>
                        {selectedDates.map((date) => (
                          <td
                            key={`${key}-${date}`}
                            style={{
                              color: isValueOutOfRange(values.get(date), minRange, maxRange)
                                ? "red"
                                : "inherit",
                            }}
                          >
                            {values.get(date)
                              ? values.get(date).map(value => parseFloat(value.replace(",", ".")).toFixed(2)).join(", ")
                              : "N/A"}
                          </td>
                        ))}
                        <td>{testRange}</td>
                        <td>{testUnit}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={selectedDates.length + 3}>{t("No test data available for selected dates.")}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

export default BloodComponent;
