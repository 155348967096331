import React, { useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import './timeframebuttons.css'
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { useTranslation } from 'react-i18next'; 


const BarCharts = ({ type, data, unit }) => {
  const [selectedTimeFrame, setSelectedTimeFrame] = useState("daily");
  const { t } = useTranslation();
  
  // Verilen zaman dilimine göre veriyi filtreleyin
  const filteredData = data[selectedTimeFrame] || [];

  let effectiveDataKey;

  // Zaman dilimine göre dataKey'i ayarlayın
  if (selectedTimeFrame === t('yearly') || selectedTimeFrame === 'sixMonths') {
    effectiveDataKey = 'monthYear';
  } else {
    effectiveDataKey = 'dayMonth';
  }
  
  // Zaman diliminde veri olup olmadığını kontrol edin
  const hasData = filteredData.length > 0; // Veri var mı diye kontrol ediyoruz.

  // Zaman dilimi seçim butonlarını oluştur
  const renderTimeFrameButtons = () =>
    ["daily", "weekly", "monthly", "sixMonths", "yearly"].map((frame) => (
      <Button
        key={frame}
        style={{
          backgroundColor: selectedTimeFrame === frame ? "#7978DE" : "#f8f9fa",
          borderColor: "none",
          color: selectedTimeFrame === frame ? "#fff" : "#000",
          border: "none"
        }}
        className="timeframe-button"
        onClick={() => setSelectedTimeFrame(frame)}
      >
        {t(frame.charAt(0).toUpperCase() + frame.slice(1))}
      </Button>
    ));
  const customTooltipFormatter = (value) => {
    return [`${t(type)}: ${value} ${t(unit)}`];
  };
    
  if (!data || data.length === 0) {
    return (
      <div style={{ textAlign: "center", margin: "20px" }}>
        <h3>{`${t(type)} ${t('Value')}`} </h3>
        <p>{t('No Data')}</p>
      </div>
    );
  }

  return (
    <div className="text-center">
      <ButtonGroup className="py-2 time-category">{renderTimeFrameButtons()}</ButtonGroup>
      {hasData ? (
        <ResponsiveContainer width="100%">
          <BarChart data={filteredData}>
            <Bar
              dataKey="value"
              fill="#7978DE"
              className="custom-bar" // Add a class for custom styling
              barSize={30}
              name={`${t(type)} ${t('Value')}`}
            />
            <CartesianGrid stroke="#E2E2E2" />
            <XAxis
              dataKey={effectiveDataKey}
              scale="point"
              padding={{ left: 20, right: 20 }}
            />
            <YAxis />
            <Legend />
            <Tooltip formatter={customTooltipFormatter} />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        // Veri yoksa bilgilendirme mesajı göster
        <div style={{ textAlign: "center", marginTop: "2rem" }}>
          <p>{t('nodataavailable')}</p>
        </div>
      )}
    </div>
  );
};

export default BarCharts;
