export const specializations = [
    'Advanced Limb Reconstruction',
    'Aesthetic, Plastic and Reconstructive Surgery',
    'Allergic Diseases',
    'Alzheimer’s Disease and Aging',
    'Andrology',
    'Anesthesiology',
    'Audiology',
    'Bone Marrow Transplant Unit - Adult',
    'Bone Marrow Transplantation Unit - Pediatric',
    'Breast Clinic',
    'Breast Health',
    'Cardiac Health',
    'Cardiology',
    'Cardiopulmonary Rehabilitation',
    'Cardiovascular Surgery',
    'Check-Up',
    'Child and Adolescent Health',
    'Clinical Laboratory',
    'Coronary Intensive Care Unit',
    'Cosmetic Dermatology',
    'Dermatology',
    'Diagnosis and Treatment of Prostate Diseases',
    'Emergency Service',
    'Endocrine Surgery',
    'Endocrinology',
    'Endoscopy',
    'Family Medicine',
    'Foot and Ankle Surgery',
    'Gait analysis',
    'Gastroenterology',
    'Gastroenterology and Coloproctologic Surgery',
    'General Surgery',
    'Growth and Adolescence',
    'Gynecologic Oncology',
    'Gynecology and Obstetrics',
    'Hair Transplant',
    'Hand Surgery',
    'Headache Treatment',
    'Hematology',
    'Hemorrhoid and Anorectal Diseases',
    'In Vitro Fertilization Centers',
    'Infectious Diseases and Clinical Microbiology',
    'Inpatient Clinic Physicians',
    'Intensive Care',
    'Internal Medicine',
    'Interventional Radiology',
    'Kidney Transplant Center',
    'lifeClub',
    'Liver Transplant Center',
    'Medical Genetics',
    'Menopause',
    'Metabolic Surgery',
    'Neonatal Intensive Care Unit',
    'Nephrology',
    'Neurology',
    'Neurosurgery',
    'Nuclear Medicine',
    'Nutrition and Diet',
    'Occupational Medicine',
    'Oncology',
    'Ophthalmology',
    'Oral and Dental Health',
    'Oral, Dental and Maxillofacial Surgery',
    'Orthopedic Oncology',
    'Orthopedics and Traumatology',
    'Otorhinolaryngology',
    'Pathology',
    'Pedagogy',
    'Pediatric Allergy',
    'Pediatric and Adolescent Psychiatry',
    'Pediatric Cardiology',
    'Pediatric Endocrinology',
    'Pediatric Gastroenterology',
    'Pediatric Genetic Diseases',
    'Pediatric Hand Surgery',
    'Pediatric Hematology',
    'Pediatric Infectious Diseases',
    'Pediatric Intensive Care Unit',
    'Pediatric Nephrology',
    'Pediatric Neurology',
    'Pediatric Neurosurgery',
    'Pediatric Oncology',
    'Pediatric Rehabilitation',
    'Pediatric Rheumatology',
    'Pediatric Surgery',
    'Pediatric Urology',
    'Pediatrics',
    'Perinatology and High-Risk Pregnancies',
    'Periodontolgy',
    'Physical Medicine and Rehabilitation',
    'podology (Foot Health)',
    'Psychiatry',
    'Psychology',
    'Pulmonary Medicine',
    'Radiation Oncology',
    'Radiology',
    'Rheumatology',
    'Robot-assisted Surgery',
    'Robotic Rehabilitation',
    'Shoulder and Elbow Treatment',
    'Smoke-Free Life',
    'Speech and Language Pathology',
    'Spine Health',
    'Sports Medicine',
    'Thoracic Surgery',

]