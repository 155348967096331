import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { SidebarContext } from "../../context/SidebarContext";
import { AuthContext } from "../../context/AuthContext";
import { auth } from "../../firebase";
import { reauthenticateWithCredential, EmailAuthProvider, updatePassword } from "firebase/auth";
import { toast } from "react-toastify";
import locker from '../../images/icons/lock-2-line.svg'; // Ensure this path is correct
import './Password.css';

const Password = () => {
  // eslint-disable-next-line no-unused-vars
  const { isSidebarCollapsed } = useContext(SidebarContext);
  const { t } = useTranslation();
  const { currentUser } = useContext(AuthContext);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [focusedField, setFocusedField] = useState("");

  const handleSubmitPassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmNewPassword) {
      toast.error(t("New password and confirm password do not match"));
      return;
    }

    if (!currentUser) {
      toast.error(t("No authenticated user found"));
      return;
    }

    try {
      const credential = EmailAuthProvider.credential(currentUser.email, currentPassword);
      await reauthenticateWithCredential(auth.currentUser, credential); // auth.currentUser kullanımı
      await updatePassword(auth.currentUser, newPassword); // auth.currentUser kullanımı
      toast.success(t("Password updated successfully"));
    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        toast.error(t("The current password is incorrect"));
      } else if (error.code === 'auth/too-many-requests') {
        toast.error(t("Too many attempts. Please try again later."));
      } else {
        toast.error(`Error: ${error.message}`);
      }
      console.error(error);
    }
  };

  return (
    <div className="password-settings-unique">
      <div className="password-header-unique">
        <h1 className="password-title-unique">{t('Settings')}</h1>
        <hr />
        <div className="password-subheader-unique">
          <span>Password</span>
          <p className="password-instructions-unique">{t('Please enter your current password and confirm the new one.')}</p>
        </div>
      </div>
      <form onSubmit={handleSubmitPassword} className="password-form-unique">
        <div className="password-form-group-unique">
          <label htmlFor="current-password">{t('Current Password')}</label>
          <div className="password-input-container-unique">
            {!focusedField && !currentPassword && <img src={locker} alt="lock icon" className="password-lock-icon-unique" />}
            <input
              type="password"
              id="current-password"
              className="password-form-control-unique"
              value={currentPassword}
              onFocus={() => setFocusedField("currentPassword")}
              onBlur={() => setFocusedField("")}
              onChange={(e) => setCurrentPassword(e.target.value)}
              placeholder="******"
              required
            />
          </div>
        </div>
        <div className="password-form-group-unique">
          <label htmlFor="new-password">{t('New Password')}</label>
          <div className="password-input-container-unique">
            {!focusedField && !newPassword && <img src={locker} alt="lock icon" className="password-lock-icon-unique" />}
            <input
              type="password"
              id="new-password"
              className="password-form-control-unique"
              value={newPassword}
              onFocus={() => setFocusedField("newPassword")}
              onBlur={() => setFocusedField("")}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              placeholder="******"
            />
          </div>
          <p className="password-hint-unique">{t('New password must be more than 8 characters.')}</p>
        </div>
        <div className="password-form-group-unique">
          <label htmlFor="confirm-new-password">{t('Confirm Your New Password')}</label>
          <div className="password-input-container-unique">
            {!focusedField && !confirmNewPassword && <img src={locker} alt="lock icon" className="password-lock-icon-unique" />}
            <input
              type="password"
              id="confirm-new-password"
              className="password-form-control-unique"
              value={confirmNewPassword}
              onFocus={() => setFocusedField("confirmNewPassword")}
              onBlur={() => setFocusedField("")}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              required
              placeholder="******"
            />
          </div>
        </div>
        <div className="form-actions d-flex justify-content-start">
            <button type="button" className="settings-cancel-btn me-2" >{t('Cancel')}</button>
            <button type="submit" className="settings-save-btn">{t('Save')}</button>
          </div>
      </form>
    </div>
  );
};

export default Password;
