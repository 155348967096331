import React from "react";

import { Link } from "react-router-dom";
const NotFound = () => {
  return (
    <div className="flex flex-col items-center mt-20 max-w-5xl mx-auto text-center text-white">
      <h1 className="text-5xl my-5 text-black">404 Not Found! 😢</h1>
      <Link to="/users">
        <span className="text-4xl text-gray-400 mb-5 text-center">Back to Home Page</span>
      </Link>
    </div>
  );
};

export default NotFound;
