import React, { useContext, useEffect, useState, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { Accordion, Row, Col } from "react-bootstrap";
import { healthDataCategories } from '../../constants/category';
import { doc, getDoc } from "firebase/firestore";
import "./userDetails.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import NavbarMenu from "../../components/navbar/NavbarMenu";
import Charts from "../../components/charts/Charts";
import { CircularProgress } from "@mui/material";
import { Modal, Button } from "react-bootstrap"; // Bootstrap bileşenleri
import TwoCharts from "../../components/charts/TwoCharts";
import BarCharts from "../../components/charts/BarCharts";
import PastNotifComponent from './PastNotificationComponent'
import defaultAvatar from '../../images/avatar.png'
import { db, db2, db3, db4, db5, db6, db7, db8, db9 } from '../../firebase'; // Include additional databases
import { useTranslation } from "react-i18next";
import { DoctorContext } from "../../context/DoctorContext";
import { AuthContext } from "../../context/AuthContext";
import { toast } from "react-toastify";
import { SidebarContext } from "../../context/SidebarContext";
import FcmTomogramSender from "../../notification/FcmTomogramSender";
import TomogramCommentList from "../../notification/TomogramCommentList";
import FCMPdfSender from "../../notification/FcmPdfSender";
import PdfCommentList from "../../notification/PdfCommentList";
import AboutComponent from "./AboutComponent";
import BloodComponent from "./BloodComponent";
import useFetchUserDetails from "./FetchUserDetails";
import OtherMedicalDocumentsComponent from "./OtherMedicalDocuments";
const databases = [db, db2, db3, db4, db5, db6, db7, db8, db9];
const UserDetails = () => {

  const location = useLocation();
  const { t } = useTranslation();
  const { userId } = useParams();
  const { clients } = useContext(UserContext);
  const { userDetails, expirationDate, loading,fetchedHealthScores } = useFetchUserDetails(userId, databases);
  const user = clients.find((u) => u.id === userId); // `clients` listesi içinden arama yapıyoruz.
  const userData = userDetails[0];
  // eslint-disable-next-line no-unused-vars
  const { currentUser } = useContext(AuthContext)
  const { doctors } = useContext(DoctorContext);
  const doctor = doctors.find((doc) => doc.uid === currentUser.uid);
  const { isSidebarCollapsed } = useContext(SidebarContext);
  const [showModal, setShowModal] = useState(false);
  const [activeVideoUrl, setActiveVideoUrl] = useState("");
  const [riskScoreDiabet, setRiskScoreDiabet] = useState("");
  const [riskScoreNeurology, setRiskScoreNeurology] = useState("");
  const [riskScoreKardio, setRiskScoreKardio] = useState("");
  const [riskScoreObezite, setRiskScoreObezite] = useState("");
  const [riskScoreHiperTansiyon, setRiskScoreHiperTansiyon] = useState("");
  const [currentDateTime, setCurrentDateTime] = useState({
    fullDate: "",
    timeToday: "",
  });
  // eslint-disable-next-line
  const [notificationCount, setNotificationCount] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [heartRateData, setHeartRateData] = useState([]);
  const [oxygenSaturationData, setOxygenSaturationData] = useState([]);
  const [distanceWalkingData, setDistanceWalkingData] = useState([]);
  const [exerciseTimeData, setExerciseTimeData] = useState([]);
  const [flightsClimbedData, setFlightsClimbedData] = useState([]);
  const [respiratoryRateData, setRespiratoryRateData] = useState([]);
  const [restingEnergyData, setRestingEnergyData] = useState([]);
  const [restingHeartRateData, setRestingHeartRateData] = useState([]);
  const [sleepData, setSleepData] = useState([]);
  const [standHoursData, setStandHoursData] = useState([]);
  const [stepsData, setStepsData] = useState([]);
  const [todayCaloriesData, setTodayCaloriesData] = useState([]);
  const [sixMinutesWalkData, setSixMinutesWalkData] = useState([])
  const [bloodGlucoseData, setBloodGlucoseData] = useState([]);
  const [bloodPressureData, setBloodPressureData] = useState([]);
  const [heartRateRecoveryData, setHeartRateRecoveryData] = useState([]);
  const [heartRateVariabilitySDNNData, setHeartRateVariabilitySDNNData] =
    useState([]);
  const [walkingAsymmetryData, setWalkingAsymmetryData] = useState([])
  const [increasingTrends, setIncreasingTrends] = useState({});
  const [vo2MaxData, setVo2MaxData] = useState([]);
  const [weightData, setWeightData] = useState([]);
  const [bmiData, setBmiData] = useState([]);
  const [bodyFatPercentageData, setBodyFatPercentageData] = useState([]);
  const [leanBodyMassData, setLeanBodyMassData] = useState([]);
  const [waterData, setWaterData] = useState([]);
  const [b12Data, setB12Data] = useState([]);
  const [carbonhydrateData, setCarbonhydrateData] = useState([]);
  const [ironData, setIronData] = useState([]);
  const [magnesiumData, setMagnesiumData] = useState([]);
  const [proteinData, setProteinData] = useState([]);
  const [vitaminCData, setVitaminCData] = useState([]);
  const [vitaminDData, setVitaminDData] = useState([]);
  const [vitaminAData, setVitaminAData] = useState([]);
  const [vitaminB6Data, setVitaminB6Data] = useState([]);
  const [copperData, setCopperData] = useState([]);
  const [dietaryEnergyData, setDietaryEnergyData] = useState([]);
  const [dietaryCholesterolData, setDietaryCholesterolData] = useState([]);
  const [dietarySugarData, setDietarySugarData] = useState([]);
  const [dietaryBiotinData, setDietaryBiotinData] = useState([]);
  const [dietaryZincData, setDietaryZincData] = useState([]);
  const [dietaryFatPolyunsaturatedData, setDietaryFatPolyunsaturatedData] =
    useState([]);
  const [dietaryFatSaturatedData, setDietaryFatSaturatedData] = useState([]);
  const [vitaminEData, setVitamiNEData] = useState([]);
  const [dietaryFolateData, setDietaryFolateData] = useState([]);
  const [dietaryPhosphorusData, setDietaryPhosphorusData] = useState([]);
  const [dietaryIodineData, setDietaryIodineData] = useState([]);
  const [vitaminKData, setVitaminKData] = useState([]);
  const [dietaryCaffeineData, setDietaryCaffeineData] = useState([]);
  const [dietaryCalciumData, setDietaryCalciumData] = useState([]);
  const [dietaryChlorideData, setDietaryChloirdeData] = useState([]);
  const [dietaryChromiumData, setDietaryChromiumData] = useState([]);
  const [dietaryFiberData, setDietaryFiberData] = useState([]);
  const [dietaryManganeseData, setDietaryManganeseData] = useState([]);
  const [dietaryMolybdenumData, setDietaryMolybdenumData] = useState([]);
  const [dietaryNiacinData, setDietaryNiacinData] = useState([]);
  const [dietaryPantothenicAcidData, setDietaryPantothenicAcidData] = useState(
    []
  );
  const [dietaryPotassiumData, setDietaryPotassiumData] = useState([]);
  const [dietaryRiboflavinData, setDietaryRiboflavinData] = useState([]);
  const [dietarySeleniumData, setDietarySeleniumData] = useState([]);
  const [dietarySodiumData, setDietarySodiumData] = useState([]);
  const [dietaryFatMonounsaturatedData, setDietaryFatMonounsaturatedData] =
    useState([]);
  const [dietaryThiaminData, setDietaryThiaminData] = useState([]);
  const [dietaryFatTotalData, setDietaryFatTotalData] = useState([]);
  const [healthScoreData, setHealthScoreData] = useState([]);
  const [activeComponent, setActiveComponent] = useState("about");
  const toggleModal = () => {
    setModalOpen(!isModalOpen);

  };
 const handleCloseModal = () => {
    setShowModal(false);
  };
 // ---------------------- USE EFFECT START ----------------------
   useEffect(() => {
    if (!user || !userData) {
      <CircularProgress />;
      return;
    }
    const healthScoreTransform = transformData(fetchedHealthScores, "Health Score");
    const dateTime = calculateCurrentDateTime(user.HealthDataUser);
    const sixMinutesWalkTransform = transformData(userData.sixMinutesWalk, t("sixminuteswalk"))
    const walkingAsymetryTransform = transformData(userData.walkingAsymmetry, t("walkingasymetry"))
    const heartRateTransform = transformData(userData.heartRate, t("heartRate"));
    const oxygenSaturationTransform = transformData(
      userData.oxygenSaturation,
      t("oxygenSaturation")
    );
    const bloodGlucoseTransform = transformData(
      userData.bloodGlucose,
      t("bloodGlucose")
    );
    const distanceWalkingTransform = transformData(
      userData.distanceWalking,
      "distanceWalking"
    );
    const exerciseTimeTransform = transformData(
      userData.exerciseTime,
      "exerciseTime"
    );
    const flightsClimbedTransform = transformData(
      userData.flightsClimbed,
      "flightsClimbed"
    );
    const respiratoryRateTransform = transformData(
      userData.respiratoryRate,
      t("respiratory")
    );
    const restingEnergyTransform = transformData(
      userData.restingEnergy,
      "resting"
    );
    const restingHeartRateTransform = transformData(
      userData.restingHeartRate,
      t("Resting Heart Rate")
    );

    const standHoursTransform = transformData(userData.standHours, "stand");
    const stepsTransform = transformData(userData.steps, "steps");
    const todayCaloriesTransform = transformData(
      userData.todayCalories,
      "todaycalories"
    );
    const heartRateRecoveryTransform = transformData(
      userData.heartRateRecovery,
      t("heartraterecovery")
    );
    const heartRateVariabilitySDNNTransform = transformData(
      userData.heartRateVariabilitySDNN,
      t("sdnn")
    );
    const vo2MaxTransform = transformData(userData.vo2Max, "vo2");
    const weightTransform = transformData(userData.weights, t("weights"));
    const bmiTransform = transformData(userData.bmi, t("bmi"));
    const bodyFatPercentageTransform = transformData(
      userData.bodyFatPercentage,
      t("bodyfat")
    );
    const leanBodyMassTransform = transformData(
      userData.leanBodyMass,
      t("leanbody")
    );

    const bloodPressureTransform = transformData(
      userData.bloodPressure,
      t("bloodPressure")
    );
    const waterTransform = transformData(userData.water, "water");
    const b12Transform = transformData(userData.b12, "b12");
    const carbonhydrateTransform = transformData(
      userData.carbonhydrate,
      "carbonhydrate"
    );
    const ironTransform = transformData(userData.iron, "iron");
    const magnesiumTransform = transformData(userData.magnesium, "magnesium");
    const proteinTransform = transformData(userData.protein, "protein");
    const vitaminCTransform = transformData(userData.vitaminC, "vitaminC");
    const vitaminDTransform = transformData(userData.vitaminD, "vitaminD");
    const vitaminATransform = transformData(userData.vitaminA, "vitaminA");
    const vitaminB6Transform = transformData(userData.vitaminB6, "vitaminB6");
    const copperTransform = transformData(userData.copper, "copper");
    const dietaryEnergyTransform = transformData(
      userData.dietaryEnergy,
      "dietaryEnergy"
    );
    const dietaryCholesterolTransform = transformData(
      userData.dietaryCholesterol,
      "dietaryCholesterol"
    );
    const dietarySugarTransform = transformData(
      userData.dietarySugar,
      "dietarySugar"
    );
    const dietaryBiotinTransform = transformData(
      userData.dietaryBiotin,
      "dietaryBiotin"
    );
    const dietaryZincTransform = transformData(
      userData.dietaryZinc,
      "dietaryZinc"
    );
    const dietaryFatPolyunsaturatedTransform = transformData(
      userData.dietaryFatPolyunsaturated,
      "dietaryFatPolyunsaturated"
    );
    const dietaryFatSaturatedTransform = transformData(
      userData.dietaryFatSaturated,
      "dietaryFatSaturated"
    );
    const vitaminETransform = transformData(userData.vitaminE, "vitaminE");
    const dietaryFolateTransform = transformData(
      userData.dietaryFolate,
      "dietaryFolate"
    );
    const dietaryPhosphorusTransform = transformData(
      userData.dietaryPhosphorus,
      "dietaryPhosphorus"
    );
    const dietaryIodineTransform = transformData(
      userData.dietaryIodine,
      "dietaryIodine"
    );
    const vitaminKTransform = transformData(userData.vitaminK, "vitaminK");
    const dietaryCaffeineTransform = transformData(
      userData.dietaryCaffeine,
      "dietaryCaffeine"
    );
    const dietaryCalciumTransform = transformData(
      userData.dietaryCalcium,
      "dietaryCalcium"
    );
    const dietaryChlorideTransform = transformData(
      userData.dietaryChloride,
      "dietaryChloride"
    );
    const dietaryChromiumTransform = transformData(
      userData.dietaryChromium,
      "dietaryChromium"
    );
    const dietaryFiberTransform = transformData(
      userData.dietaryFiber,
      "dietaryFiber"
    );
    const dietaryManganeseTransform = transformData(
      userData.dietaryManganese,
      "dietaryManganese"
    );
    const dietaryMolybdenumTransform = transformData(
      userData.dietaryMolybdenum,
      "dietaryMolybdenum"
    );
    const dietaryNiacinTransform = transformData(
      userData.dietaryNiacin,
      "dietaryNiacin"
    );
    const dietaryPantothenicAcidTransform = transformData(
      userData.dietaryPantothenicAcid,
      "dietaryPantothenicAcid"
    );
    const dietaryPotassiumTransform = transformData(
      userData.dietaryPotassium,
      "dietaryPotassium"
    );
    const dietaryRiboflavinTransform = transformData(
      userData.dietaryRiboflavin,
      "dietaryRiboflavin"
    );
    const dietarySeleniumTransform = transformData(
      userData.dietarySelenium,
      "dietarySelenium"
    );
    const dietarySodiumTransform = transformData(
      userData.dietarySodium,
      "dietarySodium"
    );
    const dietaryFatMonounsaturatedTransform = transformData(
      userData.dietaryFatMonounsaturated,
      "dietaryFatMonounsaturated"
    );
    const dietaryThiaminTransform = transformData(
      userData.dietaryThiamin,
      "dietaryThiamin"
    );
    const dietaryFatTotalTransform = transformData(
      userData.dietaryFatTotal,
      "dietaryFatTotal"
    );

    const bloodGlucoseData = convertToStructuredData(
      bloodGlucoseTransform,
      convertToDateObjectDaily,
      t("bloodGlucose")
    );
    const distanceWalkingData = convertToStructuredAverageData(
      distanceWalkingTransform,
      convertToDateObjectDaily,
      "distanceWalking"
    );
    const exerciseTimeData = convertToStructuredAverageData(
      exerciseTimeTransform,
      convertToDateObjectDaily,
      "exerciseTime"
    );
    const flightsClimbedData = convertToStructuredAverageData(
      flightsClimbedTransform,
      convertToDateObjectDaily,
      "flightsClimbed"
    );
    const heartRateData = convertToStructuredData(
      heartRateTransform,
      convertToDateObjectDaily,
      "heartRate"
    );
    const heartRateRecoveryData = convertToStructuredAverageData(
      heartRateRecoveryTransform,
      convertToDateObjectDaily,
      "heartRateRecovery"
    );
    const heartRateVariabilitySDNNData = convertToStructuredAverageData(
      heartRateVariabilitySDNNTransform,
      convertToDateObjectDaily,
      "heartRateVariabilitySDNN"
    );
    const oxygenSaturationData = convertToStructuredData(
      oxygenSaturationTransform,
      convertToDateObjectDaily,
      "oxygenSaturation"
    );
    const respiratoryRateData = convertToStructuredData(
      respiratoryRateTransform,
      convertToDateObjectDaily,
      "respiratoryRate"
    );
    const restingEnergyData = convertToStructuredAverageData(
      restingEnergyTransform,
      convertToDateObjectDaily,
      "restingEnergy"
    );
    const restingHeartRateData = convertToStructuredAverageData(
      restingHeartRateTransform,
      convertToDateObjectDaily,
      "restingHeartRate"
    );
    const standHoursData = convertToStructuredAverageData(
      standHoursTransform,
      convertToDateObjectDaily,
      "standHours"
    );
    const stepsData = convertToStructuredAverageData(
      stepsTransform,
      convertToDateObjectDaily,
      "steps"
    );
    const todayCaloriesData = convertToStructuredAverageData(
      todayCaloriesTransform,
      convertToDateObjectDaily,
      "todayCalories"
    );
    const vo2MaxData = convertToStructuredAverageData(
      vo2MaxTransform,
      convertToDateObjectDaily,
      "vo2Max"
    );

    const bloodPressureData = convertToStructuredData(
      bloodPressureTransform,
      convertToDateObjectDaily,
      "bloodPressure"
    );

    const weightData = convertToStructuredAverageData(
      weightTransform,
      convertToDateObjectDaily,
      "weights"
    );

    const bmiData = convertToStructuredAverageData(
      bmiTransform,
      convertToDateObjectDaily,
      "bmi"
    );

    const bodyFatPercentageData = convertToStructuredAverageData(
      bodyFatPercentageTransform,
      convertToDateObjectDaily,
      "bodyFatPercentage"
    );

    const leanBodyMassData = convertToStructuredAverageData(
      leanBodyMassTransform,
      convertToDateObjectDaily,
      "leanBodyMass"
    );

    const sleepData = extractSleepDurationWithDate(userData.sleep);
    const b12Data = convertToStructuredAverageData(
      b12Transform,
      convertToDateObjectDaily,
      "b12"
    );
    const walkingAsymmetryData = convertToStructuredAverageData(
      walkingAsymetryTransform, convertToDateObjectDaily, "walkingAsymetry"
    )
    const sixMinutesWalkData = convertToStructuredAverageData(
      sixMinutesWalkTransform, convertToDateObjectDaily, "sixMinutesWalk"
    )
    const waterData = convertToStructuredAverageData(
      waterTransform,
      convertToDateObjectDaily,
      "water"
    );

    const carbonhydrateData = convertToStructuredAverageData(
      carbonhydrateTransform,
      convertToDateObjectDaily,
      "carbonhydrate"
    );
    const ironData = convertToStructuredAverageData(
      ironTransform,
      convertToDateObjectDaily,
      "iron"
    );
    const magnesiumData = convertToStructuredAverageData(
      magnesiumTransform,
      convertToDateObjectDaily,
      "magnesium"
    );
    const proteinData = convertToStructuredAverageData(
      proteinTransform,
      convertToDateObjectDaily,
      "protein"
    );
    const vitaminCData = convertToStructuredAverageData(
      vitaminCTransform,
      convertToDateObjectDaily,
      "vitaminC"
    );
    const vitaminDData = convertToStructuredAverageData(
      vitaminDTransform,
      convertToDateObjectDaily,
      "vitaminD"
    );
    const vitaminAData = convertToStructuredAverageData(
      vitaminATransform,
      convertToDateObjectDaily,
      "vitaminA"
    );
    const vitaminB6Data = convertToStructuredAverageData(
      vitaminB6Transform,
      convertToDateObjectDaily,
      "vitaminB6"
    );
    const copperData = convertToStructuredAverageData(
      copperTransform,
      convertToDateObjectDaily,
      "copper"
    );
    const dietaryEnergyData = convertToStructuredAverageData(
      dietaryEnergyTransform,
      convertToDateObjectDaily,
      "dietaryEnergy"
    );
    const dietaryCholesterolData = convertToStructuredAverageData(
      dietaryCholesterolTransform,
      convertToDateObjectDaily,
      "dietaryCholesterol"
    );
    const dietarySugarData = convertToStructuredAverageData(
      dietarySugarTransform,
      convertToDateObjectDaily,
      "dietarySugar"
    );
    const dietaryBiotinData = convertToStructuredAverageData(
      dietaryBiotinTransform,
      convertToDateObjectDaily,
      "dietaryBiotin"
    );
    const dietaryZincData = convertToStructuredAverageData(
      dietaryZincTransform,
      convertToDateObjectDaily,
      "dietaryZinc"
    );
    const dietaryFatPolyunsaturatedData = convertToStructuredAverageData(
      dietaryFatPolyunsaturatedTransform,
      convertToDateObjectDaily,
      "dietaryFatPolyunsaturated"
    );
    const dietaryFatSaturatedData = convertToStructuredAverageData(
      dietaryFatSaturatedTransform,
      convertToDateObjectDaily,
      "dietaryFatSaturated"
    );
    const vitaminEData = convertToStructuredAverageData(
      vitaminETransform,
      convertToDateObjectDaily,
      "vitaminE"
    );
    const dietaryFolateData = convertToStructuredAverageData(
      dietaryFolateTransform,
      convertToDateObjectDaily,
      "dietaryFolate"
    );
    const dietaryPhosphorusData = convertToStructuredAverageData(
      dietaryPhosphorusTransform,
      convertToDateObjectDaily,
      "dietaryPhosphorus"
    );
    const dietaryIodineData = convertToStructuredAverageData(
      dietaryIodineTransform,
      convertToDateObjectDaily,
      "dietaryIodine"
    );
    const vitaminKData = convertToStructuredAverageData(
      vitaminKTransform,
      convertToDateObjectDaily,
      "vitaminK"
    );
    const dietaryCaffeineData = convertToStructuredAverageData(
      dietaryCaffeineTransform,
      convertToDateObjectDaily,
      "dietaryCaffeine"
    );
    const dietaryCalciumData = convertToStructuredAverageData(
      dietaryCalciumTransform,
      convertToDateObjectDaily,
      "dietaryCalcium"
    );
    const dietaryChlorideData = convertToStructuredAverageData(
      dietaryChlorideTransform,
      convertToDateObjectDaily,
      "dietaryChloride"
    );
    const dietaryChromiumData = convertToStructuredAverageData(
      dietaryChromiumTransform,
      convertToDateObjectDaily,
      "dietaryChromium"
    );
    const dietaryFiberData = convertToStructuredAverageData(
      dietaryFiberTransform,
      convertToDateObjectDaily,
      "dietaryFiber"
    );
    const dietaryManganeseData = convertToStructuredAverageData(
      dietaryManganeseTransform,
      convertToDateObjectDaily,
      "dietaryManganese"
    );
    const dietaryMolybdenumData = convertToStructuredAverageData(
      dietaryMolybdenumTransform,
      convertToDateObjectDaily,
      "dietaryMolybdenum"
    );
    const dietaryNiacinData = convertToStructuredAverageData(
      dietaryNiacinTransform,
      convertToDateObjectDaily,
      "dietaryNiacin"
    );
    const dietaryPantothenicAcidData = convertToStructuredAverageData(
      dietaryPantothenicAcidTransform,
      convertToDateObjectDaily,
      "dietaryPantothenicAcid"
    );
    const dietaryPotassiumData = convertToStructuredAverageData(
      dietaryPotassiumTransform,
      convertToDateObjectDaily,
      "dietaryPotassium"
    );
    const dietaryRiboflavinData = convertToStructuredAverageData(
      dietaryRiboflavinTransform,
      convertToDateObjectDaily,
      "dietaryRiboflavin"
    );
    const dietarySeleniumData = convertToStructuredAverageData(
      dietarySeleniumTransform,
      convertToDateObjectDaily,
      "dietarySelenium"
    );
    const dietarySodiumData = convertToStructuredAverageData(
      dietarySodiumTransform,
      convertToDateObjectDaily,
      "dietarySodium"
    );
    const dietaryFatMonounsaturatedData = convertToStructuredAverageData(
      dietaryFatMonounsaturatedTransform,
      convertToDateObjectDaily,
      "dietaryFatMonounsaturated"
    );
    const dietaryThiaminData = convertToStructuredAverageData(
      dietaryThiaminTransform,
      convertToDateObjectDaily,
      "dietaryThiamin"
    );
    const dietaryFatTotalData = convertToStructuredAverageData(
      dietaryFatTotalTransform,
      convertToDateObjectDaily,
      "dietaryFatTotal"
    );

    const healthScoreData = convertToStructuredAverageData(
      healthScoreTransform,convertToDateObjectDaily,"Health Score"
    )

    function calculateAge(birthdate) {
      const [day, month, year] = birthdate.split("/").map(Number);
      const birthDate = new Date(year, month - 1, day);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    }

    // ---------------------- RISK CALCULATION START ----------------------
    function calculateDiabetRisk(bloodTestData) {
      try {
        let riskScore = 0;
        let riskScoreText = t("Needs Attention");

        const glucose =
          calculateRiskBasedOnSingleTest(bloodTestData, [
            "Glucose",
            "Blood Sugar",
          ]) ?? null;
        const hba1c =
          calculateRiskBasedOnSingleTest(bloodTestData, [
            "HbA1c",
            "Hemoglobin A1c",
          ]) ?? null;
        const stepsCount = stepsData?.weekly?.slice(-1)[0]?.value ?? null;
        const bmiScore = bmiData?.weekly?.slice(-1)[0]?.value ?? null;

        // Check which data is missing and distribute points equally among the other data
        const nullCount = [glucose, hba1c, stepsCount, bmiScore].filter(
          (data) => data === null
        ).length;
        const availableDataCount = 4 - nullCount;
        const distributedPercentage = 25 / availableDataCount;
        if (nullCount >= 3) {

          return t("N/A");
        }

        if (glucose === null) {
          if (hba1c !== null) riskScore += distributedPercentage;
          if (stepsCount !== null) riskScore += distributedPercentage;
          if (bmiScore !== null) riskScore += distributedPercentage;
        } else if (hba1c === null) {
          if (glucose !== null) riskScore += distributedPercentage;
          if (stepsCount !== null) riskScore += distributedPercentage;
          if (bmiScore !== null) riskScore += distributedPercentage;
        } else if (stepsCount === null) {
          if (glucose !== null) riskScore += distributedPercentage;
          if (hba1c !== null) riskScore += distributedPercentage;
          if (bmiScore !== null) riskScore += distributedPercentage;
        } else if (bmiScore === null) {
          if (glucose !== null) riskScore += distributedPercentage;
          if (hba1c !== null) riskScore += distributedPercentage;
          if (stepsCount !== null) riskScore += distributedPercentage;
        } else {
          // Apply conditions if all data is available
          if (stepsCount < 5000) riskScore += 25;
          if (bmiScore >= 30) riskScore += 25;
          if (glucose > 100) riskScore += 25;
          if (hba1c > 6.5) riskScore += 25;
        }

        // Determine the risk category based on the score
        if (riskScore > 55) {
          riskScoreText = t("Needs Attention");
        } else if (riskScore <= 50 && riskScore > 25) {
          riskScoreText = t("Optimal");
        } else if (riskScore <= 25) {
          riskScoreText = t("Ideal");
        }
        return riskScoreText;
      } catch (error) {
        console.error("Error calculating diabetes risk:", error);
        return t("N/A");
      }
    }
    function calculateObeziteRisk(bloodTestData, stepsData, bmiData) {
      try {
        let riskScore = 0;
        let riskScoreText = t("Need Attention");

        const alt =
          calculateRiskBasedOnSingleTest(bloodTestData, [
            "ALT",
            "ALT(SGPT)",
            "AlaninAminotransferaz(ALT)",
          ]) ?? null;
        const ast =
          calculateRiskBasedOnSingleTest(bloodTestData, [
            "AST",
            "AST(SGOT)",
            "AspartatAminotransaminaz(AST)",
          ]) ?? null;
        const adimPuan = stepsData?.weekly?.slice(-1)[0]?.value ?? null;
        const bmiPuan = bmiData?.weekly?.slice(-1)[0]?.value ?? null;

        // Hangi verinin null olduğunu kontrol ediyoruz ve 25 puanı diğer üç veri arasında eşit şekilde dağıtıyoruz
        const nullCount = [alt, ast, adimPuan, bmiPuan].filter(
          (data) => data === null
        ).length;
        const availableDataCount = 4 - nullCount;
        const distributedPercentage = 25 / availableDataCount;
        if (nullCount >= 2) {

          return t("N/A");
        }

        if (alt === null) {
          if (ast !== null) riskScore += distributedPercentage;

          if (adimPuan !== null) riskScore += distributedPercentage;
          if (bmiPuan !== null) riskScore += distributedPercentage;
        } else if (ast === null) {
          if (alt !== null) riskScore += distributedPercentage;
          if (adimPuan !== null) riskScore += distributedPercentage;
          if (bmiPuan !== null) riskScore += distributedPercentage;
        } else if (adimPuan === null) {
          if (alt !== null) riskScore += distributedPercentage;
          if (ast !== null) riskScore += distributedPercentage;
          if (bmiPuan !== null) riskScore += distributedPercentage;
        } else if (bmiPuan === null) {
          if (alt !== null) riskScore += distributedPercentage;
          if (ast !== null) riskScore += distributedPercentage;
          if (adimPuan !== null) riskScore += distributedPercentage;
        } else {
          // Tüm veriler mevcutsa, koşulları uygula
          if (adimPuan < 5000) riskScore += 25;
          if (bmiPuan >= 30) riskScore += 25;
          if (alt > 45) riskScore += 25;
          if (ast > 35) riskScore += 25;
        }

        if (riskScore > 55) {
          riskScoreText = t("Need Attention");
        } else if (riskScore <= 50 && riskScore > 25) {
          riskScoreText = t("Optimal");
        } else if (riskScore <= 25) {
          riskScoreText = t("Ideal");
        }

        return riskScoreText;
      } catch (error) {
        console.error("Obezite riski hesaplanırken bir hata oluştu:", error);
        return t("N/A");
      }
    }

    function calculateHiperTansiyonRisk(bloodTestData) {
      try {
        let riskScore = 0;

        const tansiyonPuan = bloodPressureData?.weekly?.slice(-1)[0] ?? null;
        const systolicValue = tansiyonPuan?.value ?? null;
        const diastolicValue = tansiyonPuan?.maxValue ?? null;
        const bmiPuan = bmiData?.weekly?.slice(-1)[0]?.value ?? null;
        const yas = calculateAge(user.birthday);
        const ldl =
          calculateRiskBasedOnSingleTest(bloodTestData, [
            "LDL",
            "LDLCholesterol",
            "LDLKolesterol",
          ]) ?? null;
        const TotalCholesterol =
          calculateRiskBasedOnSingleTest(bloodTestData, [
            "TotalCholesterol",
            "Kolesterol_Total",
            "Kolesterol",
          ]) ?? null;

        const nullCount = [
          tansiyonPuan,
          systolicValue,
          diastolicValue,
          bmiPuan,
          yas,
          ldl,
          TotalCholesterol,
        ].filter((data) => data === null).length;
        if (nullCount >= 4) {
          return t("N/A");
        }
        const availableDataCount = 7 - nullCount;
        const distributedPercentage = 14.2 / availableDataCount;
        if (tansiyonPuan === null) {
          if (systolicValue !== null) riskScore += distributedPercentage;
          if (diastolicValue !== null) riskScore += distributedPercentage;
          if (yas !== null) riskScore += distributedPercentage;
          if (ldl !== null) riskScore += distributedPercentage;
          if (bmiPuan !== null) riskScore += distributedPercentage;
          if (TotalCholesterol !== null) riskScore += distributedPercentage;
        } else if (systolicValue === null) {
          if (tansiyonPuan !== null) riskScore += distributedPercentage;

          if (diastolicValue !== null) riskScore += distributedPercentage;
          if (yas !== null) riskScore += distributedPercentage;
          if (ldl !== null) riskScore += distributedPercentage;
          if (bmiPuan !== null) riskScore += distributedPercentage;
          if (TotalCholesterol !== null) riskScore += distributedPercentage;
        } else if (diastolicValue === null) {
          if (tansiyonPuan !== null) riskScore += distributedPercentage;
          if (systolicValue !== null) riskScore += distributedPercentage;

          if (yas !== null) riskScore += distributedPercentage;
          if (ldl !== null) riskScore += distributedPercentage;
          if (bmiPuan !== null) riskScore += distributedPercentage;
          if (TotalCholesterol !== null) riskScore += distributedPercentage;
        } else if (yas === null) {
          if (tansiyonPuan !== null) riskScore += distributedPercentage;
          if (systolicValue !== null) riskScore += distributedPercentage;
          if (diastolicValue !== null) riskScore += distributedPercentage;
          if (ldl !== null) riskScore += distributedPercentage;
          if (bmiPuan !== null) riskScore += distributedPercentage;
          if (TotalCholesterol !== null) riskScore += distributedPercentage;
        } else if (ldl === null) {
          if (tansiyonPuan !== null) riskScore += distributedPercentage;
          if (systolicValue !== null) riskScore += distributedPercentage;
          if (diastolicValue !== null) riskScore += distributedPercentage;
          if (yas !== null) riskScore += distributedPercentage;
          if (bmiPuan !== null) riskScore += distributedPercentage;
          if (TotalCholesterol !== null) riskScore += distributedPercentage;
        } else if (TotalCholesterol === null) {
          if (tansiyonPuan !== null) riskScore += distributedPercentage;
          if (systolicValue !== null) riskScore += distributedPercentage;
          if (diastolicValue !== null) riskScore += distributedPercentage;
          if (yas !== null) riskScore += distributedPercentage;
          if (ldl !== null) riskScore += distributedPercentage;
          if (bmiPuan !== null) riskScore += distributedPercentage;
        } else if (bmiPuan === null) {
          if (tansiyonPuan !== null) riskScore += distributedPercentage;
          if (systolicValue !== null) riskScore += distributedPercentage;
          if (diastolicValue !== null) riskScore += distributedPercentage;
          if (yas !== null) riskScore += distributedPercentage;
          if (ldl !== null) riskScore += distributedPercentage;
          if (TotalCholesterol !== null) riskScore += distributedPercentage;
        }
        if (systolicValue >= 120 && systolicValue <= 129) {
          riskScore += 5;
        } else if (systolicValue >= 130 && systolicValue <= 139) {
          riskScore += 10;
        } else if (systolicValue >= 140) {
          riskScore += 20;
        }

        if (diastolicValue >= 80 && diastolicValue <= 89) {
          riskScore += 10;
        } else if (diastolicValue >= 90) {
          riskScore += 20;
        }
        if (yas >= 45 && yas <= 54) {
          riskScore += 5;
        } else if (yas >= 55 && yas <= 64) {
          riskScore += 10;
        } else if (yas >= 65) {
          riskScore += 20;
        } if (bmiPuan >= 25 && bmiPuan <= 29.9) {
          riskScore += 10;
        } else if (bmiPuan >= 30) {
          riskScore += 20;
        }
        if (ldl >= 130) {
          riskScore += 10;
        }
        if (TotalCholesterol >= 200) {
          riskScore += 10;
        }

        let riskScoreText = t("Needs Attention");
        if (riskScore < 20) {
          riskScoreText = t("Ideal");
        } else if (riskScore >= 20 && riskScore < 40) {
          riskScoreText = t("Optimal");
        } else if (riskScore >= 40) {
          riskScoreText = t("Need Attention");
        }

        return riskScoreText;
      } catch (error) {
        console.error(
          "Hiper tansiyon riski hesaplanırken bir hata oluştu:",
          error
        );
        return t("N/A");
      }
    }

    function calculateKardiovasuklerRisk(bloodTestData) {
      try {
        let riskScore = 0;
        const tansiyonPuan =
          bloodPressureData?.weekly?.slice(-1)[0]?.value ?? null;
        const dinlenmiskalphızıPuan =
          restingHeartRateData?.weekly?.slice(-1)[0]?.value ?? null;
        const kansekeriPuan =
          bloodGlucoseData?.weekly?.slice(-1)[0]?.value ?? null;
        const oksijenDoygunluguPuan =
          oxygenSaturationData?.weekly?.slice(-1)[0]?.value ?? null;

        const cinsiyetPuan = user.genderType;
        const yas = calculateAge(user.birthday);
        const hdl =
          calculateRiskBasedOnSingleTest(bloodTestData, [
            "HDL",
            "HDLCholesterol",
            "HDL-Kolesterol",
            "HDLKolesterol",
          ]) ?? null;
        const ldl =
          calculateRiskBasedOnSingleTest(bloodTestData, [
            "LDL",
            "LDLCholesterol",
            "LDLKolesterol",
          ]) ?? null;
        const TotalCholesterol =
          calculateRiskBasedOnSingleTest(bloodTestData, [
            "TotalCholesterol",
            "Kolesterol_Total",
            "Kolesterol",
          ]) ?? null;
        const triglycerides =
          calculateRiskBasedOnSingleTest(bloodTestData, [
            "Triglycerides",
            "Trigliserit",
            "Trigliserid",
          ]) ?? null;

        const nullCount = [
          tansiyonPuan,
          dinlenmiskalphızıPuan,
          kansekeriPuan,
          oksijenDoygunluguPuan,
          cinsiyetPuan,
          yas,
          hdl,
          ldl,
          TotalCholesterol,
          triglycerides,
        ].filter((data) => data === null).length;
        if (nullCount >= 7) {
          return t("N/A");
        }
        const availableDataCount = 10 - nullCount;
        const distributedPercentage = 10 / availableDataCount || 0;
        if (tansiyonPuan === null) {
          if (dinlenmiskalphızıPuan !== null)
            riskScore += distributedPercentage;
          if (kansekeriPuan !== null) riskScore += distributedPercentage;
          if (oksijenDoygunluguPuan !== null)
            riskScore += distributedPercentage;
          if (cinsiyetPuan !== null) riskScore += distributedPercentage;
          if (yas !== null) riskScore += distributedPercentage;
          if (hdl !== null) riskScore += distributedPercentage;
          if (ldl !== null) riskScore += distributedPercentage;
          if (TotalCholesterol !== null) riskScore += distributedPercentage;
          if (triglycerides !== null) riskScore += distributedPercentage;
        } else if (dinlenmiskalphızıPuan === null) {
          if (tansiyonPuan !== null) riskScore += distributedPercentage;
          if (kansekeriPuan !== null) riskScore += distributedPercentage;
          if (oksijenDoygunluguPuan !== null)
            riskScore += distributedPercentage;
          if (cinsiyetPuan !== null) riskScore += distributedPercentage;
          if (yas !== null) riskScore += distributedPercentage;
          if (hdl !== null) riskScore += distributedPercentage;
          if (ldl !== null) riskScore += distributedPercentage;
          if (TotalCholesterol !== null) riskScore += distributedPercentage;
          if (triglycerides !== null) riskScore += distributedPercentage;
        } else if (kansekeriPuan === null) {
          if (tansiyonPuan !== null) riskScore += distributedPercentage;
          if (dinlenmiskalphızıPuan !== null)
            riskScore += distributedPercentage;
          if (oksijenDoygunluguPuan !== null)
            riskScore += distributedPercentage;
          if (cinsiyetPuan !== null) riskScore += distributedPercentage;
          if (yas !== null) riskScore += distributedPercentage;
          if (hdl !== null) riskScore += distributedPercentage;
          if (ldl !== null) riskScore += distributedPercentage;
          if (TotalCholesterol !== null) riskScore += distributedPercentage;
          if (triglycerides !== null) riskScore += distributedPercentage;
        } else if (oksijenDoygunluguPuan === null) {
          if (tansiyonPuan !== null) riskScore += distributedPercentage;
          if (dinlenmiskalphızıPuan !== null)
            riskScore += distributedPercentage;
          if (kansekeriPuan !== null) riskScore += distributedPercentage;
          if (cinsiyetPuan !== null) riskScore += distributedPercentage;
          if (yas !== null) riskScore += distributedPercentage;
          if (hdl !== null) riskScore += distributedPercentage;
          if (ldl !== null) riskScore += distributedPercentage;
          if (TotalCholesterol !== null) riskScore += distributedPercentage;
          if (triglycerides !== null) riskScore += distributedPercentage;
        } else if (cinsiyetPuan === null) {
          if (tansiyonPuan !== null) riskScore += distributedPercentage;
          if (kansekeriPuan !== null) riskScore += distributedPercentage;
          if (oksijenDoygunluguPuan !== null)
            riskScore += distributedPercentage;
          if (dinlenmiskalphızıPuan !== null)
            riskScore += distributedPercentage;
          if (yas !== null) riskScore += distributedPercentage;
          if (hdl !== null) riskScore += distributedPercentage;
          if (ldl !== null) riskScore += distributedPercentage;
          if (TotalCholesterol !== null) riskScore += distributedPercentage;
          if (triglycerides !== null) riskScore += distributedPercentage;
        } else if (yas === null) {
          if (tansiyonPuan !== null) riskScore += distributedPercentage;
          if (kansekeriPuan !== null) riskScore += distributedPercentage;
          if (oksijenDoygunluguPuan !== null)
            riskScore += distributedPercentage;
          if (dinlenmiskalphızıPuan !== null)
            riskScore += distributedPercentage;

          if (hdl !== null) riskScore += distributedPercentage;
          if (ldl !== null) riskScore += distributedPercentage;
          if (TotalCholesterol !== null) riskScore += distributedPercentage;
          if (triglycerides !== null) riskScore += distributedPercentage;
          if (cinsiyetPuan != null) riskScore += distributedPercentage;
        } else if (hdl === null) {
          if (tansiyonPuan !== null) riskScore += distributedPercentage;
          if (kansekeriPuan !== null) riskScore += distributedPercentage;
          if (oksijenDoygunluguPuan !== null)
            riskScore += distributedPercentage;
          if (dinlenmiskalphızıPuan !== null)
            riskScore += distributedPercentage;
          if (yas !== null) riskScore += distributedPercentage;

          if (ldl !== null) riskScore += distributedPercentage;
          if (TotalCholesterol !== null) riskScore += distributedPercentage;
          if (triglycerides !== null) riskScore += distributedPercentage;
          if (cinsiyetPuan != null) riskScore += distributedPercentage;
        } else if (ldl === null) {
          if (tansiyonPuan !== null) riskScore += distributedPercentage;
          if (kansekeriPuan !== null) riskScore += distributedPercentage;
          if (oksijenDoygunluguPuan !== null)
            riskScore += distributedPercentage;
          if (dinlenmiskalphızıPuan !== null)
            riskScore += distributedPercentage;
          if (yas !== null) riskScore += distributedPercentage;
          if (hdl !== null) riskScore += distributedPercentage;

          if (TotalCholesterol !== null) riskScore += distributedPercentage;
          if (triglycerides !== null) riskScore += distributedPercentage;
          if (cinsiyetPuan != null) riskScore += distributedPercentage;
        } else if (TotalCholesterol === null) {
          if (tansiyonPuan !== null) riskScore += distributedPercentage;
          if (kansekeriPuan !== null) riskScore += distributedPercentage;
          if (oksijenDoygunluguPuan !== null)
            riskScore += distributedPercentage;
          if (dinlenmiskalphızıPuan !== null)
            riskScore += distributedPercentage;
          if (yas !== null) riskScore += distributedPercentage;
          if (hdl !== null) riskScore += distributedPercentage;
          if (ldl !== null) riskScore += distributedPercentage;

          if (triglycerides !== null) riskScore += distributedPercentage;
          if (cinsiyetPuan != null) riskScore += distributedPercentage;
        } else if (triglycerides === null) {
          if (tansiyonPuan !== null) riskScore += distributedPercentage;
          if (kansekeriPuan !== null) riskScore += distributedPercentage;
          if (oksijenDoygunluguPuan !== null)
            riskScore += distributedPercentage;
          if (dinlenmiskalphızıPuan !== null)
            riskScore += distributedPercentage;
          if (yas !== null) riskScore += distributedPercentage;
          if (hdl !== null) riskScore += distributedPercentage;
          if (ldl !== null) riskScore += distributedPercentage;
          if (TotalCholesterol !== null) riskScore += distributedPercentage;

          if (cinsiyetPuan != null) riskScore += distributedPercentage;
        } else {
          if (cinsiyetPuan === "male" && hdl < 40) {
            riskScore += 10;
          } else if (cinsiyetPuan === "female" && hdl < 50) {
            riskScore += 10;
          }
          if (ldl >= 130) riskScore += 10;
          if (TotalCholesterol >= 220) riskScore += 10;
          if (triglycerides >= 200) riskScore += 10;
          if (yas >= 65) riskScore += 10;
          if (tansiyonPuan >= 140) riskScore += 10;
          if (dinlenmiskalphızıPuan > 100) riskScore += 20;
          if (kansekeriPuan >= 126) riskScore += 10;
          if (oksijenDoygunluguPuan < 92) riskScore += 10;
          if (cinsiyetPuan === "male" || cinsiyetPuan === "Male") {
            riskScore += 10;
          } else if (cinsiyetPuan === "female" || cinsiyetPuan === "Female") {
            riskScore += 5;
          }
        }

        // Risk puanına göre metni belirle
        let riskScoreText = t("Needs Attention");
        if (riskScore < 60) {
          riskScoreText = t("Ideal");
        } else if (riskScore >= 60 && riskScore < 80) {
          riskScoreText = t("Optimal");
        } else if (riskScore >= 80) {
          riskScoreText = t("Need Attention");
        }
        return riskScoreText;
      } catch (error) {
        console.error(
          "Kardiyovasküler risk hesaplaması sırasında bir hata oluştu:",
          error
        );
        return t("N/A");
      }
    }

    function calculateNeurologicalRisk(bloodTestData) {
      try {
        let riskScore = 0;
        const uykuPuan =
          sleepData
            ?.filter(
              (d) =>
                new Date(d.date) >=
                new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
            )
            .pop()?.value ?? null;
        const oksijenDoygunluguPuan =
          oxygenSaturationData?.weekly?.[oxygenSaturationData.weekly.length - 1]
            ?.value ?? null;
        const b12Puan =
          calculateRiskBasedOnSingleTest(bloodTestData, "VitaminB12") ?? null;
        const kansekeriPuan =
          bloodGlucoseData?.weekly?.[bloodGlucoseData.weekly.length - 1]
            ?.value ?? null;
        const ldl =
          calculateRiskBasedOnSingleTest(bloodTestData, "LDL") ?? null;
        const nullCount = [
          uykuPuan,
          oksijenDoygunluguPuan,
          b12Puan,
          kansekeriPuan,
          ldl,
        ].filter((data) => data === null).length;
        const availableDataCount = 5 - nullCount;
        const distributedPercentage = 20 / availableDataCount;
        if (nullCount >= 3) {
          return t("N/A");
        }
        if (uykuPuan === null) {
          if (oksijenDoygunluguPuan !== null)
            riskScore += distributedPercentage;

          if (b12Puan !== null) riskScore += distributedPercentage;
          if (kansekeriPuan !== null) riskScore += distributedPercentage;
          if (ldl !== null) riskScore += distributedPercentage;
        } else if (oksijenDoygunluguPuan === null) {
          if (uykuPuan !== null) riskScore += distributedPercentage;
          if (b12Puan !== null) riskScore += distributedPercentage;
          if (kansekeriPuan !== null) riskScore += distributedPercentage;
          if (ldl !== null) riskScore += distributedPercentage;
        } else if (b12Puan === null) {
          if (oksijenDoygunluguPuan !== null)
            riskScore += distributedPercentage;
          if (uykuPuan !== null) riskScore += distributedPercentage;

          if (kansekeriPuan !== null) riskScore += distributedPercentage;
          if (ldl !== null) riskScore += distributedPercentage;
        } else if (kansekeriPuan === null) {
          if (oksijenDoygunluguPuan !== null)
            riskScore += distributedPercentage;
          if (uykuPuan !== null) riskScore += distributedPercentage;
          if (b12Puan !== null) riskScore += distributedPercentage;

          if (ldl !== null) riskScore += distributedPercentage;
        } else if (ldl === null) {
          if (oksijenDoygunluguPuan !== null)
            riskScore += distributedPercentage;
          if (uykuPuan !== null) riskScore += distributedPercentage;
          if (b12Puan !== null) riskScore += distributedPercentage;
          if (kansekeriPuan !== null) riskScore += distributedPercentage;
        }

        if (ldl >= 130) riskScore += 10;
        if (uykuPuan < 6) riskScore += 10;
        if (kansekeriPuan >= 126) riskScore += 10;
        if (oksijenDoygunluguPuan < 92) riskScore += 50;
        if (b12Puan < 200) riskScore += 20;

        let riskScoreText = t("Critical");
        if (riskScore < 50) {
          riskScoreText = t("Ideal");
        } else if (riskScore === 50) {
          riskScoreText = t("Optimal");
        } else if (riskScore > 50) {
          riskScoreText = t("Needs Attention");
        }


        return riskScoreText;
      } catch (error) {
        console.error("Hesaplama sırasında bir hata oluştu:", error);
        return t("N/A");
      }
    }

    const diabetRisk = calculateDiabetRisk(
      user.ArtificialIntelligenceBloodData
    );
    setRiskScoreDiabet(diabetRisk);
    const neuroRisk = calculateNeurologicalRisk(
      user.ArtificialIntelligenceBloodData
    );
    setRiskScoreNeurology(neuroRisk);
    const kardiovaskulerRisk = calculateKardiovasuklerRisk(
      user.ArtificialIntelligenceBloodData
    );
    setRiskScoreKardio(kardiovaskulerRisk);
    const obesiteRisk = calculateObeziteRisk(
      user.ArtificialIntelligenceBloodData
    );
    setRiskScoreObezite(obesiteRisk);
    const tansiyonRisk = calculateHiperTansiyonRisk(
      user.ArtificialIntelligenceBloodData
    );
    setRiskScoreHiperTansiyon(tansiyonRisk);
    // ---------------------- RISK CALCULATION END ----------------------

    // State'i güncelle
    setSixMinutesWalkData(sixMinutesWalkData)
    setWalkingAsymmetryData(walkingAsymmetryData)
    setCurrentDateTime(dateTime);
    setHealthScoreData(healthScoreData);
    setOxygenSaturationData(oxygenSaturationData);
    setHeartRateData(heartRateData);
    setDistanceWalkingData(distanceWalkingData);
    setExerciseTimeData(exerciseTimeData);
    setFlightsClimbedData(flightsClimbedData);
    setRespiratoryRateData(respiratoryRateData);
    setRestingEnergyData(restingEnergyData);
    setRestingHeartRateData(restingHeartRateData);
    setStandHoursData(standHoursData);
    setStepsData(stepsData);
    setTodayCaloriesData(todayCaloriesData);
    setBloodGlucoseData(bloodGlucoseData);
    setHeartRateRecoveryData(heartRateRecoveryData);
    setHeartRateVariabilitySDNNData(heartRateVariabilitySDNNData);
    setVo2MaxData(vo2MaxData);
    setSleepData(sleepData);
    setBloodPressureData(bloodPressureData);
    setWeightData(weightData);
    setBmiData(bmiData);
    setBodyFatPercentageData(bodyFatPercentageData);
    setLeanBodyMassData(leanBodyMassData);
    setWaterData(waterData);
    setB12Data(b12Data);
    setCarbonhydrateData(carbonhydrateData);
    setIronData(ironData);
    setMagnesiumData(magnesiumData);
    setProteinData(proteinData);
    setVitaminCData(vitaminCData);
    setVitaminDData(vitaminDData);
    setVitaminAData(vitaminAData);
    setVitaminB6Data(vitaminB6Data);
    setCopperData(copperData);
    setDietaryEnergyData(dietaryEnergyData);
    setDietaryCholesterolData(dietaryCholesterolData);
    setDietarySugarData(dietarySugarData);
    setDietaryBiotinData(dietaryBiotinData);
    setDietaryZincData(dietaryZincData);
    setDietaryFatPolyunsaturatedData(dietaryFatPolyunsaturatedData);
    setDietaryFatSaturatedData(dietaryFatSaturatedData);
    setVitamiNEData(vitaminEData);
    setDietaryFolateData(dietaryFolateData);
    setDietaryPhosphorusData(dietaryPhosphorusData);
    setDietaryIodineData(dietaryIodineData);
    setVitaminKData(vitaminKData);
    setDietaryCaffeineData(dietaryCaffeineData);
    setDietaryCalciumData(dietaryCalciumData);
    setDietaryChloirdeData(dietaryChlorideData);
    setDietaryChromiumData(dietaryChromiumData);
    setDietaryFiberData(dietaryFiberData);
    setDietaryManganeseData(dietaryManganeseData);
    setDietaryMolybdenumData(dietaryMolybdenumData);
    setDietaryNiacinData(dietaryNiacinData);
    setDietaryPantothenicAcidData(dietaryPantothenicAcidData);
    setDietaryPotassiumData(dietaryPotassiumData);
    setDietaryRiboflavinData(dietaryRiboflavinData);
    setDietarySeleniumData(dietarySeleniumData);
    setDietarySodiumData(dietarySodiumData);
    setDietaryFatMonounsaturatedData(dietaryFatMonounsaturatedData);
    setDietaryThiaminData(dietaryThiaminData);
    setDietaryFatTotalData(dietaryFatTotalData);
    // eslint-disable-next-line
  }, [clients, user, userData]);

  // ---------------------- USE EFFECT END ----------------------
  const formatNumber = (number) => (number < 10 ? `0${number}` : number);
  const calculateCurrentDateTime = (HealthDataUser) => {
    if (!HealthDataUser || !HealthDataUser.timestamp) {
      return { fullDate: 'N/A', timeToday: 'N/A' }; // Return default values instead of null
    }
    const lastUpdatedDate = new Date(HealthDataUser.timestamp.seconds * 1000);
    const fullDate = `${formatNumber(lastUpdatedDate.getDate())}.${formatNumber(
      lastUpdatedDate.getMonth() + 1
    )}.${lastUpdatedDate.getFullYear()} ${formatNumber(
      lastUpdatedDate.getHours()
    )}:${formatNumber(lastUpdatedDate.getMinutes())}`;
    const timeToday = `${formatNumber(
      lastUpdatedDate.getHours()
    )}:${formatNumber(lastUpdatedDate.getMinutes())}`;

    return { fullDate, timeToday };
  };

  // ---------------------- LAST UPDATED END ----------------------


  function convertToDateObjectDaily(dateStr, type) {
    if (dateStr === undefined) {
      dateStr = "01.01.2024";
    }
    let day, month, year, time, parts;

    parts = dateStr.split(".");

    if (parts.length === 2) {
      // Sadece ay ve yıl varsa (örneğin "01.2024")
      [month, year] = parts;
      day = "01";
      time = "12:00";
    } else if (parts.length === 3) {
      // Gün, ay ve yıl varsa (örneğin "26.01.2024" veya "26.01.2024 12:00")
      [day, month, year] = parts;

      if (year.includes(" ")) {
        // Eğer yıl kısmında zaman bilgisi varsa
        [year, time] = year.split(" ");
      } else {
        time = "12:00";
      }
    } else {
      console.error("convertToDateObjectDaily: Date Error", dateStr, type);
      return {};
    }

    const dateObject = new Date(`${year}-${month}-${day}T${time}`);
    return { dateObject, day, month, year, time };
  }

  const convertToStructuredData = (data, convertToDate, type) => {
    const structuredData = {};
    if (typeof data !== "object" || data === null) {
      return {}; // Hata durumunda null döndür
    }

    // Her zaman dilimi için veriyi işle
    Object.entries(data).forEach(([period, values]) => {
      if (!values || Object.keys(values).length === 0) {
        structuredData[period] = [];
      } else {
        structuredData[period] = Object.entries(values).map(([date, value]) => {
          const { time, dateObject, day, month, year } = convertToDate(
            date,
            type
          );
          const { fullDate } = calculateCurrentDateTime(user.HealthDataUser);
          if (type === "oxygenSaturation") {
            return {
              time: time,
              date: dateObject,
              dayMonth: `${day}.${month}`,
              dayTime: fullDate,
              monthYear: `${month}.${year}`,
              value: parseFloat(value.split("-")[0]) * 100,
              maxValue: parseFloat(value.split("-")[1]) * 100,
            };
          } else {
            return {
              time: time,
              date: dateObject,
              dayMonth: `${day}.${month}`,
              dayTime: `${month}.${year}`,
              monthYear: `${month}.${year}`,
              value: parseFloat(value.split("-")[0]),
              maxValue: parseFloat(value.split("-")[1]),
            };
          }
        });
        structuredData[period].sort((a, b) => a.date - b.date);
      }
    });
    return structuredData;
  };

  const convertToStructuredAverageData = (data, convertToDate, type) => {
    const structuredData = {};
    if (typeof data !== "object" || data === null) {
      console.error("convertToStructuredData: Data Error", type, "struct");
      return {};
    }

    // Her zaman dilimi için veriyi işle
    Object.entries(data).forEach(([period, values]) => {
      if (!values || Object.keys(values).length === 0) {
        structuredData[period] = [];
      } else {
        structuredData[period] = Object.entries(values)
          .map(([date, value]) => {
            const { dateObject, day, month, year } = convertToDate(date, type);
            const { fullDate } = calculateCurrentDateTime(user.HealthDataUser);
            if (type === "bodyFatPercentage") {
              return {
                date: dateObject,
                dayMonth: `${day}.${month}`,
                dayTime: fullDate,
                monthYear: `${month}.${year}`,
                value: parseFloat(value) * 100, // ort deger
              };
            } else {
              return {
                date: dateObject,
                dayMonth: `${day}.${month}`,
                dayTime: fullDate,
                monthYear: `${month}.${year}`,
                value: parseFloat(value), // ort deger
              };
            }
          })
          .filter(entry => entry.value !== 0); // 0 olan değerleri filtrele

        structuredData[period].sort((a, b) => a.date - b.date);
      }
    });

    return structuredData;
  };
  
  const extractSleepDurationWithDate = (sleepData) => {
    

    return sleepData && typeof sleepData === "object"
        ? Object.entries(sleepData)
            .map(([date, value]) => {
                
                const { dateObject, day, month, year } = convertToDateObjectDaily(date);
         

                const durationParts = value.split("$");

                const totalDuration = durationParts[durationParts.length - 1];

                const [hours] = totalDuration.split(":");

                return {
                    date: dateObject,
                    dayMY: `${day}.${month}.${year}`,
                    value: hours,
                };
            })
            .filter((entry) => {
                
                return parseInt(entry.value) !== 0;
            })
            .sort((a, b) => {
              
                return a.date - b.date;
            })
        : [];
};

  function healthScores(healthScores) {
    // healthScores verisinin tipini ve null olmadığını kontrol et
    if (
      typeof healthScores !== "object" ||
      healthScores === null ||
      !Array.isArray(healthScores)
    ) {
      console.error("healthScores: Data Error", "healthScores", "struct");
      return {};
    } else {
      // Öncelikle, tüm girişleri işleyerek geçici bir dizi oluştur
      const tempEntries = healthScores.map((entry) => {
        if (
          !entry ||
          !entry.timestamp ||
          typeof entry.timestamp.seconds === "undefined" ||
          typeof entry.healthScore === "undefined"
        ) {
          console.error("healthScores: Entry Error", entry, "struct");
          return { error: "Invalid data" };
        }

        const date = new Date(entry.timestamp.seconds * 1000);
        // const twoDigit = (num) => (num < 10 ? `0${num}` : num);
        const formattedDate = date.toISOString().split("T")[0]; // YYYY-MM-DD formatına getir
        const healthScoreValue = Math.round(parseFloat(entry.healthScore));

        return {
          fullDate: date,
          date: formattedDate,
          value: healthScoreValue
        };
      });
      const latestEntries = tempEntries.reduce((acc, current) => {
        if (current.error || current.value === 0) return acc; // 0 olan veya hatalı girdileri atla
        const currentVal = acc[current.date];
        if (currentVal) {
          // Mevcut tarih için daha geç bir zaman var mı kontrol et
          if (current.fullDate > currentVal.fullDate) {
            acc[current.date] = current; // Daha geç bir zamanla güncelle
          }
        } else {
          acc[current.date] = current; // Bu tarih için yeni bir giriş ekle
        }
        return acc;
      }, {});

      // En son girişler dizisi oluştur
      return Object.values(latestEntries).map((entry) => {
        if (entry.error) return entry; // Hatalı girişleri olduğu gibi döndür

        const date = entry.fullDate;
        const twoDigit = (num) => (num < 10 ? `0${num}` : num);
        const formattedDate = date.toISOString().replace("T", " ").slice(0, 19);

        return {
          date: formattedDate,
          dayMonth: `${twoDigit(date.getDate())}.${twoDigit(
            date.getMonth() + 1
          )}`,
          time: `${twoDigit(date.getHours())}:${twoDigit(date.getMinutes())}`,
          dayTime: `${twoDigit(date.getDate())}.${twoDigit(
            date.getMonth() + 1
          )} - ${twoDigit(date.getHours())}:${twoDigit(date.getMinutes())}`,
          value: entry.value,
        };
      });
    }
  }
  const transformData = (originalData, type) => {
    try {
      if (!originalData || typeof originalData !== 'object') {
        return null; // Veri yoksa sadece null döndür
      }
      const formatValueRange = (values) =>
        values.map((value) => {
          if (value.includes("-")) {
            return value
              .split("-")
              .map((v) => parseFloat(parseFloat(v).toFixed(2)));
          } else if (value.includes(":")) {
            return value
              .split(":")
              .map((v) => parseFloat(parseFloat(v).toFixed(2)));
          } else if (!isNaN(parseFloat(value))) {
            return [parseFloat(parseFloat(value).toFixed(2))];
          } else {
            throw new Error(`Invalid value format: ${value}`);
          }
        });

      const transformedData = {
        yearly: {},
        monthly: {},
        sixMonths: {},
        weekly: {},
        daily: {},
      };
      const getSixMonthsAgo = () => {
        const date = new Date();
        date.setMonth(date.getMonth() - 5);
        date.setDate(1); // Ayın ilk gününü al
        return date;
      };      

      const getLast30Days = () =>
        new Date(new Date().setDate(new Date().getDate() - 30));
      const getLast7Days = () =>
        new Date(new Date().setDate(new Date().getDate() - 6));

      Object.entries(originalData).forEach(([date, rateRange]) => {
        let day, month, year, time, parts;
        let bloodDate;

        if (date.includes("-")) {
          parts = date.split("-");
        } else {
          parts = date.split(".");
        }

        if (type === t("bloodPressure")) {
          let dateTimePart = parts[2].split(" ");
          [day, month, year] = parts;
          day = Number(day);
          month = Number(month);
          year = Number(dateTimePart[0]);
          time = dateTimePart[1];
          bloodDate = `${day < 10 ? `0${day}` : day}-${month < 10 ? `0${month}` : month}-${year}`;
        } else {
          [day, month, year] = parts.map(Number);
        }

        if (parts.length !== 3) {
          throw new Error(`Invalid date format: ${date}`);
        }

        if (!day || !month || !year) {
          throw new Error(`Invalid date components in: ${date}`);
        }

        const currentDate = new Date(year, month - 1, day);
        const sixMonthsAgoDate = getSixMonthsAgo();
        const last30DaysDate = getLast30Days();
        const last7DaysDate = getLast7Days();

        let formattedDate;
        const yearDaily = "2024";
        const monthDaily = new Date().getMonth() + 1;
        const todayMonth =
          monthDaily < 10 ? `0${monthDaily}` : monthDaily.toString();
        const dayDaily = new Date().getDate();
        const todayDay = dayDaily < 10 ? `0${dayDaily}` : dayDaily.toString();
        let todayDate;
        if (date.includes("-")) {
          todayDate = `${todayDay}-${todayMonth}-${yearDaily}`;
        } else {
          todayDate = `${todayDay}.${todayMonth}.${yearDaily}`;
        }
      if (type === t("bloodPressure")) {
          //daily verisi
          if (todayDate === bloodDate) {
            if (time) {
              formattedDate = `${todayDay}.${todayMonth}.${yearDaily} ${time}`;
            } else {
              formattedDate = `${todayDay}.${todayMonth}.${yearDaily}`;
            }
            if (!transformedData.daily[formattedDate]) {
              transformedData.daily[formattedDate] = formatValueRange([
                rateRange,
              ]);
            } else {
              transformedData.daily[formattedDate].push(
                formatValueRange([rateRange])
              );
            }
          }
        }
        else {
          //daily verisi
          if (todayDate === date) {
            if (time) {
              formattedDate = `${todayDay}.${todayMonth}.${yearDaily} ${time}`;
            } else {
              formattedDate = `${todayDay}.${todayMonth}.${yearDaily}`;
            }
            if (!transformedData.daily[formattedDate]) {
              transformedData.daily[formattedDate] = formatValueRange([
                rateRange,
              ]);
            } else {
              transformedData.daily[formattedDate].push(
                formatValueRange([rateRange])
              );
            }
          }
        }




        const formattedMonth = month < 10 ? `0${month}` : month.toString();
        const formattedDay = day < 10 ? `0${day}` : day.toString();
        const formattedYearMonth = `${formattedMonth}.${year}`;

        // 'yearly' verisini güncelle
        if (!transformedData.yearly[formattedYearMonth]) {
          const formattedYearMonth = `${formattedMonth}.${year}`; // "2024.01" formatında
          transformedData.yearly[formattedYearMonth] = formatValueRange([
            rateRange,
          ]);
        } else {
          transformedData.yearly[formattedYearMonth].push(
            formatValueRange([rateRange])
          );
        }
       
        // 'sixMonths' verisini güncelle
        if (currentDate >= sixMonthsAgoDate) {
          const formattedYearMonth = `${formattedMonth}.${year}`; // "2024.01" formatında
          if (!transformedData.sixMonths[formattedYearMonth]) {
            transformedData.sixMonths[formattedYearMonth] = formatValueRange([
              rateRange,
            ]);
          } else {
            transformedData.sixMonths[formattedYearMonth].push(
              formatValueRange([rateRange])
            );
          }
        }

        // 'monthly' verisini güncelle
        if (currentDate >= last30DaysDate) {
          const formattedYearMonthDay = `${formattedDay}.${formattedMonth}.${year}`;
          if (!transformedData.monthly[formattedYearMonthDay]) {
            transformedData.monthly[formattedYearMonthDay] = formatValueRange([
              rateRange,
            ]);
          } else {
            transformedData.monthly[formattedYearMonthDay].push(
              formatValueRange([rateRange])
            );
          }
        }

        // 'weekly' verisini güncelle
        if (currentDate >= last7DaysDate) {
          const formattedYearMonth = `${formattedDay}.${formattedMonth}.${year}`;
          if (!transformedData.weekly[formattedYearMonth]) {
            transformedData.weekly[formattedYearMonth] = formatValueRange([
              rateRange,
            ]);
          } else {
            transformedData.weekly[formattedYearMonth].push(
              formatValueRange([rateRange])
            );
          }
        }
      });
      if (
        type === t("bmi") || 
        type === t("weights") || 
        type === t("leanbody") || 
        type === t("bodyfat")
      ) {
        const yearlyEntries = Object.entries(transformedData.yearly);
        console.log("Yearly Entries for type:", yearlyEntries); // Check the entries
      
        if (yearlyEntries.length > 0) {
          yearlyEntries.sort(([dateA], [dateB]) => {
            const [monthA, yearA] = dateA.split('.').map(Number);
            const [monthB, yearB] = dateB.split('.').map(Number);
            return new Date(yearA, monthA - 1) - new Date(yearB, monthB - 1);
          });
      
          const [latestYearlyDate, latestValue] = yearlyEntries[yearlyEntries.length - 1];
      
          // Check if there is data in `daily`; if not, use the latest `yearly` data in `month.year` format
          if (!transformedData.daily || Object.keys(transformedData.daily).length === 0) {
            transformedData.daily = {
              [latestYearlyDate]: latestValue  // Use `month.year` format from `yearly`
            };
          }
        }
      }
      
      
      const calculateMinMax = (heartRates) => {
        let min = heartRates[0][0],
          max = heartRates[0][1];

        heartRates.forEach(([low, high]) => {
          if (low < min) min = low;
          if (high > max) max = high;
        });
        return `${min}-${max}`;
      };

      Object.keys(transformedData).forEach((category) => {
        if (
          (category === "sixMonths" || category === "yearly") &&
          type !== t("bloodGlucose") &&
          type !== t("bloodPressure") &&
          type !== t("oxygenSaturation") &&
          type !== t("respiratory") &&
          type !== t("heartRate")
        ) {
          Object.keys(transformedData[category]).forEach((key) => {
            const monthData = transformedData[category][key];
            function flattenArray(arr) {
              return arr.reduce(
                (acc, val) =>
                  acc.concat(Array.isArray(val) ? flattenArray(val) : val),
                []
              );
            }
            function calculateAverage(arr) {
              const flatArray = flattenArray(arr);
              const sum = flatArray.reduce((acc, val) => acc + val, 0);
              return sum / flatArray.length;
            }
            const average = calculateAverage(monthData);
            transformedData[category][key] = average.toFixed(2);
          });
        } else {
          Object.keys(transformedData[category]).forEach((key) => {
            transformedData[category][key] = calculateMinMax(
              transformedData[category][key]
            );
          });
        }
      });
      

      if (type === t("heartRate") || type === t("respiratory") || type === t("bloodGlucose") || type === t("sdnn") || type === t("heartraterecovery") || type === t("Resting Heart Rate") || type === t("weights") || type === t("bodyfat") || type === t("leanbody") || type === t("bmi")) {
        const monthlyValues = Object.entries(transformedData.monthly).map(([key, values]) => {
          let deger;
          if (type === t("heartRate") || type === t("respiratory") || type === t("bloodGlucose")) {
            const min = values.split("-")[0];
            const max = values.split("-")[1];
            deger = (parseFloat(min) + parseFloat(max)) / 2;
          } else if (type === t("sdnn") || type === t("heartraterecovery") || type === t("Resting Heart Rate") || type === t("weights") || type === t("bodyfat") || type === t("leanbody") || type === t("bmi")) {
            deger = values.split("-")[0];
          } else {
            // eslint-disable-next-line
            return;
          }

          return {
            date: key,
            value: parseFloat(deger)
          };
        }).sort((a, b) => new Date(a.date) - new Date(b.date));

        checkIncreasingTrend(monthlyValues, type);

      }

      if (type === t("bloodPressure")) {

      }

      return transformedData;
    } catch (error) {
      console.error("An error occurred:", error.message, type);
      return null;
    }
  };
  useEffect(() => {
    // Sayfa değiştiğinde tüm toast mesajlarını kapat
    return () => {
      toast.dismiss(); // Sayfa değiştiğinde toast'ı kapat
    };
  }, [location]);
  const checkIncreasingTrend = (data, type) => {
    const parseDate = (dateStr) => {
      const [day, month, year] = dateStr.split(/[-.]/).map(Number);
      return new Date(year, month - 1, day);
    };

    data.sort((a, b) => parseDate(a.date) - parseDate(b.date));

    const groupedByWeek = data.reduce((acc, entry) => {
      const date = parseDate(entry.date);
      const week = `${date.getFullYear()}-W${date.getWeekNumber()}`;
      if (!acc[week]) {
        acc[week] = [];
      }
      acc[week].push(parseFloat(entry.value));
      return acc;
    }, {});



    const weeklyAverages = Object.values(groupedByWeek).map(weekData => {
      const sum = weekData.reduce((sum, value) => sum + value, 0);
      return sum / weekData.length;
    });



    if (weeklyAverages.length <= 2) {

      return; // Fonksiyonu sonlandır
    }

    let increasingTrend = true;
    for (let i = 1; i < weeklyAverages.length; i++) {
      if (weeklyAverages[i] <= weeklyAverages[i - 1]) {

        increasingTrend = false;
        break;
      }
    }

    if (increasingTrend) {
      let errorMargin = type === "heartRate" ? 5 : 1;
      const lastValue = weeklyAverages[weeklyAverages.length - 1];
      const previousValue = weeklyAverages[0];
      const percentageIncrease = ((lastValue - previousValue) / previousValue) * 100;

      if (percentageIncrease > errorMargin) {
        console.log("Yüzde artış:", percentageIncrease, type);
        toast.error(t("warning_trend", { type: type }), {
          autoClose: false, // Mesaj kaybolmasın, sabit kalsın
        });
        // State'i güncelle: belirli bir tür (type) için artış eğilimi olduğunu işaretle
        setIncreasingTrends(prevState => {
          const newState = {
            ...prevState,

            [type]: true,
          };
          console.log(`Updated increasingTrends for ${type}:`, newState);
          console.log("Type used in checkIncreasingTrend:", type); // Check the exact value of type

          return newState;

        });

      } else {
        // Eğer percentageIncrease, errorMargin'dan büyük değilse (eşit veya küçükse)

        // State'i güncelle: belirli bir tür (type) için artış eğilimi olmadığını işaretle
        setIncreasingTrends(prevState => ({
          ...prevState, // Önceki state'i koru
          [type]: false, // Belirtilen tür (type) için değeri false yap
        }));
      }
    }
  };

  // eslint-disable-next-line
  Date.prototype.getWeekNumber = function () {
    const firstDayOfYear = new Date(this.getFullYear(), 0, 1);
    const pastDaysOfYear = (this - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  };
  // ------------------- TOMOGRAPHY, VIDEO, PDF FUNCTIONS START -------------------

  function toReadableFormat(str) {
    return str
      .replace(/([A-Z]+|[A-Z]?[a-z]+)(?=[A-Z]|\b)/g, "$1 ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  }

  const renderHealthData = (HealthDataUser, category) => {
    if (!HealthDataUser) {
      return <p>{t("healthDataNotAvailable")}</p>;
    }

    const healthDataArray = Object.entries(HealthDataUser)
      .filter(([key, _]) => key !== "timestamp" && category.some(item => item.value === key))
      .sort(([key1], [key2]) => {
        if (key1 === "healthScore") return -1;
        if (key2 === "healthScore") return 1;
        return toReadableFormat(key1).localeCompare(toReadableFormat(key2));
      });

    return (
      <ul className="accordion-list">
        <table className="health-data-table">
          <tbody>
            {healthDataArray.map(([key, value]) => {
              const readableKey = toReadableFormat(key);
              const unitKey = value ? `units.${key}` : "";
              const unit = value && unitKey ? t(unitKey, "") : "";

              return (
                <tr className="health-data-row" key={key}>
                  <td className="health-data-key">{t(`healthkit.${key}`, readableKey)}</td>
                  <td className="health-data-value">
                    {value ? value : t("Not available")}
                    {value && unit ? ` ${unit}` : ""}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </ul>
    );
  };
 // ------------------- CURRENT HEALTH DATA FUNCTIONS END -------------------

  const calculateRiskBasedOnSingleTest = (bloodTestData, possibleTestNames) => {
    let lastValue = null;
    // eslint-disable-next-line
    let lastTimestamp = 0;
    if (bloodTestData) {
      Object.entries(bloodTestData).forEach(([id, { result, timestamp }]) => {
        result.split("€").forEach((testString) => {
          if (testString) {
            const [currentTestName, testValue] = testString.split("$");
            if (possibleTestNames.includes(currentTestName)) {
              lastValue = testValue; // En son değeri güncelle
              lastTimestamp = timestamp; // En son zaman damgasını güncelle
            }
          }
        });
      });
    }
    if (lastValue) {
      const numericValue = parseFloat(lastValue);
      return numericValue;
    }
    return null; // Test değeri yoksa
  };
  const Dashboard = ({ user, isModalOpen, toggleModal, setNotificationCount }) => {
    const contentRef = useRef(null);
    const handleButtonClick = (componentName) => {
      setActiveComponent(componentName);
      scrollToContent();
    };
    const scrollToContent = () => {
      if (contentRef.current) {
        contentRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    };

    const renderComponent = () => {
      switch (activeComponent) {
        case "healthKit":
          return <HealthKitComponent />;
        case "about":
          return <AboutComponent
            user={user}
            doctor={doctor}
            riskScoreNeurology={riskScoreNeurology}
            riskScoreKardio={riskScoreKardio}
            riskScoreObezite={riskScoreObezite}
            riskScoreHiperTansiyon={riskScoreHiperTansiyon}
            riskScoreDiabet={riskScoreDiabet} />;
        case "documents":
          return <OtherMedicalDocumentsComponent user={user} />;
        case "notifications":
          return <PastNotifComponent
            isModalOpen={isModalOpen}
            toggleModal={toggleModal}
            user={user} // burada user prop'u geçiliyor
            setNotificationCount={setNotificationCount}
          />;
        case "blood":
          return <BloodComponent user={user} doctor={doctor} />;
        default:
          return <AboutComponent
            user={user}
            doctor={doctor}
            riskScoreNeurology={riskScoreNeurology}
            riskScoreKardio={riskScoreKardio}
            riskScoreObezite={riskScoreObezite}
            riskScoreHiperTansiyon={riskScoreHiperTansiyon}
            riskScoreDiabet={riskScoreDiabet} />;
      }
    };

    return (
      <div className="dashboard">
        <div className="menu">
          <button
            className={`button-details ${activeComponent === "about" ? "active" : ""}`}
            onClick={() => handleButtonClick("about")}
          >
            <p className="details-main">{t("About")}</p>
          </button>
          <button
            className={`button-details ${activeComponent === "healthKit" ? "active" : ""}`}
            onClick={() => handleButtonClick("healthKit")}
          >
            <p className="details-main">{t("Health Data")}</p>
          </button>
          <button
            className={`button-details ${activeComponent === "documents" ? "active" : ""}`}
            onClick={() => handleButtonClick("documents")}
          >
            <p className="details-main">{t("Documents")}</p>
          </button>
          <button
            className={`button-details ${activeComponent === "blood" ? "active" : ""}`}
            onClick={() => handleButtonClick("blood")}
          >
            <p className="details-main">{t("Blood")}</p>
          </button>
          <button
            className={`button-details ${activeComponent === "notifications" ? "active" : ""}`}
            onClick={() => handleButtonClick("notifications")}
          >
            <p className="details-main">{t("Past Notification")}</p>
          </button>
        </div>
        <div className="content" ref={contentRef}>
          {renderComponent()}
        </div>
      </div>
    );
  };
  const HealthKitComponent = () => {


    return (
      <div className="health-kit-component">
        {/* <h3 className="component-title">{t("Health Kit Data")}</h3> */}
        <Accordion style={{ marginTop: '35px' }}>
          <Row>
            <Col>
              <Row className="mb-2">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <span className="m-2 head-accordion">{t("currenthealthkit")}</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    {Object.keys(healthDataCategories).map((categoryKey, index) => (
                      <Accordion key={index}>
                        <Row className="mb-2">
                          <Accordion.Item eventKey={index.toString()}>
                            <Accordion.Header>
                              <span className="m-2">{t(`healthDataCategoriess.${categoryKey}`)}</span>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <ul className="health-data-list">
                                  {renderHealthData(user.HealthDataUser, healthDataCategories[categoryKey])}
                                </ul>
                                <p>
                                  <strong style={{ padding: '20px' }}>{t("lastUpdated")}: </strong>
                                  {currentDateTime.fullDate || t("Not available")}
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Row>
                      </Accordion>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Row>


              <Row className="mb-2">
                <Accordion.Item eventKey="18">
                  <Accordion.Header>

                    <span className="m-2 head-accordion">{t("bodymeasurements")}</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          {t("bodymassindex")}  {increasingTrends[t("Body Mass")] && (
                            <span style={{ color: 'red', marginLeft: '10px', fontSize: '1.5em', fontWeight: 'bold' }}>!</span>
                          )}
                        </Accordion.Header>
                        <Accordion.Body>
                          <Charts
                            type="Body Mass"
                            data={bmiData}
                            unit="kg/m2"
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>{t("bodymass")} {increasingTrends[t("Body Fat")] && (
                          <span style={{ color: 'red', marginLeft: '10px', fontSize: '1.5em', fontWeight: 'bold' }}>!</span>
                        )}</Accordion.Header>
                        <Accordion.Body>
                          <Charts
                            type="Body Fat"
                            data={bodyFatPercentageData}
                            unit="%"
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>{t("leanbody")} {increasingTrends[t("Lean Body Mass")] && (
                          <span style={{ color: 'red', marginLeft: '10px', fontSize: '1.5em', fontWeight: 'bold' }}>!</span>
                        )}</Accordion.Header>
                        <Accordion.Body>
                          <Charts
                            type="Lean Body"
                            data={leanBodyMassData}
                            unit="kg"
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>{t("weightgraf")} {increasingTrends[t("Weight")] && (
                          <span style={{ color: 'red', marginLeft: '10px', fontSize: '1.5em', fontWeight: 'bold' }}>!</span>
                        )} </Accordion.Header>
                        <Accordion.Body>
                          <Charts type="Weight" data={weightData} unit="kg" />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              </Row>
              <Row className="mb-2">
                <Accordion.Item eventKey="39">
                  <Accordion.Header>

                    <span className="m-2 head-accordion">{t("heart")}</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Accordion>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>{t("heartgraf")} {increasingTrends[t("Heart Rate")] && (
                          <span style={{ color: 'red', marginLeft: '10px', fontSize: '1.5em', fontWeight: 'bold' }}>!</span>
                        )}</Accordion.Header>
                        <Accordion.Body>
                          <TwoCharts
                            type="Heart Rate"
                            data={heartRateData}
                            unit="bpm"
                            dataKey="value"
                            maxValueKey="maxValue"
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="6">
                        <Accordion.Header>{t("heartratevar")} {increasingTrends[t("Heart Rate Variability")] && (
                          <span style={{ color: 'red', marginLeft: '10px', fontSize: '1.5em', fontWeight: 'bold' }}>!</span>
                        )}</Accordion.Header>
                        <Accordion.Body>
                          <Charts
                            type="Heart Rate Variability"
                            data={heartRateVariabilitySDNNData}
                            unit="bpm"
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="9">
                        <Accordion.Header>
                          {t("restingheartrate")}
                          {increasingTrends[t("Resting Heart Rate")] && (
                            <span style={{ color: 'red', marginLeft: '10px', fontSize: '1.5em', fontWeight: 'bold' }}>!</span>
                          )}
                        </Accordion.Header>
                        <Accordion.Body>
                          <Charts
                            type="Resting Heart Rate"
                            data={restingHeartRateData}
                            unit="bpm"
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="15">
                        <Accordion.Header>
                          {t("heartraterecovery")} {increasingTrends[t("Heart Rate Recovery")] && (
                            <span style={{ color: 'red', marginLeft: '10px', fontSize: '1.5em', fontWeight: 'bold' }}>!</span>
                          )}
                        </Accordion.Header>
                        <Accordion.Body>
                          <Charts
                            type="Heart Rate Recovery"
                            data={heartRateRecoveryData}
                            unit="bpm"
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              </Row>
              <Row className="mb-2">
                <Accordion.Item eventKey="41">
                  <Accordion.Header>

                    <span className="m-2 head-accordion">{t("respiratory")}</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Accordion>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          {t("oxygensaturation")}
                        </Accordion.Header>
                        <Accordion.Body>
                          <TwoCharts
                            type="Oxygen Saturation"
                            data={oxygenSaturationData}
                            unit="%"
                            dataKey="value"
                            maxValueKey="maxValue"
                          />
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="7">

                        <Accordion.Header>

                          {t("respiratoryrate")}  {increasingTrends["Respiratory"] && (
                            <span style={{ color: 'red', marginLeft: '10px', fontSize: '1.5em', fontWeight: 'bold' }}>!</span>
                          )}
                        </Accordion.Header>
                        <Accordion.Body>
                          <TwoCharts
                            type="Respiratory Rate"
                            data={respiratoryRateData}
                            unit="breaths/min"
                            dataKey="value"
                            maxValueKey="maxValue"
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              </Row>
              <Row className="mb-2">
                <Accordion.Item eventKey="19">
                  <Accordion.Header>

                    <span className="m-2 head-accordion">{t("nutrition")}</span>
                  </Accordion.Header>

                  <Accordion.Body>
                    <Accordion defaultActiveKey={0}>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>{t("Minerals")}</Accordion.Header>
                        <Accordion.Body>
                          <Accordion>
                            <Accordion.Item eventKey="1">
                              <Accordion.Header>{t("water")}</Accordion.Header>
                              <Accordion.Body>
                                <BarCharts
                                  type="Water"
                                  data={waterData}
                                  unit="L"
                                />
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                              <Accordion.Header>
                                {t("carbonhydrate")}
                              </Accordion.Header>
                              <Accordion.Body>
                                <BarCharts
                                  type="Carbonhydrate"
                                  data={carbonhydrateData}
                                  unit="g"
                                />
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                              <Accordion.Header>
                                {t("protein")}
                              </Accordion.Header>
                              <Accordion.Body>
                                <BarCharts
                                  type="Protein"
                                  data={proteinData}
                                  unit="g"
                                />
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                              <Accordion.Header>
                                {t("magnesium")}
                              </Accordion.Header>
                              <Accordion.Body>
                                <BarCharts
                                  type="Magnesium"
                                  data={magnesiumData}
                                  unit="mg"
                                />
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                              <Accordion.Header>{t("iron")}</Accordion.Header>
                              <Accordion.Body>
                                <BarCharts
                                  type="Iron"
                                  data={ironData}
                                  unit="mg"
                                />
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                              <Accordion.Header>{t("copper")}</Accordion.Header>
                              <Accordion.Body>
                                <BarCharts
                                  type="Copper"
                                  data={copperData}
                                  unit="mg"
                                />
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </Accordion.Body>
                        <Accordion defaultActiveKey={0}>
                          <Accordion.Item eventKey="7">
                            <Accordion.Header>{t("Vitamins")}</Accordion.Header>
                            <Accordion.Body>
                              <Accordion>
                                <Accordion.Item eventKey="8">
                                  <Accordion.Header>
                                    {t("vitaminc")}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <BarCharts
                                      type="Vitamin C"
                                      data={vitaminCData}
                                      unit="mg"
                                    />
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="9">
                                  <Accordion.Header>
                                    {t("vitamind")}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <BarCharts
                                      type="Vitamin D"
                                      data={vitaminDData}
                                      unit="mcg"
                                    />
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="10">
                                  <Accordion.Header>
                                    {t("vitamina")}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <BarCharts
                                      type="Vitamin A"
                                      data={vitaminAData}
                                      unit="mg"
                                    />
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="11">
                                  <Accordion.Header>
                                    {t("vitamine")}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <BarCharts
                                      type="Vitamin E"
                                      data={vitaminEData}
                                      unit="mg"
                                    />
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="12">
                                  <Accordion.Header>
                                    {t("vitamink")}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <BarCharts
                                      type="Vitamin K"
                                      data={vitaminKData}
                                      unit="mg/dl"
                                    />
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="13">
                                  <Accordion.Header>
                                    {t("vitaminb6")}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <BarCharts
                                      type="Vitamin B6"
                                      data={vitaminB6Data}
                                      unit="mg"
                                    />
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="14">
                                  <Accordion.Header>
                                    {t("vitaminb12")}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <BarCharts
                                      type="B12"
                                      data={b12Data}
                                      unit="mcg"
                                    />
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </Accordion.Body>
                            <Accordion defaultActiveKey={3}>
                              <Accordion.Item eventKey="15">
                                <Accordion.Header>
                                  {t("Dietary")}
                                </Accordion.Header>
                                <Accordion.Body>
                                  <Accordion>
                                    <Accordion.Item eventKey="16">
                                      <Accordion.Header>
                                        {t("dietaryenergy")}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <BarCharts
                                          type="Dietary Energy"
                                          data={dietaryEnergyData}
                                          unit="kcal"
                                        />
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="17">
                                      <Accordion.Header>
                                        {t("dietarycholesterol")}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        {" "}
                                        <BarCharts
                                          type="Dietary Cholesterol"
                                          data={dietaryCholesterolData}
                                          unit="mg"
                                        />
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="18">
                                      <Accordion.Header>
                                        {t("dietarysugar")}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <BarCharts
                                          type="Dietary Sugar"
                                          data={dietarySugarData}
                                          unit="mg"
                                        />
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="19">
                                      <Accordion.Header>
                                        {t("dietarybiotin")}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <BarCharts
                                          type="Dietary Biotin"
                                          data={dietaryBiotinData}
                                          unit="mg"
                                        />
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="20">
                                      <Accordion.Header>
                                        {t("dietaryzinc")}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <BarCharts
                                          type="Dietary Zinc"
                                          data={dietaryZincData}
                                          unit="mg"
                                        />
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="21">
                                      <Accordion.Header>
                                        {t("dietaryfatpolyunsaturated")}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <BarCharts
                                          type="Dietary Fat Polyunsaturated"
                                          data={dietaryFatPolyunsaturatedData}
                                          unit="mg"
                                        />
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="22">
                                      <Accordion.Header>
                                        {t("dietaryfatsaturated")}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <BarCharts
                                          type="Dietary Fat Saturated"
                                          data={dietaryFatSaturatedData}
                                          unit="mg"
                                        />
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="23">
                                      <Accordion.Header>
                                        {t("dietaryfolate")}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <BarCharts
                                          type="Dietary Folate"
                                          data={dietaryFolateData}
                                          unit="mg"
                                        />
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="24">
                                      <Accordion.Header>
                                        {t("dietaryphosphorus")}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <BarCharts
                                          type="Dietary Phosphorus"
                                          data={dietaryPhosphorusData}
                                          unit="mg"
                                        />
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="25">
                                      <Accordion.Header>
                                        {t("dietaryiodine")}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <BarCharts
                                          type="Dietary Iodine"
                                          data={dietaryIodineData}
                                          unit="mg"
                                        />
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="26">
                                      <Accordion.Header>
                                        {t("dietarycaffeine")}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <BarCharts
                                          type="Dietary Caffeine"
                                          data={dietaryCaffeineData}
                                          unit="mg"
                                        />
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="27">
                                      <Accordion.Header>
                                        {" "}
                                        {t("dietarycalcium")}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <BarCharts
                                          type="Dietary Calcium"
                                          data={dietaryCalciumData}
                                          unit="mg"
                                        />
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="28">
                                      <Accordion.Header>
                                        {t("dietarychloride")}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <BarCharts
                                          type="Dietary Chloride"
                                          data={dietaryChlorideData}
                                          unit="mg"
                                        />
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="29">
                                      <Accordion.Header>
                                        {t("dietarychromium")}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <BarCharts
                                          type="Dietary Chromium"
                                          data={dietaryChromiumData}
                                          unit="mg"
                                        />
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="30">
                                      <Accordion.Header>
                                        {t("dietaryfiber")}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <BarCharts
                                          type="Dietary Fiber"
                                          data={dietaryFiberData}
                                          unit="mg"
                                        />
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="31">
                                      <Accordion.Header>
                                        {t("dietarymanganese")}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <BarCharts
                                          type="Dietary Manganese"
                                          data={dietaryManganeseData}
                                          unit="mg"
                                        />
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="32">
                                      <Accordion.Header>
                                        {t("dietarymolybdenum")}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <BarCharts
                                          type="Dietary Molybdenum"
                                          data={dietaryMolybdenumData}
                                          unit="mg"
                                        />
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="33">
                                      <Accordion.Header>
                                        {t("dietaryniacin")}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <BarCharts
                                          type="Dietary Niacin"
                                          data={dietaryNiacinData}
                                          unit="mg"
                                        />
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="34">
                                      <Accordion.Header>
                                        {t("dietarypanthotenicacid")}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <BarCharts
                                          type="Dietary Pantothenic Acid"
                                          data={dietaryPantothenicAcidData}
                                          unit="mg"
                                        />
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="35">
                                      <Accordion.Header>
                                        {t("dietarypotassium")}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <BarCharts
                                          type="Dietary Potassium"
                                          data={dietaryPotassiumData}
                                          unit="mg"
                                        />
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="36">
                                      <Accordion.Header>
                                        {t("dietaryriboflavin")}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <BarCharts
                                          type="Dietary Riboflavin"
                                          data={dietaryRiboflavinData}
                                          unit="mg"
                                        />
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="37">
                                      <Accordion.Header>
                                        {t("dietaryselenium")}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <BarCharts
                                          type="Dietary Selenium"
                                          data={dietarySeleniumData}
                                          unit="mg"
                                        />
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="38">
                                      <Accordion.Header>
                                        {t("dietarysodium")}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <BarCharts
                                          type="Dietary Sodium"
                                          data={dietarySodiumData}
                                          unit="mg"
                                        />
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="39">
                                      <Accordion.Header>
                                        {t("dietaryfatmonounsaturated")}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <BarCharts
                                          type="Dietary Fat Monounsaturated"
                                          data={dietaryFatMonounsaturatedData}
                                          unit="mg"
                                        />
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="40">
                                      <Accordion.Header>
                                        {t("dietarythiamin")}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <BarCharts
                                          type="Dietary Thiamin"
                                          data={dietaryThiaminData}
                                          unit="mg"
                                        />
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="41">
                                      <Accordion.Header>
                                        {t("dietaryfattotal")}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <BarCharts
                                          type="Dietary Fat Total"
                                          data={dietaryFatTotalData}
                                          unit="mg"
                                        />
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </Accordion.Item>
                        </Accordion>
                      </Accordion.Item>
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              </Row>
            </Col>

            <Col className="ms-md-3">
              <Row className="mb-2">
                <Accordion.Item eventKey="20">
                  <Accordion.Header>

                    <span className="m-2 head-accordion">{t("healthscore")} </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Charts
                      type="Health Score"
                      data={healthScoreData}
                      unit="points"
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Row>
              <Row className="mb-2">
                <Accordion.Item eventKey="44">
                  <Accordion.Header>

                    <span className="m-2 head-accordion">{t("sleep")}</span>
                  </Accordion.Header>

                  <Accordion.Body>
                    <Charts type="Sleep" data={sleepData} unit="hrs" />
                  </Accordion.Body>
                </Accordion.Item>
              </Row>
              <Row className="mb-2">
                <Accordion.Item eventKey="3">
                  <Accordion.Header>

                    <span className="m-2 head-accordion">{t("mobility")}</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Accordion>
                      <Accordion.Item eventKey="41">
                        <Accordion.Header>
                          {t("distancewalking")}
                        </Accordion.Header>

                        <Accordion.Body>
                          <BarCharts
                            type="Distance Walking"
                            data={distanceWalkingData}
                            unit="m"
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="45">
                        <Accordion.Header>
                          {t("walkingasymetry")}
                        </Accordion.Header>

                        <Accordion.Body>
                          <Charts
                            type="Walking Asymetry"
                            data={walkingAsymmetryData}
                            unit="%"
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="46">
                        <Accordion.Header>
                          {t("sixminuteswalk")}
                        </Accordion.Header>

                        <Accordion.Body>
                          <BarCharts
                            type="Six Minutes Walk"
                            data={sixMinutesWalkData}
                            unit="m"
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header>{t("exercisetime")}</Accordion.Header>
                        <Accordion.Body>
                          <BarCharts
                            type="Exercise Time"
                            data={exerciseTimeData}
                            unit="mins"
                          />
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="5">
                        <Accordion.Header>
                          {t("flightsclimbed")}
                        </Accordion.Header>
                        <Accordion.Body>
                          <BarCharts
                            type="Flights Climbed"
                            data={flightsClimbedData}
                            unit="floors"
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="11">
                        <Accordion.Header>{t("steps")}</Accordion.Header>
                        <Accordion.Body>
                          <BarCharts
                            type="Steps"
                            data={stepsData}
                            unit="step"
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="8">
                        <Accordion.Header>
                          {t("restingenergy")}
                        </Accordion.Header>
                        <Accordion.Body>
                          <BarCharts
                            type="Resting Energy"
                            data={restingEnergyData}
                            unit="kcal"
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="10">
                        <Accordion.Header>{t("standhours")}</Accordion.Header>
                        <Accordion.Body>
                          <BarCharts
                            type="Stand Hours"
                            data={standHoursData}
                            unit="hrs"
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="16">
                        <Accordion.Header>{t("vo2max")}</Accordion.Header>
                        <Accordion.Body>
                          <Charts
                            type="VO2 Max"
                            data={vo2MaxData}
                            unit="ml/kg/min"
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              </Row>
              <Row className="mb-2">
                <Accordion.Item eventKey="42">
                  <Accordion.Header>

                    <span className="m-2 head-accordion">{t("bloodaccordion")}</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Accordion>
                      <Accordion.Item eventKey="14">
                        <Accordion.Header>{t("bloodglucose")} {increasingTrends["Blood Glucose Chart"] && (
                          <span style={{ color: 'red', marginLeft: '10px', fontSize: '1.5em', fontWeight: 'bold' }}>!</span>
                        )}</Accordion.Header>
                        <Accordion.Body>
                          <TwoCharts
                            type="Blood Glucose"
                            data={bloodGlucoseData}
                            unit="mg/dl"
                            dataKey="value"
                            maxValueKey="maxValue"
                          />
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="17">
                        <Accordion.Header>
                          {t("bloodpressure")}
                        </Accordion.Header>
                        <Accordion.Body>
                          <TwoCharts
                            type="Blood Pressure"
                            data={bloodPressureData}
                            unit="mmHg"
                            dataKey="value"
                            maxValueKey="maxValue"
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              </Row>
              <Row className="mb-2">
                <Accordion.Item eventKey="43">
                  <Accordion.Header>

                    <span className="m-2 head-accordion">{t("calorie")}</span>
                  </Accordion.Header>

                  <Accordion.Body>
                    <BarCharts
                      type="Daily Calorie"
                      data={todayCaloriesData}
                      unit="kcal"
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Row>
            </Col>
          </Row>
        </Accordion>
      </div>
    );
  };

  // ------------------- RENDER FUNCTIONS START -------------------
  return (
    <>
      <div>
        <div>
          <NavbarMenu />
        </div>
        <div style={{
          paddingLeft: isSidebarCollapsed ? "100px" : "300px",
          ...(window.innerWidth <= 768 && { paddingLeft: "100px" }),
        }}>
          <div className="bg">
            {user ? (
              <div
                className="container-fluid"
                style={{ marginTop: "40px", marginRight: '50px' }}
                data-bs-theme="light"
              >
                <nav aria-label="breadcrumb">
                  <div className="breadcrumb">
                    <div className="breadcrumb-item">
                      <a style={{ color: '#677184' }} href="/users">Patients</a>
                    </div>
                    <div className="breadcrumb-separator"> {'>'}</div>
                    <div className="breadcrumb-item active custom-breadcrumb">
                      {user.name}
                    </div>

                  </div>
                </nav>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                  <Zoom>
                    <img
                      src={user.profileImageUrl || defaultAvatar} // Kullanıcının profil resminin URL'si
                      alt={`${user.name}'s profile`}
                      style={{
                        width: '60px',
                        height: '60px',
                        borderRadius: '50%',
                        marginRight: '10px',
                        objectFit: 'cover',
                      }}
                    />
                  </Zoom>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h1 style={{ fontSize: '24px', fontWeight: '500', lineHeight: '32px', fontFamily: 'Poppins' }}>
                      {user.name}
                    </h1>
                    {expirationDate && (
                      <div className="premium-expiration" style={{ fontSize: '14px', color: '#000000' }}>
                        {t('Premium ends on')}: {expirationDate.toLocaleString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',

                        })}
                      </div>
                    )}
                  </div>
                </div>
                <hr />

                <div className="user-details">

                  <Dashboard user={user} isModalOpen={isModalOpen} toggleModal={toggleModal} setNotificationCount={setNotificationCount} />

                  <Modal
                    show={showModal}
                    onHide={handleCloseModal}
                    size="lg"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>{t("videodocuments")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <video
                        src={activeVideoUrl}
                        controls
                        className="w-100"
                        style={{ maxHeight: "70vh" }}
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleCloseModal}>
                        {t("close")}
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            ) : (
              <div className="d-flex align-items-center justify-content-center vh-100">
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetails;