import React, { useContext, useEffect, useState } from "react";
import './Dashboard.css';
import { CircularProgress } from "@mui/material";
import NavbarMenu from "../navbar/NavbarMenu";
import { useTranslation } from "react-i18next";
import { SidebarContext } from "../../context/SidebarContext";
import { UserContext } from "../../context/UserContext";
import { db } from "../../firebase"; // Assuming Firebase is initialized
import { doc, getDoc } from "firebase/firestore";
import BarCharts from "../charts/BarCharts"; 

const Dashboard = () => {
    const { t } = useTranslation();
    const { clients, loading } = useContext(UserContext);
    const { isSidebarCollapsed } = useContext(SidebarContext);
    const [restingHeartRateData, setRestingHeartRateData] = useState({
        dailyAverage: 0,
        weeklyAverage: 0,
        monthlyAverage: 0,
        sixMonthsAverage: 0,
        yearlyAverage: 0,
    });
    useEffect(() => {
        const fetchAverageData = async () => {
            try {
                console.log("Veri çekme işlemi başlatıldı..."); // Başlangıç mesajı
                const dashboardDocRef = doc(db, "dashboard", "oTTKLqD1Q6NxgI3UEpsS1ATGc6h1"); // Belge ID'sini burada kontrol edin
                const dashboardDoc = await getDoc(dashboardDocRef);
        
                if (dashboardDoc.exists()) {
                    const data = dashboardDoc.data();
                    console.log("Firestore'dan alınan veri:", data); // Veri yapısını kontrol edin
    
                    setRestingHeartRateData({
                        dailyAverage: data.daily?.restingHeartRate || 0,
                        weeklyAverage: data.weekly?.restingHeartRate || 0,
                        monthlyAverage: data.monthly?.restingHeartRate || 0,
                        sixMonthsAverage: data.sixMonth?.restingHeartRate || 0,
                        yearlyAverage: data.yearly?.restingHeartRate || 0,
                    });
                } else {
                    console.log("Dashboard belgesinde veri bulunamadı."); // Belge bulunamazsa
                }
            } catch (error) {
                console.error("Ortalama kalp hızı verisi alınırken hata oluştu:", error); // Hata mesajını ayrıntılı göster
            }
        };
    
        fetchAverageData();
    }, []);
    
    
    return (
        <div>
            <NavbarMenu />
            <div>
                {loading ? (
                    <div className="d-flex align-items-center justify-content-center vh-100">
                        <CircularProgress />
                    </div>
                ) : (
                    <div className="container">
                        <div className={`datatable ${isSidebarCollapsed ? "sidebar-collapsed" : "sidebar-open"}`}>
                            <div className="row align-items-center">
                                <div className="patient-container">
                                    <h1 className="head" style={{ marginTop: '30px' }}>{t('Company Logo')}</h1>
                                </div>
                                <h2 style={{ marginTop: '20px' }}>DASHBOARD</h2>
                            </div>

                            {/* Total Patients and Buttons Section */}
                            <div className="row">
                                <div className="col-6 text- mt-3 mb-3">
                                    <div className="pt-4 pe-2">
                                        <span style={{
                                            fontFamily: "Poppins",
                                            fontSize: "18px",
                                            fontWeight: 600,
                                            lineHeight: "28px",
                                            color: "#141517",
                                        }}>
                                            {t('Total Users')}: {clients.length}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {/* Average Resting Heart Rate and Graph Section */}
                            <div className="average-heart-rate-container mt-4">
                                <h2 className="average-heart-rate-title">Average Resting Heart Rate</h2>
                                <div className="average-heart-rate-content">
                                    {/* Display Averages */}
                                    <div className="average-values">
                                        <p>Daily Average: {restingHeartRateData.dailyAverage} bpm</p>
                                        <p>Weekly Average: {restingHeartRateData.weeklyAverage} bpm</p>
                                        <p>Monthly Average: {restingHeartRateData.monthlyAverage} bpm</p>
                                        <p>Six Months Average: {restingHeartRateData.sixMonthsAverage} bpm</p>
                                        <p>Yearly Average: {restingHeartRateData.yearlyAverage} bpm</p>
                                    </div>

                                    {/* Graph Section */}
                                    <div className="graph-section">
                                        <div className="graph-container">
                                            <BarCharts type="Resting Heart Rate" data={restingHeartRateData} unit="bpm" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Dashboard;
