import { useState, useEffect } from "react";
import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

const useFetchUserDetails = (userId, databases, activeComponent) => {
  const [userDetails, setUserDetails] = useState([]);
  const [expirationDate, setExpirationDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isHealthKitDataFetched, setIsHealthKitDataFetched] = useState(false);
  const [fetchedHealthScores, setFetchedHealthScores] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      // 'users' koleksiyonundan veri çekme
      const userPromises = databases.map(database =>
        getDoc(doc(database, 'users', userId))
      );

      const userResults = await Promise.all(userPromises);

      for (const clientSnap of userResults) {
        if (clientSnap.exists()) {
          const data = clientSnap.data();
          if (data.doctorPaymentExpiration && data.doctorPaymentExpiration.toDate) {
            setExpirationDate(data.doctorPaymentExpiration.toDate());
          }
        }
      }

      // 'healthKit' bileşeni aktif ve henüz veri çekilmediyse
     

        const healthKitPromises = databases.map(database =>
          getDoc(doc(database, 'usersMonthData', userId))
        );

        const healthKitResults = await Promise.all(healthKitPromises);
        const foundData = healthKitResults.find(docSnap => docSnap.exists());

        if (foundData) {
          setUserDetails([foundData.data()]);
          setIsHealthKitDataFetched(true); // Veri çekildi olarak işaretle
        } else {
          setUserDetails([]);
        }
      }

      setLoading(false);
    

    fetchData();
  }, [userId, databases, activeComponent, isHealthKitDataFetched]);
  
 useEffect(() => {
    const fetchHealthScores = async () => {
      try {
        const healthScorePromises = databases.map((database) =>
          getDoc(doc(database, "healthscorePastYearly", userId))
        );

        const healthScoreResults = await Promise.all(healthScorePromises);
        const healthScoreData = healthScoreResults.find((docSnap) => docSnap.exists());

        if (healthScoreData) {
          setFetchedHealthScores(healthScoreData.data());
        } else {
          console.log("No health scores found for user:", userId);
          setFetchedHealthScores([]);
        }
      } catch (error) {
        console.error("Error fetching health scores:", error);
      }
    };

    fetchHealthScores();
  }, [userId, databases]);


  return { userDetails, expirationDate, loading, fetchedHealthScores };
};

export default useFetchUserDetails;
