import React, { useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  onAuthStateChanged,
} from "firebase/auth";
import { setDoc, doc, updateDoc } from "firebase/firestore";
import { auth, storage, db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { useTranslation } from "react-i18next";
import { specializations } from "../../constants/specializations";
import { v4 } from "uuid";
import './register.css'

import { Dropdown } from "react-bootstrap";
import deFlag from "../../images/de.png";
import usFlag from "../../images/eng.svg";
import trFlag from "../../images/turkey.svg";
import logo from '../../images/icons/Content.svg'
function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [gender, setGender] = useState("");
  const { t } = useTranslation();
  const [name, setName] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [clients, setClients] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [pending, setPending] = useState([]);
  const [currentFlagUrl, setCurrentFlagUrl] = useState(usFlag);
  const [surname, setSurname] = useState("");
  const [specilization, setSpecilization] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [about, setAbout] = useState("");
  const [error, setError] = useState(false);
  const [isDoctor, setIsDoctor] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [imageUpload, setImageUpload] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [fileName, setFileName] = useState('');
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const isFormValid = email !== "" && password !== "";

  useEffect(() => {
    if (!isDoctor) {
      navigate("/register-company");
    }
  }, [isDoctor, navigate]);

  const handleOptionChange = (isDoctorSelected) => {
    setIsDoctor(isDoctorSelected);
    if (!isDoctorSelected) {
      navigate("/register-company");
    }
  };
  
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("appLanguage", lng);
    setCurrentFlagUrl(getCurrentFlag(lng));
  };

  const getCurrentFlag = (lang) => {
    switch (lang) {
      case "en":
        return usFlag;
      case "tr":
        return trFlag;
      case "de":
        return deFlag;
      default:
        return usFlag;
    }
  };

  useEffect(() => {
    const savedLang = localStorage.getItem("appLanguage") || "en";
    i18n.changeLanguage(savedLang);
    setCurrentFlagUrl(getCurrentFlag(savedLang));
  }, [i18n]);

  const handleRegister = async (e) => {
    e.preventDefault();
    setError(false);

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const userProfileDocumentReference = doc(db, `users/${userCredential.user.uid}`);
      await setDoc(userProfileDocumentReference, { email, emailVerified: false });

      const user = userCredential.user;

      await sendEmailVerification(user);
  const clientId = localStorage.getItem('clientId');
      const clientsArray = clientId ? [clientId] : [];
      if (imageUpload) {
        const imageRef = ref(storage, `DoctorFiles/${imageUpload.name + v4()}`);
        await uploadBytes(imageRef, imageUpload).then((snapshot) => {
          return getDownloadURL(snapshot.ref);
        }).then((downloadURL) => {
          return setDoc(doc(db, "doctors", user.uid), {
            uid: user.uid,
            email: email,
            Name: name,
            Gender: gender,
            Surname: surname,
            Specilization: specilization,
            aboutMe: about,
            imageUrl: downloadURL,  
            clients: clientsArray,
            pending: pending,
            role: "doctor",
            type: "doctor",
          });
        });
      } else {
        await setDoc(doc(db, "doctors", user.uid), {
          uid: user.uid,
          email: email,
          Name: name,
          Gender: gender,
          Surname: surname,
          Specilization: specilization,
          aboutMe: about,
          clients: clientsArray,
          pending: [],
          notificationOf: [],
          role: "doctor",
          type: "doctor",
        },{merge:true});
      }

      if (clientId) {
        localStorage.removeItem('clientId');
        toast.success(t("Patient added successfully."));
      }

      toast.success(t("Registration successful! Please check your email to verify your account."));
      navigate("/login");
    } catch (error) {
      setError(true);
      toast.error(t("Registration failed. Please try again."));
      console.error(error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user && user.emailVerified) {
        const userDocRef = doc(db, "users", user.uid);
        await updateDoc(userDocRef, {
          emailVerified: true
        });
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="register">
      <div className="containerr">
        <div className="register-left">
        <div className="subcont">
          <div className="form-containerr">
          <Dropdown>
              <Dropdown.Toggle
                style={{ backgroundColor: "transparent", border: "none" }}
                variant="success"
                id="dropdown-basic"
                className="btn-info"
              >
                <img
                  src={currentFlagUrl}
                  alt="Current Language"
                  width="40"
                  height="30"
                />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => changeLanguage("en")}>
                  <img src={usFlag} alt="English" width="40" height="30" />
                  English
                </Dropdown.Item>
                <Dropdown.Item onClick={() => changeLanguage("tr")}>
                  <img src={trFlag} alt="Türkçe" width="40" height="30" />
                  Türkçe
                </Dropdown.Item>
                <Dropdown.Item onClick={() => changeLanguage("de")}>
                  <img src={deFlag} alt="Deutsch" width="40" height="30" />
                  Deutsch
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          
            <div className="login-header" style={{marginBottom:'0px'}}>
              
            <img src={logo} alt="Realhealth Logo" className="logo-img" />
            
            <span className="logo-title" style={{marginBottom:'16px'}}>Realhealth</span>
            
          </div>

        
          <div className="button-group mb-1 ">
          <button
  type="button" // Add this line
  className={`option-button ${isDoctor ? 'selected' : ''}`}
  onClick={() => handleOptionChange(true)}
>
  {t("For Doctors")}
</button>
<button
  type="button" // And this line
  className={`option-button ${!isDoctor ? 'selected' : ''}`}
  onClick={() => handleOptionChange(false)}
>
  {t("For Businesses")}
</button>

              </div>
            <form className="login-form" style={{marginTop:'20px'}} onSubmit={handleRegister}>
           
              <div className="input-group-register">
                <label htmlFor="name" className="input-label">{t("Name")}*</label>
                <input
                  type="text"
                  className="form-input"
                  id="name"
                  onChange={(e) => setName(e.target.value)}
                  placeholder={t("Enter your name")}
                  required
                />
              </div>
              <div className="input-group-register">
                <label htmlFor="surname" className="input-label">{t("Surname")}*</label>
                <input
                  type="text"
                  className="form-input"
                  id="surname"
                  onChange={(e) => setSurname(e.target.value)}
                  placeholder={t("Enter your surname")}
                  required
                />
              </div>
              <div className="input-group-register">
                <span className="icon">
             
                </span>
                <label htmlFor="email" className="input-label">{t("Email")}*</label>
                <input
                  type="email"
                  id="email"
                  placeholder={t("Enter your email")}
                  className={`form-input ${email ? "filled" : ""}`}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onFocus={(e) => e.target.classList.add('focused')}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      e.target.classList.remove('focused');
                    }
                  }}
                />
              </div>
              <div className="input-group-register">
                <label htmlFor="specilization" className="input-label">{t("Specialization")}*</label>
                <select
                  className="form-input"
                  id="specilization"
                  value={specilization}
                  onChange={(e) => setSpecilization(e.target.value)}
                  required
                >
                  <option value="">{t('Select your specialization')}</option>
                  {specializations.map((spec, index) => (
                    <option key={index} value={spec}>
                      {spec}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-group-register">
                <label htmlFor="gender" className="input-label">{t('gender')}*</label>
                <select
                  name="gender"
                  className="form-input"
                  placeholder="Select your gender"
                  id="gender"
                  onChange={(e) => setGender(e.target.value)}
                  required
                >
                  <option value="">{t("Select your gender")}</option>
                  <option value="Female">{t("Female")}</option>
                  <option value="Male">{t("Male")}</option>
                </select>
              </div>
              {/* <div className="input-group-register">
                <label htmlFor="about" className="input-label">{t("About")}</label>
                <textarea
                  className="form-input"
                  id="about"
                  onChange={(e) => setAbout(e.target.value)}
                  placeholder={t("Write about yourself")}
                  required
                />
              </div> */}
              
              <div className="input-group-register">
               
                <label htmlFor="password" className="input-label">{t("Password")}*</label>
                <input
                  type="password"
                  id="password"
                  placeholder={t("Enter your password")}
                  className={`form-input ${password ? "filled" : ""}`}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onFocus={(e) => e.target.classList.add('focused')}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      e.target.classList.remove('focused');
                    }
                  }}
                />
              </div>
              <div className="button-group m-2">
                <button
                  type="submit"
                  className={`form-button ${isFormValid ? "active" : ""}`}
                  disabled={!isFormValid}
                >
                  {t('Register')}
                </button>
              </div>
              <div className="mt-3">
                <span className=" d-flex align-items-center justify-content-center" style={{fontWeight:'400',fontSize:'14px',color:'#677184'}}> {t("Already have an account?") }
                <a className="m-2 login-buton"  style={{color:'#7978DE',textDecoration:'none'}} href="/login">
                  {t('Login')}
                </a>
                </span>
              </div>
              {error && (
                <div className="alert alert-danger mt-3" role="alert">
                  Registration failed!
                </div>
              )}
            </form>
          </div>
         
        </div>
      
      </div>
      <div className="register-right"></div>
      </div>
     
    </div>
  );
}

export default Register;
