import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

// Ana Firebase yapılandırması
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Ana Firebase uygulamasını başlat
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const db2 = getFirestore(app,'amsterdam')
export const db3 = getFirestore(app,'belgium')
export const db4 = getFirestore(app,'berlin')
export const db5 = getFirestore(app,'finland')
export const db6 = getFirestore(app,'london')
export const db7 = getFirestore(app,'unitedstatesofamerica')
export const db8 = getFirestore(app,'warsaw')
export const db9 = getFirestore(app,'zurich')

export const auth = getAuth();
export const storage = getStorage(app);
export const functions = getFunctions(app);


