import NotificationsModal from "../../components/pastNotifications/pastNotifications";
const PastNotifComponent = ({ isModalOpen, toggleModal, user, setNotificationCount }) => {
    if (!user) {
        return <div>Yükleniyor...</div>; // Alternatif olarak, bir hata mesajı da gösterebilirsiniz
    }
    return (
      <div className="container">

        <NotificationsModal
          isOpen={isModalOpen}
          close={toggleModal}
          userId={user.id}
          region={user.region}
          onNotificationCountChange={setNotificationCount}
        />
      </div>
    );
  };
  export default PastNotifComponent