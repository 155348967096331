import React, { useState, useContext, useEffect } from 'react';
import { auth, db } from "../../firebase";
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { collection, query, where, getDocs, setDoc, addDoc, Timestamp, doc, getDoc } from "firebase/firestore";
import { toast } from 'react-toastify';
import './MyTeam.scss';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import './MyTeamRegister.css';
import { notifyTeamMemberRegistered } from '../toastify/notifySuccess';
import { t } from 'i18next';

const MyTeamRegister = () => {
  const [Name, setName] = useState('');
  const [Surname, setSurname] = useState('');
  const [password, setPassword] = useState('');
  const [Gender, setGender] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [type, setType] = useState('Assistant');
  const [Specilization, setSpecilization] = useState('');
  const [email, setEmail] = useState('');
  const urlParams = new URLSearchParams(window.location.search);
  const registerToken = urlParams.get('registerToken');
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const linkSentTimeStr = searchParams.get("date");
    const linkSentEmail = searchParams.get("email");
    if (!linkSentTimeStr) {
      navigate('/expired');
      return;
    }

    const [day, month, year, hours, minutes] = linkSentTimeStr.split(/[-_]/);
    const linkSentTime = new Date(year, month - 1, day, hours, minutes);
    const expirationTime = linkSentTime.getTime() + 30 * 60 * 1000; // 30 minutes expiration
    const now = new Date().getTime();

    if (now > expirationTime) {
      navigate('/expired');
    }

    setEmail(linkSentEmail);
  }, [navigate]);

  const checkLinkUsage = async (email) => {
    if (email) {
      const linkUsageRef = collection(db, 'linkUsage');
      const q = query(linkUsageRef, where("email", "==", email), where("expired", "==", false));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const latestDoc = querySnapshot.docs[0];
        const expirationTime = latestDoc.data().sentAt.toDate().getTime() + 30 * 60 * 1000;
        const now = new Date().getTime();
        if (now > expirationTime) {
          await setDoc(latestDoc.ref, { expired: true }, { merge: true });
          return false; // Link has expired
        }
        return true; // Link is still valid
      }
    }
    return false;
  };

  useEffect(() => {
    if (email) {
      checkLinkUsage(email).then((isUsed) => {
        if (isUsed) {
          navigate('/expired');
        }
      });
    }
  }, [email, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!currentUser) {
      toast.error(t("noCurrentUserData"));
      return;
    }
  
    try {
      const isLinkUsed = await checkLinkUsage(email);
      if (isLinkUsed) {
        navigate('/expired');
        return;
      }
  
      const doctorRef = doc(db, "doctors", currentUser.uid);
      const docSnap = await getDoc(doctorRef);
  
      if (!docSnap.exists() || docSnap.data().role !== 'doctor') {
        toast.error(t("insufficientPermissions"));
        return;
      }
  
      let clientsArray = docSnap.data().clients || [];
  
      await addDoc(collection(db, 'linkUsage'), {
        email,
        sentAt: Timestamp.now(),
        expired: false
      });
  
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      await updateProfile(user, {
        displayName: `${Name} ${Surname}`
      });
  
      await setDoc(doc(db, "teamMembers", user.uid), {
        Name,
        Surname,
        Gender,
        email,
        Specilization,
        type,
        registerToken:registerToken,
        uid: user.uid,
        doctorId: currentUser.uid,
        clients: clientsArray
      });
  
      notifyTeamMemberRegistered();
      setName('');
      setSurname('');
      setPassword('');
      setGender('');
      setSpecilization('');
    } catch (error) {
      console.error("Error registering team member: ", error);
      toast.error(`${t("errorRegisteringTeamMember")}: ${error.message}`);
    }
  };
  

  const handleLoginRedirect = () => {
    navigate('/login');
  };

  return (
    <div className="login-modal">
      <div className="container">
        <div className="subcont">
          <div className="form-container form-modal p-20 mt-5">
            <div>
              <div className="row">
                <div className="col-12 d-flex">
                </div>
              </div>
            </div>
            <h2 className='d-flex align-items-center justify-content-center'>Register To Realhealth</h2>
            <form className="form-inputs" onSubmit={handleSubmit}>
              <input type="text" value={Name} onChange={e => setName(e.target.value)} placeholder='Name' className="form-input" required />
              <input type="text" value={Surname} onChange={e => setSurname(e.target.value)} placeholder='Surname' className="form-input" required />
              <input type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder='Password' className="form-input" required />
              <input type="specilization" value={Specilization} onChange={e => setSpecilization(e.target.value)} placeholder='Specialization' className="form-input" required />
              <select value={Gender} onChange={e => setGender(e.target.value)} className="form-input" required>
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>

              <button type="submit" className="form-button">Register</button>
              <p className="login-link" onClick={handleLoginRedirect}>Login</p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyTeamRegister;
