// src/components/ProfilePage.jsx

import React, { useContext, useEffect, useState } from "react";
import "./profile.css";
import '../datatable/datatable.scss';
import { AuthContext } from "../../context/AuthContext";
import { DoctorContext } from "../../context/DoctorContext";
import { TeamContext } from "../../context/TeamContext";
import { BusinessContext } from "../../context/BusinessContext"; // Import BusinessContext
import { Button } from "@mui/material";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SidebarContext } from "../../context/SidebarContext";
import NavbarMenu from "../navbar/NavbarMenu";
import defaultAvatar from '../../images/avatar.png';
import { UserContext } from "../../context/UserContext";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";

function ProfilePage() {
  const { t } = useTranslation();
  const { doctors } = useContext(DoctorContext);
  const { teams } = useContext(TeamContext);
  const { businesses } = useContext(BusinessContext); // Access businesses
  const { currentUser } = useContext(AuthContext);
  const { logout: logoutAuthContext } = useContext(AuthContext);
  const { logout: logoutUserContext } = useContext(UserContext);
  const { isSidebarCollapsed } = useContext(SidebarContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [loggedInUserData, setLoggedInUserData] = useState(null);
  const [profileNote, setProfileNote] = useState("");
  const [editableName, setEditableName] = useState("");
  const [editableSurname, setEditableSurname] = useState("");
  const [editableSpecialization, setEditableSpecialization] = useState("");
  const [error, setError] = useState(false);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      logoutAuthContext();
      logoutUserContext();
      navigate("/login");
    } catch (error) {
      console.error("Logout Error:", error);
    }
  };

  useEffect(() => {
    if (currentUser && currentUser.uid) {
      // Search in doctors
      let user = doctors.find(d => d.uid === currentUser.uid);
      // If not found in doctors, search in teams
      if (!user) {
        user = teams.find(t => t.uid === currentUser.uid);
      }
      // If not found in teams, search in businesses
      if (!user) {
        user = businesses.find(b => b.uid === currentUser.uid);
      }

      if (user) {
        setLoggedInUserData(user);
        setProfileNote(user.aboutMe || "");
        setEditableName(user.name || "");
        setEditableSurname(user.surname || "");
        setEditableSpecialization(user.specialization || ""); // Adjusted to lowercase
      } else {
        setLoggedInUserData(null);
      }
      setLoading(false);
    }
  }, [doctors, teams, businesses, currentUser]);

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center vh-100">
        <CircularProgress />
      </div>
    );
  }

  if (!loggedInUserData) {
    return (
      <div>
        <NavbarMenu />
        <div className="container">
          <div className={`profile-container ${isSidebarCollapsed ? "sidebar-collapsed" : "sidebar-open"}`}>
            <div className="row align-items-center">
              <div className="alert alert-warning" role="alert">
                {t("No profile data found.")}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Determine user type
  const isDoctor = loggedInUserData.role === "doctor";
  const isBusiness = loggedInUserData.role === "business";
  const isTeamMember = loggedInUserData.role === "teamMember"; // Assuming role 'teamMember'

  return (
    <div>
      <NavbarMenu />
      <div className="container">
        <div className={`profile-container ${isSidebarCollapsed ? "sidebar-collapsed" : "sidebar-open"}`}>

          <div className="row align-items-center">
            <nav aria-label="breadcrumb">
              <div className="breadcrumb">
                <div className="breadcrumb-item">
                  <a style={{ color: '#677184' }} href="/">{t('Home')}</a>
                </div>
                <div className="breadcrumb-separator"> {'>'}</div>
                <div className="breadcrumb-item active custom-breadcrumb">
                  {t("Profile")}
                </div>
              </div>
            </nav>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px' }}>
              <h1 className="mb-4" style={{ fontSize: '24px', fontWeight: '500', lineHeight: '32px', fontFamily: 'Poppins' }}>
                {t('Profile')}
              </h1>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleLogout}
                className="logout-button"
                style={{ backgroundColor: '#7978DE', color: '#fff' }}
              >
                {t('logout')}
              </Button>
            </div>

            <hr />
            <div className="profile-info">
              <div className="profile-header d-flex align-items-center mb-4">
                <Zoom>
                  <img
                    src={loggedInUserData?.ProfileImage || defaultAvatar}
                    alt="Profile"
                    className="profile-image"
                    style={{ width: '120px', height: '120px', borderRadius: '50%', marginRight: '20px' }}
                  />
                </Zoom>
                <div>
                  <h2 className="profile-name">{loggedInUserData?.Name} {loggedInUserData?.Surname}</h2>
                  {isDoctor && (
                    <h4 className="profile-specialization text-muted">{loggedInUserData?.specialization}</h4>
                  )}
                  
                  {isTeamMember && (
                    <h4 className="profile-specialization text-muted">{loggedInUserData?.teamRole}</h4>
                  )}
                </div>
              </div>
              <div className="profile-section">
                <h3>{t('About me')}</h3>
                <p>{loggedInUserData?.aboutMe}</p>
              </div>
              <div className="profile-section d-flex flex-wrap justify-content-between align-items-start">
                <div className="profile-detail">
                  <h3>{t('Email')}</h3>
                  <p>{loggedInUserData?.email}</p>
                </div>
                {isDoctor && (
                  <>
                    <div className="profile-detail">
                      <h3>{t('gender')}</h3>
                      <p>{loggedInUserData?.Gender || 'No data'}</p>
                    </div>
                   
                  
                  </>
                )}
                {isBusiness && (
                  <>
                    <div className="profile-detail">
                      <h3>{t('Business Name')}</h3>
                      <p>{loggedInUserData?.businessName || 'No data'}</p>
                    </div>
                    <div className="profile-detail">
                      <h3>{t('Business Type')}</h3>
                      <p>{loggedInUserData?.businessType || 'No data'}</p>
                    </div>
                    <div className="profile-detail">
                      <h3>{t('Contact Number')}</h3>
                      <p>{loggedInUserData?.contactNumber || 'No data'}</p>
                    </div>
                  </>
                )}
                {isTeamMember && (
                  <>
                    <div className="profile-detail">
                      <h3>{t('Team Role')}</h3>
                      <p>{loggedInUserData?.teamRole || 'No data'}</p>
                    </div>
                    {/* Add more fields as necessary */}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilePage;
