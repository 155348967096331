import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { confirmPasswordReset, getAuth } from "firebase/auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from '../../images/icons/Content.svg';
import arrow from '../../images/icons/arrow-left.svg';
import "./login.css";


const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const { t } = useTranslation();
  const { oobCode } = useParams(); // Get the reset code from the URL
  const navigate = useNavigate();
  const auth = getAuth();

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmNewPassword) {
      toast.error(t("New password and confirm password do not match"));
      return;
    }

    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
      toast.success(t("Password reset successfully!"));
      navigate("/login"); // Redirect to the login page on success
    } catch (error) {
      toast.error(t("Error resetting password. Please try again."));
      console.error(error);
    }
  };

  return (
    <div className="login">
      <div className="container">
        <div className="subcont">
          <div className="login-header">
            <img src={logo} alt="Realhealth Logo" className="logo-img" />
            <span className="logo-title">Realhealth</span>
          </div>
          <div className="form-container">
            <h2 className="form-title-forget text-center">{t('Reset Your Password')}</h2>
            <form onSubmit={handleResetPassword}>
              <div className="form-group">
                <label htmlFor="new-password">{t('New Password')}</label>
                <input
                  type="password"
                  id="new-password"
                  className="form-control"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="confirm-new-password">{t('Confirm New Password')}</label>
                <input
                  type="password"
                  id="confirm-new-password"
                  className="form-control"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="form-button">{t('Reset Password')}</button>
            </form>
            <div className="button-group-forget mt-4">
              <a className="forget-password" style={{ color: '#677184' }} href="/login">
                <img src={arrow} alt="arrow" width={'20px'} height={'20px'} /> {t("Back to log in")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
