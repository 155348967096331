import { useTranslation } from "react-i18next";
import NavbarMenu from "../navbar/NavbarMenu";
import { SidebarContext } from "../../context/SidebarContext";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { CircularProgress, Zoom } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import defaultAvatar from '../../images/avatar.png';
import { db } from "../../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import pay from '../../images/pay.svg';
import { AuthContext } from "../../context/AuthContext";
import { toast } from "react-toastify";

const Token = () => {
    const { t } = useTranslation();
    const { setIsSidebarCollapsed } = useContext(SidebarContext);
    const { loading } = useContext(UserContext);
    const { userId } = useParams();
    const selectedUserIds = userId.split(',');
    const { clients } = useContext(UserContext);
    const [userData, setUserData] = useState(null);
    const [loadingUserData, setLoadingUserData] = useState(true);
    const [selectedPlan, setSelectedPlan] = useState('Monthly');
    const { currentUser } = useContext(AuthContext);
    const [tokenMonthly, setTokenMonthly] = useState(0);
    const [tokenYearly, setTokenYearly] = useState(0);
    
    const navigate = useNavigate();

    useEffect(() => {
        setIsSidebarCollapsed(true);
    }, [setIsSidebarCollapsed]);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const usersData = [];
                for (const id of selectedUserIds) {
                    const userRef = doc(db, "users", id);
                    const userSnap = await getDoc(userRef);
                    if (userSnap.exists()) {
                        usersData.push({ id, ...userSnap.data() });
                    }
                }
                setUserData(usersData); // Set the state with all selected users' data
            } catch (error) {
                console.error("Error fetching user data:", error);
            } finally {
                setLoadingUserData(false);
            }
        };
        fetchUserData();
    }, [selectedUserIds]);

    useEffect(() => {
        const fetchTokenData = async () => {
            try {
                const doctorRef = doc(db, "doctors", currentUser.uid);
                const doctorSnap = await getDoc(doctorRef);
                if (doctorSnap.exists()) {
                    const data = doctorSnap.data();
                    setTokenMonthly(data.tokenMonthly || 0);
                    setTokenYearly(data.tokenYearly || 0);
                }
            } catch (error) {
                console.error("Error fetching doctor tokens:", error);
            }
        };
        if (currentUser) {
            fetchTokenData();
        }
    }, [currentUser]);

    const handlePlanSelect = (plan) => {
        setSelectedPlan(plan);
    };
    const handleSharePremium = async () => {
        try {
            let newTokenMonthly = tokenMonthly;
            let newTokenYearly = tokenYearly;

            for (const userId of selectedUserIds) {
                const userRef = doc(db, "users", userId);
                const userSnap = await getDoc(userRef);

                let expirationDate = new Date();
                if (userSnap.exists() && userSnap.data().doctorPaymentExpiration) {
                    const currentExpiration = userSnap.data().doctorPaymentExpiration.toDate();
                    expirationDate = currentExpiration > expirationDate ? currentExpiration : expirationDate;
                }

                // Extend the expiration date based on the selected plan
                if (selectedPlan === 'Monthly') {
                    if (newTokenMonthly > 0) {
                        expirationDate.setMonth(expirationDate.getMonth() + 1);
                        newTokenMonthly -= 1; // Deduct one token from the monthly count
                    } else {
                        toast.error("Not enough Monthly Credits to share.");
                        return;
                    }
                } else {
                    if (newTokenYearly > 0) {
                        expirationDate.setFullYear(expirationDate.getFullYear() + 1);
                        newTokenYearly -= 1; // Deduct one token from the yearly count
                    } else {
                        toast.error("Not enough Yearly Credits to share.");
                        return;
                    }
                }

                // Update each user's premium status and expiration
                await updateDoc(userRef, {
                    payment: true,
                    doctorPayment: true,
                    doctorPaymentExpiration: expirationDate,
                });
            }

            // Update the token counts in the doctor's record
            await updateDoc(doc(db, "doctors", currentUser.uid), {
                tokenMonthly: newTokenMonthly,
                tokenYearly: newTokenYearly
            });

            toast.success("Premium successfully shared with selected users!");

            // Update the token state after successful sharing
            setTokenMonthly(newTokenMonthly);
            setTokenYearly(newTokenYearly);
            navigate('/users');
        } catch (error) {
            console.error("Error updating premium status:", error);
            toast.error("An error occurred while upgrading users.");
        }
    };
    

    const handleBuyNowClick = () => {
        if (isSingleUser) {
            navigate(`/Cost/${userId}/${user?.name}`);
        } else {
            navigate(`/Cost/${userId}`);
        }
    };

    const isSingleUser = selectedUserIds.length === 1;
    const user = isSingleUser ? clients.find(client => client.id === selectedUserIds[0]) : null;
    const buttonText = tokenMonthly > 0 || tokenYearly > 0 ? t('Share') : t('Buy Credits');
    const handleButtonClick = buttonText === t('Share') ? handleSharePremium : handleBuyNowClick;

    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <CircularProgress />
            </div>
        );
    }

    if (isSingleUser && !user) {
        return (
            <div>
                <NavbarMenu />
                <div className="error-message" style={{textAlign:'center'}}>
                    {t("User not found.")}
                </div>
            </div>
        );
    }

    return (
        <div>
            <NavbarMenu />
            <div className="payment-container">
                <nav aria-label="breadcrumb">
                    <div className="border-bottom p-3">
                        <div className="breadcrumb">
                            <div className="breadcrumb-item">
                                <a style={{ color: '#677184' }} href="/users">{t('Users')}</a>
                            </div>
                            <div className="breadcrumb-separator"> {'>'}</div>
                            <div className="breadcrumb-item active custom-breadcrumb">
                                {isSingleUser ? user?.name : t('Multiple Users Selected')}
                            </div>
                        </div>
                    </div>
                </nav>

                <div className="row align-items-center mt-4">
                    {isSingleUser && (
                        <div className="col-1">
                            <Zoom>
                                <img
                                    src={user?.profileImageUrl || defaultAvatar}
                                    alt={`${user?.name}'s profile`}
                                    style={{
                                        width: '60px',
                                        height: '60px',
                                        borderRadius: '50%',
                                        marginRight: '10px',
                                        objectFit: 'cover',
                                    }}
                                />
                            </Zoom>
                        </div>
                    )}
                    <div className="col-10">
                        <h1 style={{ fontSize: '24px', fontWeight: '500', lineHeight: '32px', fontFamily: 'Poppins' }}>
                            {isSingleUser ? user?.name : t('Share Credit with Selected Users')}
                        </h1>
                    </div>
                </div>

                <div className="row align-items-start mt-4">
                    <div className="col-lg-7 col-12">
                        <div className="payment-img">
                            <img src={pay} alt="Payment" style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-lg-5 col-12 premium-content p-4">
                        <h3 style={{ fontSize: '40px', fontFamily: 'Poppins', fontWeight: 700, color: '#141517' }}>
                            {isSingleUser ? `Share Credit with ${user?.name}` : t('Share Credit with Selected Users')}
                        </h3>
                        <span style={{ fontFamily: 'Poppins', fontWeight: 600, fontSize: '18px' }}>
                            {tokenMonthly} {t("Monthly Credits left")}
                        </span> <br/>
                        <span style={{ fontFamily: 'Poppins', fontWeight: 600, fontSize: '18px' }}>
                            {tokenYearly} {t("Yearly Credits left")}
                        </span>
                        <p>{t("You can share 1 monthly or yearly credit with your patients to upgrade their plan to premium.")}</p>
                        <p style={{ color: '#000000' }}>Variant</p>
                        <div className="premium-buttons d-flex">
                            <button
                                className={`${selectedPlan === 'Monthly' ? 'selected' : ''} button-padding`}
                                onClick={() => handlePlanSelect('Monthly')}
                            >
                                {t('Monthly')}
                            </button>
                            <button
                                className={`${selectedPlan === 'Yearly' ? 'selected' : ''} button-padding`}
                                onClick={() => handlePlanSelect('Yearly')}
                            >
                                {t('Yearly')}
                            </button>
                        </div>
                        <button
                            className="buy-now-btn w-100 mt-3"
                            style={{
                                backgroundColor: '#000',
                                color: '#fff',
                                border: 'none',
                                padding: '12px 0',
                                borderRadius: '8px'
                            }}
                            onClick={handleButtonClick}
                        >
                            {buttonText}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Token;
