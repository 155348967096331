// src/components/RegisterBusiness.jsx

import React, { useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { setDoc, doc } from "firebase/firestore";
import { auth, storage, db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import { useTranslation } from "react-i18next";
import './register.css'

import { Dropdown } from "react-bootstrap";
import deFlag from "../../images/de.png";
import usFlag from "../../images/eng.svg";
import trFlag from "../../images/turkey.svg";
import logo from '../../images/icons/Content.svg'

function RegisterBusiness() {
  // **State Variables**
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [aboutMe, setAboutMe] = useState("");
  // **Personal Information**
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  
  // **Business Information**
  const [businessName, setBusinessName] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  
  // **Profile Image**
  const [imageUpload, setImageUpload] = useState(null);
  
  // **Language Handling**
  const [currentFlagUrl, setCurrentFlagUrl] = useState(usFlag);
  
  // **Error Handling**
  const [error, setError] = useState(false);
  
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  
  const isFormValid = 
    email !== "" && 
    password !== "" &&
    name.trim() !== "" &&
    surname.trim() !== "" &&
    businessName.trim() !== "" &&
    businessType.trim() !== "" &&
    contactNumber.trim() !== "";

  // **Effect to Initialize Language**
  useEffect(() => {
    const savedLang = localStorage.getItem("appLanguage") || "en";
    i18n.changeLanguage(savedLang);
    setCurrentFlagUrl(getCurrentFlag(savedLang));
  }, [i18n]);

  // **Function to Change Language**
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("appLanguage", lng);
    setCurrentFlagUrl(getCurrentFlag(lng));
  };

  // **Function to Get Current Flag Image**
  const getCurrentFlag = (lang) => {
    switch (lang) {
      case "en":
        return usFlag;
      case "tr":
        return trFlag;
      case "de":
        return deFlag;
      default:
        return usFlag;
    }
  };

  // **Handle Business Registration**
// **Handle Business Registration**
const handleRegisterBusiness = async (e) => {
  e.preventDefault();
  setError(false);

  try {
    // **Create User with Email and Password**
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // **Send Email Verification**
    await sendEmailVerification(user);

    // **Handle Profile Image Upload**
    let downloadURL = "";
    if (imageUpload) {
      const uniqueImageName = `${imageUpload.name}_${v4()}`;
      const imageRef = ref(storage, `BusinessFiles/${uniqueImageName}`);
      const snapshot = await uploadBytes(imageRef, imageUpload);
      downloadURL = await getDownloadURL(snapshot.ref);
    }

    // **Save Business User Data in Firestore under 'doctors' collection**
    await setDoc(doc(db, "doctors", user.uid), {
      uid: user.uid,
      email: email,
      Name: name.trim(),
      Surname: surname.trim(),
      businessName: businessName.trim(),
      businessType: businessType.trim(),
      contactNumber: contactNumber.trim(),
      aboutMe: aboutMe.trim(),
      imageUrl: downloadURL || "", // Empty string if no image uploaded
      clients: [],
      role: "business",  // Set the role to 'business'
      type: "business",  // Set the type to 'business'
    }, { merge: true });

    // **Show Success Notification and Navigate to Login**
    toast.success(t("Registration successful! Please check your email to verify your account."));
    navigate("/login");
  } catch (error) {
    setError(true);
    toast.error(t("Registration failed. Please try again."));
    console.error("Registration Error:", error);
  }
};


  return (
    <div className="register">
      <div className="containerr">
        <div className="register-left">
          <div className="subcont">
            <div className="form-containerr">
              {/* **Language Dropdown** */}
              <Dropdown>
                <Dropdown.Toggle
                  style={{ backgroundColor: "transparent", border: "none" }}
                  variant="success"
                  id="dropdown-basic"
                  className="btn-info"
                >
                  <img
                    src={currentFlagUrl}
                    alt="Current Language"
                    width="40"
                    height="30"
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => changeLanguage("en")}>
                    <img src={usFlag} alt="English" width="40" height="30" />
                    English
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => changeLanguage("tr")}>
                    <img src={trFlag} alt="Türkçe" width="40" height="30" />
                    Türkçe
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => changeLanguage("de")}>
                    <img src={deFlag} alt="Deutsch" width="40" height="30" />
                    Deutsch
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              {/* **Logo Section** */}
              <div className="login-header" style={{ marginBottom: '0px' }}>
                <img src={logo} alt="Realhealth Logo" className="logo-img" />
                <span className="logo-title" style={{ marginBottom: '16px' }}>Realhealth</span>
              </div>

              {/* **Registration Form** */}
              <form className="login-form" style={{ marginTop: '20px' }} onSubmit={handleRegisterBusiness}>
                
                {/* **Personal Information: Name and Surname** */}
                <div className="input-group-register">
                  <label htmlFor="name" className="input-label">{t("First Name")}*</label>
                  <input
                    type="text"
                    className="form-input"
                    id="name"
                    onChange={(e) => setName(e.target.value)}
                    placeholder={t("Enter your first name")}
                    required
                  />
                </div>

                <div className="input-group-register">
                  <label htmlFor="surname" className="input-label">{t("Last Name")}*</label>
                  <input
                    type="text"
                    className="form-input"
                    id="surname"
                    onChange={(e) => setSurname(e.target.value)}
                    placeholder={t("Enter your last name")}
                    required
                  />
                </div>

                {/* **Business Information: Business Name** */}
                <div className="input-group-register">
                  <label htmlFor="businessName" className="input-label">{t("Business Name")}*</label>
                  <input
                    type="text"
                    className="form-input"
                    id="businessName"
                    onChange={(e) => setBusinessName(e.target.value)}
                    placeholder={t("Enter your business name")}
                    required
                  />
                </div>

                {/* **Business Information: Business Type** */}
              

                {/* **Business Information: Contact Number** */}
             

                {/* **Email Field** */}
                <div className="input-group-register">
                  <span className="icon">
                    {/* You can add an email icon here if desired */}
                  </span>
                  <label htmlFor="email" className="input-label">{t("Email")}*</label>
                  <input
                    type="email"
                    id="email"
                    placeholder={t("Enter your email")}
                    className={`form-input ${email ? "filled" : ""}`}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onFocus={(e) => e.target.classList.add('focused')}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        e.target.classList.remove('focused');
                      }
                    }}
                  />
                </div>

                {/* **Password Field** */}
                <div className="input-group-register">
                  <label htmlFor="password" className="input-label">{t("Password")}*</label>
                  <input
                    type="password"
                    id="password"
                    placeholder={t("Enter your password")}
                    className={`form-input ${password ? "filled" : ""}`}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onFocus={(e) => e.target.classList.add('focused')}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        e.target.classList.remove('focused');
                      }
                    }}
                  />
                </div>

                {/* **Profile Image Upload** */}
              

                {/* **Register Button** */}
                <div className="button-group m-2">
                  <button
                    type="submit"
                    className={`form-button ${isFormValid ? "active" : ""}`}
                   
                  >
                    {t('Register')}
                  </button>
                </div>

                {/* **Login Link** */}
                <div className="mt-3">
                  <span className="d-flex align-items-center justify-content-center" style={{fontWeight:'400',fontSize:'14px',color:'#677184'}}>
                    {t("Already have an account?")}
                    <a className="m-2 login-buton" style={{color:'#7978DE',textDecoration:'none'}} href="/login">
                      {t('Login')}
                    </a>
                  </span>
                </div>

                {/* **Error Message** */}
                {error && (
                  <div className="alert alert-danger mt-3" role="alert">
                    {t("Registration failed!")}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
        <div className="register-right"></div>
      </div>
    </div>
  );
}

export default RegisterBusiness;
