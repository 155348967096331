import React, { createContext, useState, useEffect, useContext } from 'react';
import { collection, query,  getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { AuthContext } from './AuthContext';

export const TeamContext = createContext({});

export const TeamProvider = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isUserLoaded, setIsUserLoaded] = useState(false); // Kullanıcının yüklendiğini kontrol et

  useEffect(() => {
    const checkUserLoaded = () => {
      if (currentUser !== undefined) {
        setIsUserLoaded(true);
      }
    };

    checkUserLoaded();
  }, [currentUser]);

  useEffect(() => {
    const fetchTeam = async () => {
      if (!isUserLoaded) return; // Kullanıcı yüklendiğinden emin ol

      if (!currentUser) {
        setTeams([]);
        setLoading(false);
        setError("User not logged in.");
        return;
      }

      setLoading(true);
      try {
        const teamQuery = query(
          collection(db, 'teamMembers')
        );
        const querySnapshot = await getDocs(teamQuery);
        
        const teamList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        
        setTeams(teamList);
        setError(null);
      } catch (err) {
        console.error('Error fetching team members:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTeam();
  }, [isUserLoaded, currentUser]); // currentUser ve isUserLoaded değiştiğinde useEffect'i tetikle

  return (
    <TeamContext.Provider value={{ teams,setTeams, loading, error }}>
      {children}
    </TeamContext.Provider>
  );
};
