import React, { useContext, useEffect, useState } from "react";
import { collection, query, where, getDocs, updateDoc, doc } from "firebase/firestore";
import { db, db2, db3, db4, db5, db6, db7, db8, db9 } from "../../firebase";
import "./pastNotifications.css";
import trashIcon from "../../images/icons/trash.svg";
import { DoctorContext } from "../../context/DoctorContext";
import { TeamContext } from "../../context/TeamContext";
import { AuthContext } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";

const NotificationsModal = ({ userId, region, onNotificationCountChange }) => {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState([]);
  const [isNotificationAllowed, setIsNotificationAllowed] = useState(true);
  const { doctors } = useContext(DoctorContext);
  const { teams } = useContext(TeamContext);
  const { currentUser } = useContext(AuthContext);
  const user = doctors.find((u) => u.id === currentUser.uid) || teams.find((t) => t.id === currentUser.uid);
  const doctor = doctors.find((u) => u.id === currentUser.uid);
  const responsibleDoctor = doctor || doctors.find((d) => d.id === user?.doctorId);

  console.log("currentUser.uid: ", currentUser.uid);
  console.log("responsibleDoctor.id: ", responsibleDoctor?.id);

  useEffect(() => {
    const isBlocked = responsibleDoctor?.notificationOf?.includes(userId);
    setIsNotificationAllowed(!isBlocked);
  }, [responsibleDoctor, userId]);

  // Yardımcı işlev: Doğru Firestore veritabanını seç
  const getSelectedDb = () => {
    switch (region.toLowerCase()) {
      case "amsterdam":
      case "netherlands":
        return db2;
      case "belgium":
        return db3;
      case "finland":
        return db4;
      case "berlin":
        return db5;
      case "london":
      case "united kingdom":
        return db6;
      case "unitedstatesofamerica":
      case "united states of america":
        return db7;
      case "warsaw":
      case "poland":
        return db8;
      case "zurich":
      case "switzerland":
        return db9;
      default:
        return db;
    }
  };

  const fetchNotifications = async () => {
    if (!user || !isNotificationAllowed) {
      return;
    }

    const selectedDb = getSelectedDb();

    // Kullanıcının `userNotifications` alt koleksiyonundan bildirimleri al
    const notificationsRef = collection(selectedDb, "notifications", userId, "userNotifications");

    // Eğer doktor kimliği varsa doktor için sorgula, yoksa iş kullanıcısını kontrol et
    const q = query(
      notificationsRef,
      where("isDeleted", "==", false),
      where("doctorId", "==", responsibleDoctor?.id || currentUser.uid) // İş kullanıcısı için currentUser.uid ile kontrol
    );

    const querySnapshot = await getDocs(q);

    const fetchedNotifications = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    console.log("Fetched notifications: ", fetchedNotifications); // Sorgu sonucunu loglayın

    setNotifications(fetchedNotifications);
    onNotificationCountChange(fetchedNotifications.length);
  };

  // `fetchNotifications` fonksiyonunu bileşen yüklendiğinde çağır
  useEffect(() => {
    fetchNotifications();
  }, [userId, responsibleDoctor?.id, currentUser.uid, isNotificationAllowed, region]);

  const parseTimestamp = (timestamp) => {
    if (timestamp && timestamp.toDate) {
      return timestamp.toDate();
    }
    return new Date(timestamp); // Alternatif olarak string tarih formatı olarak gelirse
  };

  const deleteNotification = async (notificationId) => {
    const selectedDb = getSelectedDb();

    setNotifications((prevNotifications) =>
      prevNotifications.filter((n) => n.id !== notificationId)
    );

    const notificationRef = doc(selectedDb, "notifications", userId, "userNotifications", notificationId);
    try {
      await updateDoc(notificationRef, {
        isDeleted: true,
      });
    } catch (error) {
      console.error("Error marking notification as deleted: ", error);
    }
  };

  if (!isNotificationAllowed) {
    return null;
  }

  return (
    <div className="container-past">
      <h3 className="my-4 Past-notif p-2">{t("Past Notifications")}</h3>
      <div className="row mb-3">
        {/* Filtreleme ve arama alanlarını buraya ekleyebilirsiniz */}
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col" className="content">{t("Content")}</th>
            <th scope="col" className="content">{t("Date")}</th>
            <th scope="col" className="content">{t("Delete")}</th>
          </tr>
        </thead>
        <tbody>
          {notifications.length > 0 ? (
            notifications.map((notification) => (
              <tr key={notification.id}>
                <td>{notification.userMessage}</td>
                <td>
                  {parseTimestamp(notification.timestamp).toLocaleString("tr-TR", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                  })}
                </td>
                <td>
                  <img
                    src={trashIcon}
                    alt="Delete notification"
                    height={20}
                    style={{ cursor: "pointer" }}
                    onClick={() => deleteNotification(notification.id)}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">{t("No notifications")}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default NotificationsModal;
