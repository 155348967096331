import React, { useContext, useEffect, useState, useRef } from "react";
import NavbarMenu from "../navbar/NavbarMenu";
import { SidebarContext } from "../../context/SidebarContext";
import { AuthContext } from "../../context/AuthContext";
import { DoctorContext } from "../../context/DoctorContext";
import { TeamContext } from "../../context/TeamContext";
import { useTranslation } from "react-i18next";
import { doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../firebase";
import { toast } from "react-toastify";
import uploadIcon from '../../images/icons/Featured icon.svg'; // Ensure this path is correct
import './settings.css';
import { CircularProgress, Modal, Button, MenuItem, Select } from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import Password from "./Password";
import { BusinessContext } from "../../context/BusinessContext";

// Move component definitions outside of the Settings component
const SettingsComponent = ({
  name,
  setName,
  surname,
  setSurname,
  title,
  setTitle,
  branch, // Yeni eklenen branch state
  setBranch,
  bio,
  handleBioChange,
  charCount,
  handleSubmit,
  handleCancel,
  isFormChanged,
  handlePhotoChange,
  editedPhoto,
  loggedInUserData,
  dragActive,
  handleDrag,
  handleDrop,
  t,
  setIsFormChanged,
}) => {
  return (
    <div className="settings">
      <div className="row align-items-center">
        <div className="col-12 mt-4">
          <h1 className="mb-4" style={{ fontSize: '24px', fontWeight: '500', lineHeight: '32px', fontFamily: 'Poppins' }}>{t('Settings')}</h1>
          <hr />
          <div className="settings-title">{t("Personal Information")}
            <p className="settings-subtitle">{t("You can update your personal information and profile photo here.")}</p>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="settings-form">
        <div className="row mb-3">
          <div className="col-md-4">
            <label>{t("Name Surname")}*</label>
          </div>
          <div className="col-md-8 d-flex">
            <input
              type="text"
              className="form-control me-2"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setIsFormChanged(true);
              }}
              placeholder={t("First Name")}
              required
            />
            <input
              type="text"
              className="form-control"
              value={surname}
              onChange={(e) => {
                setSurname(e.target.value);
                setIsFormChanged(true);
              }}
              placeholder={t("Last Name")}
              required
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-4">
            <label>{t("Photo")}</label>
          </div>
          <div className="col-md-8">
            <div
              className={`photo-upload ${dragActive ? "drag-active" : ""}`}
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
              onClick={() => document.getElementById('photo-upload-input').click()}
            >
              <div className="photo-preview">
                {editedPhoto ? (
                  <img src={editedPhoto} alt="Profile" />
                ) : loggedInUserData ? (
                  <img src={loggedInUserData.ProfileImage} alt="Profile" />
                ) : (
                  <div className="photo-placeholder" />
                )}
              </div>
              <div className="upload-area">
                <img src={uploadIcon} alt="Upload Icon" className="upload-icon" />
                <p>{t("Drag and Drop.")}<br />PNG or JPG (max. 800×400px)</p>
                <input
                  type="file"
                  className="form-control-file"
                  onChange={handlePhotoChange}
                  accept="image/png, image/jpeg"
                  style={{ display: 'none' }}
                  id="photo-upload-input"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <label>{t("Title")}</label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              className="form-control"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                setIsFormChanged(true);
              }}
            />
          </div>
        
        
          <div className="col-md-4">
            <label>{t("Branch")}</label>
          </div>
          <div className="col-md-8">
            <Select
              value={branch}
              onChange={(e) => {
                setBranch(e.target.value);
                setIsFormChanged(true);
              }}
              displayEmpty
              fullWidth
              variant="outlined"
              className="form-control"
            >
              <MenuItem value="" disabled>
                {t("Select your branch")}
              </MenuItem>
              <MenuItem value="doctor">{t("Doctor")}</MenuItem>
              <MenuItem value="professor">{t("Prof")}</MenuItem>
              <MenuItem value="specialist">{t("Doc")}</MenuItem>
            </Select>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-4">
            <label>Bio</label>
            <p className="text-muted">{t("Tell us a bit about yourself.")}</p>
          </div>
          <div className="col-md-8">
            <textarea
              className="form-control settings-form-textarea"
              value={bio}
              onChange={handleBioChange}
              rows="4"
            />
            <div className="text-center mt-2 text-muted">
              {500 - charCount} {t("character left")}
            </div>
          </div>
        </div>
        <div className="form-actions d-flex justify-content-start">
          <button type="button" className="settings-cancel-btn me-2" onClick={handleCancel} disabled={!isFormChanged}>{t('Cancel')}</button>
          <button type="submit" className="settings-save-btn" disabled={!isFormChanged}>{t('Save')}</button>
        </div>
      </form>
    </div>
  );
};

const PasswordComponent = ({ t }) => {
  return (
    <div className="password">
      <Password />
    </div>
  );
};

const Dashboard = ({
  activeComponent,
  setActiveComponent,
  t,
  name,
  setName,
  surname,
  setSurname,
  title,
  setTitle,
  bio,
  branch, // Yeni eklenen branch state
  setBranch,
  handleBioChange,
  charCount,
  handleSubmit,
  handleCancel,
  isFormChanged,
  handlePhotoChange,
  editedPhoto,
  loggedInUserData,
  dragActive,
  handleDrag,
  handleDrop,
  setIsFormChanged,
}) => {
  const contentRef = useRef(null);

  const handleButtonClick = (componentName) => {
    setActiveComponent(componentName);
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case 'settings':
        return (
          <SettingsComponent
            name={name}
            setName={setName}
            surname={surname}
            setSurname={setSurname}
            title={title}
            setTitle={setTitle}
            bio={bio}
            branch={branch} // Yeni ekleme
            setBranch={setBranch}
            handleBioChange={handleBioChange}
            charCount={charCount}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            isFormChanged={isFormChanged}
            handlePhotoChange={handlePhotoChange}
            editedPhoto={editedPhoto}
            loggedInUserData={loggedInUserData}
            dragActive={dragActive}
            handleDrag={handleDrag}
            handleDrop={handleDrop}
            t={t}
            setIsFormChanged={setIsFormChanged}
          />
        );
      case 'password':
        return <PasswordComponent t={t} />;
      default:
        return null;
    }
  };

  return (
    <div className="dashboard">
      <div className="menu">
        <button
          className={`button-details ${activeComponent === 'settings' ? 'active' : ''}`}
          onClick={() => handleButtonClick('settings')}
        >
          <p className="details-main">{t('Settings')}</p>
        </button>
        <button
          className={`button-details ${activeComponent === 'password' ? 'active' : ''}`}
          onClick={() => handleButtonClick('password')}
        >
          <p className="details-main">{t('Password')}</p>
        </button>
      </div>
      <div className="content" ref={contentRef}>
        {renderComponent()}
      </div>
    </div>
  );
};

const Settings = () => {
  const { t } = useTranslation();
  const { isSidebarCollapsed } = useContext(SidebarContext);
  const { currentUser } = useContext(AuthContext);
  const { doctors } = useContext(DoctorContext);
  const { teams } = useContext(TeamContext);
  const { businesses } = useContext(BusinessContext);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [contactNumber,setContactNumber] = useState("")
  const [title, setTitle] = useState("");
  const [bio, setBio] = useState("");
  const [photo, setPhoto] = useState(null);
  const [editedPhoto, setEditedPhoto] = useState(null);
  const [charCount, setCharCount] = useState(0);
  const [dragActive, setDragActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loggedInUserData, setLoggedInUserData] = useState(null);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const editorRef = useRef(null);
  const [activeComponent, setActiveComponent] = useState('settings');
  const [branch, setBranch] = useState(""); 
  useEffect(() => {
    if (currentUser && currentUser.uid) {
      // Determine user type and fetch data accordingly
      let user = doctors.find(d => d.uid === currentUser.uid) ||
                 teams.find(t => t.uid === currentUser.uid) ||
                 businesses.find(b => b.uid === currentUser.uid);

      if (user) {
        setLoggedInUserData(user);
        if (user.type !== "business") {
          // For Doctors and Team Members
          setName(user.Name || "");
          setSurname(user.Surname || "");
          setTitle(user.Specilization || "");
          setBio(user.aboutMe || "");
          setCharCount(user.aboutMe?.length || 0);
          setBranch(user.doctorBranch || "");
        } else {
          // For Business Users
          setName(user.name || "");
          setSurname(user.surname || ""); // Reusing surname state for contactNumber
          setTitle(user.businessType || "");
          // Bio is not applicable for Business Users; you can remove or repurpose it
        }
      }
      setLoading(false);
    }
  }, [currentUser, doctors, teams, businesses]);

  const handlePhotoChange = (e) => {
    if (e.target.files[0]) {
      setPhoto(e.target.files[0]);
      setOpenModal(true);
    }
  };

  const handleSavePhoto = () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas().toDataURL();
      setEditedPhoto(canvas);
      setPhoto(null);
      setIsFormChanged(true);
      setOpenModal(false);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setPhoto(e.dataTransfer.files[0]);
      setOpenModal(true);
    }
  };

  const handleBioChange = (e) => {
    const newBio = e.target.value;
    if (newBio.length <= 500) {
      setBio(newBio);
      setCharCount(newBio.length);
      setIsFormChanged(true);
    }
  };

  const uploadFileToStorage = async (file) => {
    const userRole = doctors.some(d => d.id === currentUser.uid) ? 'doctors' : 'teamMembers';
    const storageRef = ref(storage, `${userRole}ProfileImage/${currentUser.uid}.png`);

    try {
      await uploadBytes(storageRef, file);
      return await getDownloadURL(storageRef);
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error(t('uploadFailed'));
    }
  };

  const updateProfileImage = async (userId, imageUrl) => {
    let userRole = "";
    if (loggedInUserData.type === "doctor") {
      userRole = 'doctors';
    } else if (loggedInUserData.type === "team") {
      userRole = 'teamMembers';
    } else if (loggedInUserData.type === "business") {
      userRole = 'business';
    } else {
      userRole = 'users';
    }
    const userRef = doc(db, userRole, userId);

    try {
      await updateDoc(userRef, {
        ProfileImage: imageUrl,
      });
      toast.success(t('Profile image updated'));
    } catch (error) {
      console.error('Error updating profile image:', error);
      toast.error(t('Profile image update failed'));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation for required fields based on user type
    if (loggedInUserData.type !== "business") {
      if (!name.trim() || !surname.trim() || !title.trim()) {
        toast.error(t('Fields cannot be empty'));
        return;
      }
    } else {
      if (!name.trim() || !surname.trim() || !title.trim()) { // For business: name (businessName), surname (contactNumber), title (businessType)
        toast.error(t('Fields cannot be empty'));
        return;
      }
    }

    setLoading(true);

    try {
      if (editedPhoto) {
        // Convert base64 to blob
        const response = await fetch(editedPhoto);
        const blob = await response.blob();
        const imageUrl = await uploadFileToStorage(blob);
        await updateProfileImage(currentUser.uid, imageUrl);
      }

      let userRole = "";
      if (loggedInUserData.type === "doctor") {
        userRole = 'doctors';
      } else if (loggedInUserData.type === "team") {
        userRole = 'teamMembers';
      } else if (loggedInUserData.type === "business") {
        userRole = 'business';
      } else {
        userRole = 'users';
      }

      const userRef = doc(db, userRole, currentUser.uid);

      // Prepare update data based on user type
      let updateData = {};

      if (loggedInUserData.type !== "business") {
        // For Doctors and Team Members
        updateData = {
          Name: name.trim(),
          Surname: surname.trim(),
          Specilization: title.trim(),
          aboutMe: bio,
          doctorBranch: branch.trim(),
        };
      } else {
        // For Business Users
        updateData = {
          businessName: name.trim(),
          contactNumber: contactNumber.trim(), // Reusing surname state for contactNumber
          businessType: title.trim(),
          // bio is not applicable; if needed, you can add business-specific fields
        };
      }

      // Update Firestore document
      await updateDoc(userRef, updateData);

      toast.success(t('Profile updated successfully'));
      setIsFormChanged(false);
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error(t('Profile update failed'));
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setName(loggedInUserData.Name || '');
    setSurname(loggedInUserData.Surname || '');
    setTitle(loggedInUserData.Specialization || '');
    setBio(loggedInUserData.aboutMe || '');
    setPhoto(null);
    setEditedPhoto(null);
    setCharCount(loggedInUserData.aboutMe?.length || 0);
    setIsFormChanged(false);
  };

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center vh-100">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <NavbarMenu />
      <div className="container">
        <div className="datatable" style={{ paddingLeft: isSidebarCollapsed ? '100px' : '300px' }}>
          <Dashboard
            activeComponent={activeComponent}
            setActiveComponent={setActiveComponent}
            t={t}
            name={name}
            setName={setName}
            surname={surname}
            setSurname={setSurname}
            title={title}
            setTitle={setTitle}
            bio={bio}
            handleBioChange={handleBioChange}
            charCount={charCount}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            isFormChanged={isFormChanged}
            handlePhotoChange={handlePhotoChange}
            editedPhoto={editedPhoto}
            loggedInUserData={loggedInUserData}
            dragActive={dragActive}
            handleDrag={handleDrag}
            handleDrop={handleDrop}
            setIsFormChanged={setIsFormChanged}
            branch={branch} // Yeni ekleme: branch prop'u
            setBranch={setBranch}   
          />
        </div>
      </div>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-content">
          <AvatarEditor
            ref={editorRef}
            image={photo}
            width={250}
            height={250}
            border={50}
            color={[255, 255, 255, 0.6]} // RGBA
            scale={1.2}
            rotate={0}
          />
          <div className="d-flex justify-content-between mt-3 gap-2">
            <Button onClick={() => setOpenModal(false)} variant="contained" color="primary">
              {t('Cancel')}
            </Button>
            <Button onClick={handleSavePhoto} variant="contained" color="primary">
              {t('Save')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Settings;
