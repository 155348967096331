import React, { useContext, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import pay from '../../images/pay.svg';
import './Cost.css';
import 'react-toastify/dist/ReactToastify.css';
import { t } from 'i18next';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import NavbarMenu from '../navbar/NavbarMenu';
import { CircularProgress } from '@mui/material';
import { UserContext } from '../../context/UserContext';
import { SidebarContext } from '../../context/SidebarContext';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { AuthContext } from '../../context/AuthContext';
const stripePromise = loadStripe('pk_live_51QECqdG6LBnU5s9ShtNmlMUfa70KN5dYEoXagL2iJjwKh9NYhkRdqxdHffjggitwUEKSSYCTFy935iNQbVJa9avr00GAOew2se');
const Cost = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { clientId, userName } = useParams();
    const { setIsSidebarCollapsed } = useContext(SidebarContext);
    const { loading } = useContext(UserContext);
    const { currentUser } = useContext(AuthContext);
    const [selectedPlan, setSelectedPlan] = useState('Monthly');
    const [pricingDialogOpen, setPricingDialogOpen] = useState(false);
    const [sliderValue, setSliderValue] = useState(20); // Başlangıç değeri olarak 20

    useEffect(() => {
        setIsSidebarCollapsed(true);
    }, [setIsSidebarCollapsed]);
    
    const handleOpenPricingDialog = () => {
        setPricingDialogOpen(true);
    };
    
    const handleClosePricingDialog = () => {
        setPricingDialogOpen(false);
    };
    
    const handlePlanSelect = (plan) => {
        setSelectedPlan(plan);
    };
    
    const handleSliderChange = (event) => {
        setSliderValue(Number(event.target.value));
    };
    
    // const handleUpgradeClick = () => {
    //     const amount = calculatePrice(sliderValue);
    //     navigate(`/premium/${clientId}/${userName}?plan=${selectedPlan}&amount=${amount}&patients=${sliderValue}`);
    // };
 
    const handleUpgradeClick = async () => {
        const amount = calculatePrice(sliderValue);
        const stripe = await stripePromise;

        try {
            const userEmail = currentUser.email; 
   
            // Sunucuya Checkout oturumu oluşturmak için istek gönderiyoruz
            const response = await fetch('https://us-central1-realworks-health.cloudfunctions.net/createCheckoutSession', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    plan: selectedPlan,
                    amount: amount,
                    patients: sliderValue,
                    clientId,
                    userEmail,
                }),
            });
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Sunucu hatası: ${response.status} - ${errorText}`);
            }
            const session = await response.json();
            console.log('Session:', session);
            console.log('Session ID:', session.id);
            // Stripe Checkout sayfasına yönlendiriyoruz
            const result = await stripe.redirectToCheckout({
                sessionId: session.id,
            });

            if (result.error) {
                // Hata durumunda kullanıcıya bir mesaj gösterilebilir
                console.error('Stripe yönlendirme hatası:', result.error.message);
            }
        } catch (error) {
            console.error('Checkout oturumu oluşturulurken hata oluştu:', error);
        }
    };

    // Ücret hesaplaması
// Ücret hesaplaması
const calculatePrice = (users) => {
    const pricePerUser = selectedPlan === 'Monthly' ? 1 : 89.99;
    if (users === 1) {
        return pricePerUser; // İlk kullanıcı için tam fiyat
    }
    return Math.floor(users * pricePerUser); // İlerleyen kullanıcılar için geriye yuvarlanmış fiyat
};


    
    return (
        <div>
            <NavbarMenu />
            {loading ? (
                <div className="d-flex align-items-start justify-content-start vh-100">
                    <CircularProgress />
                </div>
            ) : (
                <div className="payment-container my-4">
                     <div className="border-bottom">
          <h1 className="payment-title">{t('Payment')}</h1>
        </div>
                    <div className="row g-0" style={{ marginTop: '20px' }}>
                        <div className="col-lg-7 col-12 mb-4 p-0">
                            <div className="payment-img">
                                <img src={pay} alt="Payment" />
                            </div>
                        </div>
                        <div className="col-lg-5 col-12 premium-content">
                            <h3 style={{ fontSize: '30px', fontFamily: 'Poppins', fontWeight: 600, color: '#141517' }}>
                               {t("Calculate the Cost of Your Plan")}
                            </h3>
                            <p>{t("Premium plans are billed monthly according to the size of your user list")}</p>
                            <p style={{ color: '#000000' }}>Variant</p>
                            <div className="premium-buttons d-flex">
                                <button
                                    className={`${selectedPlan === 'Monthly' ? 'selected' : ''} button-padding`}
                                    onClick={() => handlePlanSelect('Monthly')}
                                >
                                    {t('Monthly')}
                                </button>
                                <button
                                    className={`${selectedPlan === 'Yearly' ? 'selected' : ''} button-padding`}
                                    onClick={() => handlePlanSelect('Yearly')}
                                >
                                    {t('Yearly')}
                                </button>
                            </div>

                            {/* Bilgi Kutuları */}
                            <div className="info-boxes d-flex justify-content-between mt-4">
                                <div className="info-box" style={{ flex: '1', border: '1px solid #D9D9D9', borderRadius: '8px', padding: '20px', textAlign: 'center', marginRight: '10px' }}>
                                    <p style={{ color: '#8F90A6' }}>{t("Premium User Number")}</p>
                                    <h2 style={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: '48px' }}>{sliderValue} <span style={{ fontSize: '24px', fontFamily: 'Poppins', fontWeight: 500 }}>{t("Users")}</span></h2>
                                </div>
                                <div className="info-box" style={{ flex: '1', border: '1px solid #D9D9D9', borderRadius: '8px', padding: '20px', textAlign: 'center', marginLeft: '10px' }}>
                                    <p style={{ color: '#8F90A6' }}>{t("Price")}</p>
                                    <h2 style={{ fontSize: '48px', fontFamily: 'Poppins', fontWeight: 500 }}>{calculatePrice(sliderValue)}<span style={{ fontSize: '24px', fontFamily: 'Poppins', fontWeight: 500 }}>$</span></h2>
                                </div>
                            </div>

                            {/* Kaydırıcı */}
                            <div className="slider-container my-4">
                                <input 
                                    type="range" 
                                    min="1" 
                                    max="100" 
                                    value={sliderValue} 
                                    onChange={handleSliderChange} 
                                    className="slider" 
                                    style={{ width: '100%', accentColor: '#7D61FF', borderColor: '#D9D9D9' }} 
                                />
                                <div className="d-flex justify-content-between mt-2">
                                    <span>1</span>
                                    <span>50</span>
                                    <span>100</span>
                                </div>
                            </div>
                            {/* <p 
        className="text-start" 
        style={{ color: '#8F90A6', cursor: 'pointer' }} 
        onClick={handleOpenPricingDialog}
      >
        View all pricing plans
      </p> */}
            <Dialog
        open={pricingDialogOpen}
        onClose={handleClosePricingDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <IconButton 
            aria-label="close" 
            onClick={handleClosePricingDialog}
            sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <table style={{ width: '100%', textAlign: 'left', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ borderBottom: '1px solid #D9D9D9', padding: '8px' }}>{t("Number of Users")}</th>
                <th style={{ borderBottom: '1px solid #D9D9D9', padding: '8px' }}>{t("Monthly Price")}</th>
                <th style={{ borderBottom: '1px solid #D9D9D9', padding: '8px' }}>{t("Yearly Price")}</th>
              </tr>
            </thead>
            <tbody>
              {[...Array(10)].map((_, index) => (
                <tr key={index}>
                  <td style={{ borderBottom: '1px solid #D9D9D9', padding: '8px' }}>1-10</td>
                  <td style={{ borderBottom: '1px solid #D9D9D9', padding: '8px' }}>20$</td>
                  <td style={{ borderBottom: '1px solid #D9D9D9', padding: '8px' }}>100$</td>
                </tr>
              ))}
            </tbody>
          </table>
        </DialogContent>
      </Dialog>
                            <button
                                className="buy-now-btn w-100 mt-3"
                                style={{
                                    backgroundColor: '#000',
                                    color: '#fff',
                                    border: 'none',
                                    padding: '12px 0',
                                    borderRadius: '8px'
                                }}
                                onClick={handleUpgradeClick}
                            >{t('buy Now')}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Cost;
