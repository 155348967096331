// healthDataCategories.js
export const healthDataCategories = {
    'General Health Metrics': [
      { label: 'Health Score', value: 'healthScore', unit: '' },
    ],
    'Exercise and Activity': [
      { label: 'Exercise Time', value: 'exerciseTime', unit: 'minutes' },
      { label: 'Steps', value: 'steps', unit: 'steps' },
      { label: 'Walking Distance', value: 'walkingDistance', unit: 'km' },
      { label: 'Flights Climbed', value: 'flightsclimbed', unit: 'flights' },
      { label: 'Stand Hours', value: 'standHours', unit: 'hours' },
      { label: 'Activity Energy', value: 'activityenergy', unit: 'kcal' },
    ],
    'Heart and Respiratory Metrics': [
      { label: 'Heart Rate', value: 'heartRate', unit: 'bpm' },
      { label: 'Resting Heart Rate', value: 'restingHeartRate', unit: 'bpm' },
      { label: 'Walking Heart Rate', value: 'walkingHeartRate', unit: 'bpm' },
      { label: 'Heart Rate Variability', value: 'heartRateVariability', unit: 'ms' },
      { label: 'Respiratory Rate', value: 'respiratoryRate', unit: 'breaths/min' },
      { label: 'Oxygen Saturation', value: 'oxygenSaturation', unit: '%' },
    ],
    'Body Composition': [
     
      { label: 'Body Mass Index (BMI)', value: 'bmi', unit: 'kg/m²' },
      { label: 'Lean Body Mass', value: 'leanBodyMass', unit: 'kg' },
    ],
    'Metabolic Metrics': [
      
      { label: 'Blood Glucose', value: 'bloodGlucose', unit: 'mg/dL' },
      { label: 'Blood Pressure', value: 'bloodPressure', unit: 'mmHg' },
      { label: 'Cardio Recovery', value: 'cardioRecovery', unit: 'bpm' },
      { label: 'Vo2Max', value: 'vo2Max', unit: 'mL/kg/min' },
    ],
  };
  