// DoctorContext.js
import React, { createContext, useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

export const DoctorContext = createContext({});

export const DoctorProvider = ({ children }) => {
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDoctors = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, 'doctors'));
        const doctorList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setDoctors(doctorList);
      } catch (err) {
        console.error('Error fetching doctors:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    
    fetchDoctors();
  }, []);

  return (
    <DoctorContext.Provider value={{ doctors, loading, error }}>
      {children}
    </DoctorContext.Provider>
  );
};