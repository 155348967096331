import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase";
import { useTranslation } from "react-i18next";
import logo from '../../images/icons/Content.svg';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./login.css";

import arrow from '../../images/icons/arrow-left.svg'
function CheckEmail() {
  // eslint-disable-next-line no-unused-vars
  const [email, setEmail] = useState("");
  const { t } = useTranslation();
// eslint-disable-next-line no-unused-vars
  const handleForgetPassword = async (e) => {
    e.preventDefault();

    try {
      await sendPasswordResetEmail(auth, email);
      toast.success(t('resetLinkSent'));
    } catch (error) {
      toast.error(t('resetLinkError'));
      console.error(error);
    }
  };

  return (
    <div className="login">
      <div className="container">
        <div className="subcont">
          <div className="login-header">
            <img src={logo} alt="Realhealth Logo" className="logo-img" />
            <span className="logo-title">Realhealth</span>
          </div>
          <div className="form-container">
            <h2 className="form-title-forget text-center">{t('Check your email')}</h2>
            <p className="form-description-forget text-center" style={{color:'#677184'}}>{t('We sent a password reset link to your email')}</p>
            <div className="button-group-forget">
           
             
              <a className="forget-password" style={{color:'#677184'}} href="/login">
                  <img src = {arrow} alt="arrow"width={'20px'} height={'20px'}/> {t("Back to log in")}
                </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckEmail;
