import React, { useState, useEffect } from 'react';
import { getDoc, doc } from 'firebase/firestore';
import { db, db2, db3, db4, db5, db6, db7, db8, db9 } from '../firebase'; // Import all Firestore instances
import { useTranslation } from 'react-i18next';
import avatar from '../images/avatar.png';
import { Timestamp } from 'firebase/firestore';

const formatTimestamp = (timestamp) => {
  if (timestamp instanceof Timestamp) {
    const date = timestamp.toDate();
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  }
  return "Invalid Date";
};

const CommentList = ({ userId, bloodTestId, region, doctorId }) => { // doctorId prop'u ekledik
  const { t } = useTranslation();
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        // Kullanıcının bölgesine göre doğru Firestore örneğini belirleyin
        let selectedDb;
        switch (region) {
          case 'Netherlands':
            selectedDb = db2;
            break;
          case 'Belgium':
            selectedDb = db3;
            break;
          case 'Germany':
            selectedDb = db4;
            break;
          case 'Finland':
            selectedDb = db5;
            break;
          case 'United Kingdom':
            selectedDb = db6;
            break;
          case 'United States of America':
            selectedDb = db7;
            break;
          case 'Poland':
            selectedDb = db8;
            break;
          case 'Switzerland':
            selectedDb = db9;
            break;
          default:
            selectedDb = db; // Varsayılan Firestore instance
        }

        const userDocRef = doc(selectedDb, 'users', userId);
        const docSnapshot = await getDoc(userDocRef);
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          const bloodData = userData.ArtificialIntelligenceBloodData || {};
          const testComments = bloodData[bloodTestId]?.comments || [];

          // Yorumları atan doktorun kimliğine göre filtreleyin
          const filteredComments = testComments.filter(comment => comment.doctorID === doctorId);

          setComments(filteredComments);
        } else {
          console.log(`User document does not exist in region: ${region}, Firestore instance: ${selectedDb.app.options.projectId}`);
        }
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };

    if (userId && bloodTestId) {
      fetchComments();
    }
  }, [userId, bloodTestId, region, doctorId]); // doctorId ve region'ı dependency array'e ekledik

  if (comments.length === 0) {
    return <p>{t('No comments available')}</p>;
  }

  return (
    <div className="comments-section">
      <h4>{t("Last Comments")}</h4>
      {comments.map((comment, index) => (
        <div key={index} className="comment-item list-group-item">
          <div className="comment-header">
            <img src={avatar} alt="Doctor" className="doctor-image" />
            <div className="comment-details">
              <strong>{comment.notificationTitle}</strong>
              <span className="comment-timestamp">{formatTimestamp(comment.timestamp)}</span>
            </div>
          </div>
          <p className="comment-text">{comment.userMessage}</p>
        </div>
      ))}
    </div>
  );
};

export default CommentList;
