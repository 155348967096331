import React, { useContext, useEffect, useState } from "react";
import {
  CircularProgress,
  createTheme,
  ThemeProvider,
  Modal,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { TeamContext } from "../../context/TeamContext";
import { AuthContext } from "../../context/AuthContext";
import { db, auth } from "../../firebase";
import {
  doc,
  deleteDoc,
  
} from "firebase/firestore";
import { toast } from "react-toastify";
import NavbarMenu from "../navbar/NavbarMenu";
import "./MyTeam.scss";
import { sendSignInLinkToEmail } from "firebase/auth";
import avatar from "../../images/avatar.png";
import Zoom from "react-medium-image-zoom";
import { DoctorContext } from "../../context/DoctorContext";
import userplus from "../../images/icons/user-plus-01.png";
import { UserContext } from "../../context/UserContext";
import searchicon from "../../images/icons/search-lg.svg";
import { notifyVerificationLinkSent } from "../toastify/notifySuccess";
import { SidebarContext } from "../../context/SidebarContext";
import {useMediaQuery} from "@mui/material";
const MyTeam = () => {

  const { clients } = useContext(UserContext);
  const { teams, setTeams, loading } = useContext(TeamContext);
  const { currentUser } = useContext(AuthContext);
  const { doctors } = useContext(DoctorContext);
  const [search, setSearch] = useState("");
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [addMemberLoading, setAddMemberLoading] = useState(false);
  const [sortModel, setSortModel] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [userTeam, setUserTeam] = useState([]);
  const [filteredUserTeam, setFilteredUserTeam] = useState([]);
  const { isSidebarCollapsed } = useContext(SidebarContext);
  const doctor = doctors.find((u) => u.id === currentUser.uid) || {};
  const isDoctor = doctor.role === "doctor";


  const formatDateForURL = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${day}-${month}-${year}_${hours}-${minutes}`;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    setAddMemberLoading(true);
    

    const userExists = teams.some((user) => user.email === email);
    setAddMemberLoading(false);
    if (userExists) {
      toast.error(t("userExists"));
      return;
    }

    if (!currentUser) {
      toast.error(t("requiresAdminPermission"));
      return;
    }

    try {
      const formattedDate = formatDateForURL();
      const actionCodeSettings = {
        url: `https://pro.realhealth.solutions/MyTeamRegister?date=${formattedDate}&email=${email}`,
        handleCodeInApp: true,
        template: "Email address verification",
      };

      await sendSignInLinkToEmail(auth, email, actionCodeSettings);

      notifyVerificationLinkSent("Verification link sent to team member's email!");
      setEmail("");
      closeModal();
    } catch (error) {
      console.error("Error sending verification link: ", error);
      toast.error(`${t("errorSendingVerificationLink")}: ${error.message}`);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearch(value);

    const filtered = userTeam.filter(
      (user) =>
        user.Name.toLowerCase().includes(value) ||
        user.Surname.toLowerCase().includes(value) ||
        user.email.toLowerCase().includes(value)
    );
    setFilteredUserTeam(filtered);
  };
  
  const isMediumScreen = useMediaQuery('(max-width:768px)');
  const columnVisibilityModel = {
       type : !isMediumScreen,
       Gender:!isMediumScreen
  };
  const handleRemove = async (uid) => {
    if (!currentUser) {
      toast.error(t("adminPrivilegesRequired"));
      return;
    }

    try {
      await deleteDoc(doc(db, "teamMembers", uid));

      const updatedTeams = teams.filter((team) => team.uid !== uid);
      setTeams(updatedTeams);
      toast.success(t("teamMemberRemoved"));
    } catch (error) {
      console.error("Failed to remove team member: ", error);
      toast.error(`${t("errorRemovingTeamMember")}: ${error.message}`);
    }
  };

  useEffect(() => {
    let userTeams;

    const doctor = doctors.find((u) => u.id === currentUser.uid) || {};
    if (doctor.role === "doctor") {
      userTeams = teams.filter((u) => u.doctorId === doctor.id);
    } else {
      const user = teams.find((u) => u.id === currentUser.uid);
      if (user && user.doctorId) {
        userTeams = teams.filter((u) => u.doctorId === user.doctorId);
      } else {
        userTeams = [];
      }
    }

    setUserTeam(userTeams);
    setFilteredUserTeam(userTeams);
  }, [teams, currentUser, doctors]);


  
  
  const columns = [
    {
      field: "fullName",
      key: "fullName",
      headerName: t("Member Name"),
      flex: 1,
      sortable: true,
      valueGetter: (params) =>
        `${params.row.Name || ""} ${params.row.Surname || ""}`,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            <Zoom>
              <img
                onClick={(event) => {
                  event.stopPropagation();
                }}
                className="cellImg object-fit-cover"
                src={params.row.ProfileImage ? params.row.ProfileImage : avatar}
                alt="avatar"
              />
            </Zoom>
            <div className="user-info">
              {params.row.Name + " " + params.row.Surname}
              <div className="user-email">{params.row.email}</div>
            </div>
          </div>
        );
      },
    },
    {
      field: "Gender",
      id: "Gender",
      headerName: t("gender"),
      flex: 1,
      valueFormatter: (params) => t(`Gender.${params.value}`),
    },
    {
      field: "type",
      id: "type",
      headerName: t("type"),
      flex: 1,
      valueFormatter: (params) => t(`Type.${params.value}`),
    },
  ];

  if (isDoctor) {
    columns.push({
      field: "remove",
      id: "remove",
      headerName: t("Remove Member"),
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <button
          style={{
            color: "#fff",
            backgroundColor: "#7978DE",
            borderColor: "#7978DE",
            width: "90px",
            height: "40px",
            borderRadius: "5px",
            padding: "10px 14px",
            borderWidth: "1px",
            borderStyle: "solid",
          }}
          onClick={() => handleRemove(params.row.uid)}
        >
          {t("Remove")}
        </button>
      ),
    });
  }

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const Theme = createTheme({
    palette: { mode: "light" },
  });

  return (
    <ThemeProvider theme={Theme}>
      <NavbarMenu />
      {loading ? (
        <div className="d-flex align-items-center justify-content-center vh-100">
          <CircularProgress />
        </div>
      ) : (
        <div className="container">
          <div className={`datatable ${isSidebarCollapsed ? "sidebar-collapsed" : "sidebar-open"}`}>
            <div className="row align-items-center pt-3">
              <div className="col-sm-3"></div>
              <div className="patient-container">
                <div>
                  <h1 className="d-h1">{t("My Team")}</h1>
                  <p style={{ color: "#677184" }}>
                   {t("View your team's details")}
                  </p>
                </div>

                {isDoctor && (
                  <div>
                    <button className="add-member-btn" onClick={openModal}>
                      <img
                        src={userplus}
                        alt="User Icon"
                        style={{ width: "20px", height: "20px", marginRight: "10px" }}
                      />
                      {t("Add Member")}
                    </button>
                   
                  </div>
                )}
              </div>
              {clients.length > 0 && (
                <div className="col-12 col-sm-3 justify-content-end">
                  <div className="search-bar-container position-relative" style={{ border: '1px solid #ccc', borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
                    <img
                      src={searchicon}
                      alt="Search Icon"
                      style={{ marginLeft: '10px' }}
                    />
                    <input
                      type="text"
                      className="form-control"
                      id="searchInput"
                      placeholder={t("searchpatients")}
                      value={search}
                      onChange={handleSearch}
                      style={{ border: 'none', paddingBottom: '5px', flex: '1' }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-12 text-start m-2 mb-3">
                <div className="pt-4 pe-2">
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontWeight: 600,
                      lineHeight: "28px",
                      textAlign: "left",
                      color: "#141517",
                    }}
                  >
                    {t("Total Members")}: {filteredUserTeam.length}
                  </span>
                </div>
              </div>
            </div>
            <DataGrid
              className="datagrid"
              rows={filteredUserTeam.map((row) => ({ ...row, id: row.uid }))}
              columns={columns}
              pageSize={9}
              rowsPerPageOptions={[9]}
              sortModel={sortModel}
              onSortModelChange={(model) => setSortModel(model)}
              autoHeight
              disableSelectionOnClick
              columnVisibilityModel={columnVisibilityModel}
              components={{
                NoRowsOverlay: () => (
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    fontSize: '18px',
                    color: '#141517',
                    fontFamily: 'Poppins',
                  }}>
                    {t("No Team Members")}
                  </div>
                ),
              }}
            />
          </div>
        </div>
      )}
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="login-modal">
          <div className="container">
            <div className="subcont">
              <div className="form-container form-modal p-20 mt-5">
                <div>
                  <div className="row">
                    <div className="col-12 d-flex">
                      <button
                        className="ms-auto close-btn text-white butonuna"
                        onClick={closeModal}
                      ></button>
                    </div>
                  </div>
                </div>
                <h2 className="d-flex align-items-center justify-content-center">
                  {t("Register a Member")}
                </h2>
                <form className="form-inputs" onSubmit={handleSubmit}>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={t("E-mail")}
                    className="form-input"
                    required
                  />
                  <button type="submit" style={{ backgroundColor: '#141517' }} className="form-button">
                    {t("Register a team member")}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
     
    </ThemeProvider>
  );
};

export default MyTeam;
