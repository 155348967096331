import React, { useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Rectangle, Customized } from 'recharts';
import { useTranslation } from 'react-i18next';
const TwoCharts = ({ data, unit, type, dataKey, maxValueKey }) => {
  const [selectedTimeFrame, setSelectedTimeFrame] = useState('daily');
  const { t } = useTranslation();
  // Verilen zaman dilimine göre veriyi filtreleyin
  const filteredData = data[selectedTimeFrame] || [];

  // Zaman diliminde veri olup olmadığını kontrol edin
  const hasData = filteredData.length > 0;

  // Zaman dilimine göre dataKey'i ayarlayın
  let effectiveDataKey;

  // Zaman dilimine göre dataKey'i ayarlayın
  if (selectedTimeFrame === 'daily') {
    effectiveDataKey = 'dayTime';
  } else if (selectedTimeFrame === 'yearly' || selectedTimeFrame === 'sixMonths') {
    effectiveDataKey = 'monthYear';
  } else {
    effectiveDataKey = 'dayMonth';
  }
  // Zaman dilimi seçim butonlarını oluştur
  const renderTimeFrameButtons = () =>
  ["daily", "weekly", "monthly", "sixMonths", "yearly"].map((frame) => (
    <Button
      key={frame}
      style={{
        backgroundColor: selectedTimeFrame === frame ? "#7978DE" : "#f8f9fa",
        borderColor: "none",
        color: selectedTimeFrame === frame ? "#fff" : "#000",
        border: "none"
      }}
      className="timeframe-button"
      onClick={() => setSelectedTimeFrame(frame)}
    >
      {t(frame.charAt(0).toUpperCase() + frame.slice(1))}
    </Button>
  ));

  const customTooltipFormatter = (value, name) => {
    if (type === "Oxygen Saturation") {
      return [`${t(type)}: ${t(unit)}  ${t(value)}  `];
    }

    else if (type === "Blood Pressure") {
      const label = `${t(name)}: ${t(value)}`;
      return [`${label}` + (unit ? `${t(unit)} ` : '')];
    }

    else {
      return [`${t(name)}: ${t(value)} ` + (unit ? ` ${t(unit)} ` : '') + '  '];
    }
  };

  const CustomizedRectangle = (props) => {
    const { formattedGraphicalItems } = props;
    const firstSeries = formattedGraphicalItems[0];
    const secondSeries = formattedGraphicalItems[1];

    return firstSeries?.props?.points.map((firstSeriesPoint, index) => {
      const secondSeriesPoint = secondSeries?.props?.points[index];
      const yDifference = firstSeriesPoint.y - secondSeriesPoint.y;

      // Unutulan anahtar (key) eklemesi
      const uniqueKey = `${firstSeriesPoint.payload.dayMonth}-${index}`;

      return (
        <Rectangle
          key={uniqueKey} // Benzersiz anahtar ekleniyor
          width={10}
          height={Math.abs(yDifference)}
          x={secondSeriesPoint.x - 5}
          y={Math.min(firstSeriesPoint.y, secondSeriesPoint.y)}
          fill={yDifference > 0 ? 'red' : yDifference < 0 ? 'green' : 'none'}
        />
      );
    });
  };

  if(type === "Blood Pressure") {
    return (
      <div className='text-center'>
        <ButtonGroup className='py-2 time-category'>
          {renderTimeFrameButtons()}
        </ButtonGroup>
        {hasData ? (
          <ResponsiveContainer width="95%">
            <LineChart data={filteredData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={effectiveDataKey} {...(effectiveDataKey !== "dayTime" ? { angle: -45, textAnchor: "end" } : {})} height={100} padding={{ left: 8, right: 8 }} />
              <YAxis />
              <Tooltip formatter={customTooltipFormatter} />
              <Legend />
              <Line type="monotone" dataKey={dataKey} stroke="#8884d8" name={t('Systolic')} />
              <Line type="monotone" dataKey={maxValueKey} stroke="#82ca9d" name={t("Diastolic")} />
              <Customized component={CustomizedRectangle} />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          // Veri yoksa bilgilendirme mesajı
          <div style={{ textAlign: 'center', marginTop: '2rem' }}>
            <p>{t('No data available for the selected time frame')}</p>
          </div>
        )}
      </div>
    );
  } else {
    return (
    <div className='text-center'>
      <ButtonGroup className='py-2 time-category'>
        {renderTimeFrameButtons()}
      </ButtonGroup>
      {hasData ? (
        <ResponsiveContainer width="95%">
          <LineChart data={filteredData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={effectiveDataKey} {...(effectiveDataKey !== "dayTime" ? { angle: -45, textAnchor: "end" } : {})} height={100} padding={{ left: 8, right: 8 }} />
            <YAxis />
            <Tooltip formatter={customTooltipFormatter} />
            <Legend />
            <Line type="monotone" dataKey={maxValueKey} stroke="#82ca9d" name={`Max ${t('Value')}`} />
            <Line type="monotone" dataKey={dataKey} stroke="#8884d8" name={`Min ${t('Value')}`} />
            
            <Customized component={CustomizedRectangle} />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        // Veri yoksa bilgilendirme mesajı
        <div style={{ textAlign: 'center', marginTop: '2rem' }}>
          <p>{t('No data available for the selected time frame')}</p>
        </div>
      )}
    </div>
  );
  }

  
};

export default TwoCharts;
