import i18n from 'i18next'
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import en from './constants/healthdataen'
import de from './constants/healthdatade'
import tr from './constants/healthdatatr'

import tEN from "./locales/en/translation.json"
import tTR from "./locales/tr/translation.json"
import tDE from "./locales/de/translation.json"

const resources = {
    en:{
        translation:tEN,en
    },
    tr:{
        translation:tTR,tr
    },
    de:{
        translation:tDE,de
    }
}
i18n
   .use(LanguageDetector)
   .use(initReactI18next)
   .init({
    fallbackLng:"en",
    debug:false,
    resources,
    interpolation:{
        escapeValue:false,
    }

   })



    export default i18n