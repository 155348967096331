// src/context/BusinessContext.jsx

import React, { createContext, useEffect, useState } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";

export const BusinessContext = createContext();

export const BusinessProvider = ({ children }) => {
  const [businesses, setBusinesses] = useState([]);

  useEffect(() => {
    const businessCollection = collection(db, "business");
    const unsubscribe = onSnapshot(businessCollection, (snapshot) => {
      const businessData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBusinesses(businessData);
    });

    return () => unsubscribe();
  }, []);

  return (
    <BusinessContext.Provider value={{ businesses }}>
      {children}
    </BusinessContext.Provider>
  );
};
