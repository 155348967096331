  import React, { useState, useEffect, useContext, useRef } from "react";
  import { getFunctions, httpsCallable } from 'firebase/functions';
  import { toast } from "react-toastify";
  import { Modal, Button, Form, Tabs, Tab, InputGroup, Spinner } from "react-bootstrap";
  import { QRCodeCanvas } from "qrcode.react";
  import { useTranslation } from "react-i18next";
  import * as XLSX from 'xlsx'; // Excel veya CSV dosya işleme
  import './AddPatient.css';
  import { AuthContext } from "../../context/AuthContext";
  import axios from "axios";
  import { doc, getDoc, updateDoc } from "firebase/firestore";
  import { db } from "../../firebase";
  import copy from '../../images/icons/Copy.svg';
  import excelpng from '../../images/excel.png'
  import yesil from '../../images/yesiltik.svg'
import { Navigate, useNavigate } from "react-router-dom";
  const AddPatientModal = ({ isModalOpen, handleClose }) => {
    const { currentUser } = useContext(AuthContext);
    const [email, setEmail] = useState("");
    const [csvPatients, setCsvPatients] = useState([]); // CSV'den gelen hastalar
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [age, setAge] = useState("");
    const [sex, setSex] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [dynamicLink, setDynamicLink] = useState(""); 
    const [activeTab, setActiveTab] = useState("mail"); // Main tab control (Mail, QR, Link)
    const [activeMailTab, setActiveMailTab] = useState("one"); // Sub-tab control for Mail (Invite One User, Invite Multiple Users)
    const { t } = useTranslation();
    const qrCodeRef = useRef(null);
    const [doctorName, setDoctorName] = useState('');
    const [doctorSurname, setDoctorSurname] = useState('');
    const [doctorID, setDoctorID] = useState('');
    const [subscriptionType, setSubscriptionType] = useState('none'); // Subscription type (monthly, yearly)
    const fileInputRef = useRef(null);
    const [dragActive, setDragActive] = useState(false);
    const [uploadedFileName, setUploadedFileName] = useState("");
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [role, setRole] = useState("");
    const [activeSubscriptionTab, setActiveSubscriptionTab] = useState(null);
  const [tokenDetails, setTokenDetails] = useState({ total: 0, used: 0, remaining: 0 });
  const [isTokenModalOpen, setIsTokenModalOpen] = useState(false);
  const [agreeToCredits, setAgreeToCredits] = useState(false);
  const [doctorData, setDoctorData] = useState(null);
  const [isTokenModalConfirmed, setIsTokenModalConfirmed] = useState(false);
  const [showMore, setShowMore] = useState(false); 
  const navigate = useNavigate()
  const handleCheckboxChange = () => {
    setAgreeToCredits(!agreeToCredits);  // Durumu direk değiştir
  };
  
  const handleButtonClick = () => {
    if (agreeToCredits) {
      setIsTokenModalOpen(false); // Modalı kapat
      setIsTokenModalConfirmed(true); // Token onayını işaretle
      // Mail gönderim işlemi burada başlatılıyor
      performSendEmails();  
    } else {
      toast.error(t("You must agree to the use of credits to proceed.")); // Eğer checkbox işaretlenmediyse hata mesajı göster
    }
  };
  const performSendEmails = async () => {
    setIsLoading(true);
    try {
        let patientsToAdd = csvPatients.length > 0 ? csvPatients : [{ email, name, surname, age, sex }];
        const { doctorPayment, doctorPaymentExpiration, payment } = getSubscriptionDetails();

        const functions = getFunctions();
        const sendCustomEmail = httpsCallable(functions, 'sendCustomEmail');

        const sendEmailPromises = patientsToAdd.map(async (patient) => {
            const randomPassword = generateRandomPassword();
            return sendCustomEmail({
                recipientEmail: patient.email,
                patientName: patient.name,
                surname: patient.surname,
                doctorID: doctorID,
                doctorName: doctorName,
                doctorSurname: doctorSurname,
                password: randomPassword,
                doctorPayment: isCheckboxChecked ? doctorPayment : false,
                payment: isCheckboxChecked ? payment : false,
                doctorPaymentExpiration: isCheckboxChecked ? doctorPaymentExpiration?.toISOString() : null,
                subscriptionType: isCheckboxChecked ? subscriptionType : 'none',
                role: doctorData.role
            });
        });

        await Promise.all(sendEmailPromises);
        toast.success("Emails successfully sent!");

        // Token'ları güncelle
        if (isCheckboxChecked && subscriptionType !== 'none') {
            const tokenField = subscriptionType === 'monthly' ? 'tokenMonthly' : 'tokenYearly';
            const currentTokens = Number(doctorData[tokenField]);
            const updatedTokens = currentTokens - patientsToAdd.length;
            await updateDoc(doc(db, "doctors", doctorID), {
                [tokenField]: updatedTokens,
            });
            setDoctorData(prevData => ({
                ...prevData,
                [tokenField]: updatedTokens,
            }));
        }

        resetForm();

        // Kullanıcıyı /users sayfasına yönlendir
        navigate('/users');

        // Modalı kapatma işlemi yönlendirme yapıldıktan sonra olmalı
        handleClose(); 

    } catch (error) {
        console.error("Error sending emails:", error.message);
        toast.error("Error occurred while sending emails.");
    } finally {
        setIsLoading(false);
        setIsTokenModalConfirmed(false); // Token modal onayını sıfırla
    }
};



  const getSubscriptionDetails = () => {
    let doctorPayment = false;
    let payment = false;
    let doctorPaymentExpiration = null;
    const currentDate = new Date();
  
    // Eğer premium seçiliyse ve abonelik türü belirlenmişse premium detaylarını ayarla
    if (isCheckboxChecked) {
      if (subscriptionType === 'monthly') {
        doctorPayment = true;
        payment = true;
        doctorPaymentExpiration = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
      } else if (subscriptionType === 'yearly') {
        doctorPayment = true;
        payment = true;
        doctorPaymentExpiration = new Date(currentDate.setFullYear(currentDate.getFullYear() + 1));
      }
    }
  
    return { doctorPayment, doctorPaymentExpiration, payment };
  };
 
    useEffect(() => {
      if (role === 'business' && (activeTab === 'link' || activeTab === 'qr')) {
        setActiveTab('mail');
      }
    }, [role, activeTab]);
    useEffect(() => {
      if (currentUser && isModalOpen) {
        generateDynamicLink();
        fetchDoctorRole();
      }
    }, [currentUser,isModalOpen]);
    const fetchDoctorRole = async () => {
      if (currentUser) {
        try {
          console.log("Fetching role for user:", currentUser.uid); // Debug log
          const doctorRef = doc(db, "doctors", currentUser.uid);
          const doctorSnap = await getDoc(doctorRef);
          
          if (doctorSnap.exists()) {
            const doctorData = doctorSnap.data();
            console.log("Doctor data:", doctorData); // Doctor verilerini kontrol et
            setRole(doctorData.role);
            setDoctorData(doctorData);   // Role bilgisini state'e kaydediyoruz
          } else {
            console.log("Doktor bilgisi bulunamadı.");
          }
        } catch (error) {
          console.error("Doktor rolü alınırken hata oluştu:", error.message);
        }
      }
    };
  
    const generateDynamicLink = async () => {
      if (currentUser) {
        try {
          setIsLoading(true);
          const doctorRef = doc(db, "doctors", currentUser.uid);
          const doctorSnap = await getDoc(doctorRef);
          if (doctorSnap.exists()) {
            const doctorData = doctorSnap.data();
            setDoctorName(doctorData.Name);
            setDoctorSurname(doctorData.Surname);
            setDoctorID(currentUser.uid);

            const response = await axios.get(
              `https://us-central1-realworks-health.cloudfunctions.net/generateDynamicLink?doctorID=${currentUser.uid}&doctorName=${doctorData.Name}&doctorSurname=${doctorData.Surname}`
            );
            setDynamicLink(response.data.shortLink);
          } else {
            console.log("No such doctor document!");
          }
        } catch (error) {
          console.error("Error creating dynamic link:", error.response ? error.response.data : error.message);
        } finally {
          setIsLoading(false);
        }
      }
    };

    const generateRandomPassword = () => {
      const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let password = "";
      for (let i = 0; i < 8; i++) {
        password += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return password;
    };

    const handleFileUpload = (e) => {
      const file = e.target.files[0];  // Yüklenen dosyayı alıyoruz
      if (!file) return;
    
      const fileName = file.name.toLowerCase();  // Dosya adını küçük harfe çeviriyoruz
    
      const reader = new FileReader();  // Dosya okuma işlemi başlıyor
    
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);  // Dosya verisini Uint8Array olarak alıyoruz
    
        // Eğer dosya CSV formatında ise bunu metin olarak işliyoruz
        if (fileName.endsWith('.csv')) {
          const textData = event.target.result;
          processCsvData(textData);  // CSV dosyalarını işlemek için özel bir fonksiyon
        } else if (fileName.endsWith('.xlsx') || fileName.endsWith('.xls')) {
          // Excel dosyalarını işliyoruz
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const sheetData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });  // Excel'den JSON'a çeviriyoruz
          processSheetData(sheetData);  // Excel verisini işliyoruz
        } else {
          toast.error("Unsupported file format. Please upload a .csv, .xlsx, or .xls file.");
        }
      };
    
      // Dosya formatına göre okuma işlemi
      if (fileName.endsWith('.csv')) {
        reader.readAsText(file);  // CSV dosyasını metin olarak okuyoruz
      } else {
        reader.readAsArrayBuffer(file);  // Excel dosyalarını ArrayBuffer olarak okuyoruz
      }
    };
    const processCsvData = (csvData) => {
      const rows = csvData.trim().split("\n");  // CSV verisini satır satır alıyoruz ve boşlukları temizliyoruz
      const patients = rows
        .map((row, index) => {
          const columns = row.split(",");  // Satırı virgülle ayırarak sütunlara bölüyoruz
          // Sütunlarda bir hata yoksa email, name ve surname verilerini alıyoruz
          if (columns.length >= 3) {
            const email = columns[0]?.trim();
            const name = columns[1]?.trim() || "";
            const surname = columns[2]?.trim() || "";
    
            return {
              email,
              name,
              surname
            };
          } else {
            console.error(`Row ${index + 1} is invalid. Skipping.`);
            return null;
          }
        })
        .filter(patient => patient && validateEmail(patient.email));  // Geçerli email adresleri olan hastaları filtreliyoruz
    
      if (patients.length > 0) {
        setCsvPatients(patients);
        toast.success("Valid patients extracted from the CSV file.");
      } else {
        toast.error("No valid patients found in the CSV file.");
      }
    };
    
    const processSheetData = (sheetData) => {
      const patients = sheetData
        .filter(row => row[0] !== undefined && row[0] !== "")  // Boş satırları atlıyoruz
        .map(row => ({
          email: row[0],
          name: row[1] || "",
          surname: row[2] || ""
        }))
        .filter(patient => validateEmail(patient.email));  // Geçerli email adreslerine göre filtreleme
    
      if (patients.length > 0) {
        setCsvPatients(patients);
        toast.success("Valid patients extracted from the Excel file.");
      } else {
        toast.error("No valid patients found in the Excel file.");
      }
    };
    
    const handleChooseFileClick = () => {
      if (fileInputRef.current) {
        fileInputRef.current.click(); // Trigger the hidden file input
      }
    };
 
    const handleAddPatient = async (e) => {
      if (e) e.preventDefault();
    
      if (isLoading) return;
    
      setIsLoading(true);
    
      let patientsToAdd = [];
    
      // CSV'den gelen hastaları ekle
      if (csvPatients.length > 0) {
        patientsToAdd = csvPatients;
      } else if (email.trim()) {
        // Manuel eklenen email
        const trimmedEmail = email.trim();
        patientsToAdd.push({
          email: trimmedEmail,
          name,
          surname,
          age,
          sex
        });
      }
    
      // Eğer hasta yoksa işlemi durdur
      if (patientsToAdd.length === 0) {
        toast.error("No patients to send invitations to. Please upload an Excel file or manually enter a patient.");
        setIsLoading(false);
        return;
      }
    
      // Modal gösterilmeli mi kontrol et
      if (
        role === 'business' &&
        isCheckboxChecked &&
        (subscriptionType === 'monthly' || subscriptionType === 'yearly') &&
        !isTokenModalConfirmed
      ) {
        // Tokenları hesapla
        const hasSufficientTokens = calculateTokens(patientsToAdd.length, subscriptionType);
        if (!hasSufficientTokens) {
          return;
        }
        // Token kontrolü
        if (tokenDetails.remaining < 0) {
          toast.error("You don't have enough tokens to send invitations.");
          setIsLoading(false);
          return;
        }
    
        // Modal'ı göster
        setIsTokenModalOpen(true);
    
        // İşlemi durdur
        setIsLoading(false);
        return;
      }
    
      const invalidPatients = patientsToAdd.filter(p => !validateEmail(p.email));
      if (invalidPatients.length > 0) {
        toast.error(`Geçersiz email adresleri: ${invalidPatients.map(p => p.email).join(", ")}`);
        setIsLoading(false);
        return;
      }
    
      const { doctorPayment, doctorPaymentExpiration, payment } = getSubscriptionDetails();
    
      try {
        const doctorRef = doc(db, "doctors", doctorID);
        const doctorSnap = await getDoc(doctorRef);
        if (doctorSnap.exists()) {
          const doctorData = doctorSnap.data();
          const totalPatients = patientsToAdd.length;
    
          // Mail gönderme işlemi
          const functions = getFunctions();
          const sendCustomEmail = httpsCallable(functions, 'sendCustomEmail');
    
          const sendEmailPromises = patientsToAdd.map(async (patient) => {
            const randomPassword = generateRandomPassword();
    
            return sendCustomEmail({
              recipientEmail: patient.email,
              patientName: patient.name,
              surname: patient.surname,
              doctorID: doctorID,
              doctorName: doctorName,
              doctorSurname: doctorSurname,
              password: randomPassword,
              doctorPayment: isCheckboxChecked ? doctorPayment : false, // Checkbox seçili değilse false
              payment: isCheckboxChecked ? payment : false,            // Checkbox seçili değilse false
              doctorPaymentExpiration: isCheckboxChecked && doctorPaymentExpiration ? doctorPaymentExpiration.toISOString() : null,
              subscriptionType: isCheckboxChecked ? subscriptionType : 'none', // Checkbox seçili değilse 'none'
              role: doctorData.role,
              companyName: doctorData.businessName
            });
          
          });

          await Promise.all(sendEmailPromises);
          toast.success("Mailler başarıyla gönderildi!");
    
          // Tokenları düş
          if (isCheckboxChecked && subscriptionType !== 'none') {
            const tokenField = subscriptionType === 'monthly' ? 'tokenMonthly' : 'tokenYearly';
            const currentTokens = Number(doctorData[tokenField]);
            const updatedTokens = currentTokens - totalPatients;
    
            await updateDoc(doctorRef, {
              [tokenField]: updatedTokens,
            });
    
            // doctorData'yı güncelle
            setDoctorData(prevData => ({
              ...prevData,
              [tokenField]: updatedTokens,
            }));
          }
    
          resetForm();
        }
      } catch (error) {
        console.error("Mailler gönderilirken hata oluştu:", error.message);
        toast.error("Mailler gönderilirken hata oluştu.");
      } finally {
        setIsLoading(false);
        handleClose();
        // Modal onayını sıfırla
        setIsTokenModalConfirmed(false);
      }
    };
    
  const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(true);
    };

    const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
    };

    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);

      const file = e.dataTransfer.files[0];
      if (file) {
        setUploadedFileName(file.name);   
        processFile(file); // Pass the dropped file for processing
      }
    };  
    
    const processFile = (file) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const sheetData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

        const patients = sheetData
          .filter(row => row[0] !== undefined && row[0] !== "") // Remove empty rows
          .map(row => ({
            email: row[0],
            name: row[1] || "",
            surname: row[2] || ""
          }))
          .filter(patient => validateEmail(patient.email));

        if (patients.length > 0) {
          setCsvPatients(patients);
          toast.success("Valid patients extracted from the file.");
        } else {
          toast.error("No valid patients found in the file.");
        }
      };

      if (file.name.endsWith('.csv')) {
        reader.readAsText(file);
      } else {
        reader.readAsArrayBuffer(file);
      }
    };

    const resetForm = () => {
      setEmail("");
      setCsvPatients([]);
      setName("");
      setSurname("");
      setAge("");
      setSex("");
      setDynamicLink("");
      setSubscriptionType('none');
    };

    const validateEmail = (email) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    };

    const handleCopyLink = () => {
      if (dynamicLink) {
        navigator.clipboard.writeText(dynamicLink);
        toast.success("Link copied to clipboard!");
      }
    };
    const downloadQRCode = () => {
      const canvas = qrCodeRef.current;
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      const downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = "qr_code.png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };
    const handleCheckboxToggle = () => {
      setIsCheckboxChecked(!isCheckboxChecked); // Checkbox durumunu değiştir
      setSubscriptionType('none'); // Checkbox değiştiğinde abonelik tipini sıfırla
    };
    const handleDeletePatient = (index) => {
      const updatedPatients = [...csvPatients];
      updatedPatients.splice(index, 1); // Remove the patient from the array by index
      setCsvPatients(updatedPatients); // Update the state with the modified array
      toast.success("Patient removed successfully.");
    };
    const calculateTokens = (numPatients, subType) => {
      if (!doctorData) {
        console.error("Doctor data not loaded");
        return;
      }
    
      const tokenField = subType === 'yearly' ? 'tokenYearly' : 'tokenMonthly';
      const totalTokens = Number(doctorData[tokenField]);
    
      if (isNaN(totalTokens)) {
        console.error(`Token field ${tokenField} is not a number`);
        return;
      }
    
      const tokenCostPerPatient = 1;
      const tokensUsed = numPatients * tokenCostPerPatient;
      let remainingTokens = totalTokens - tokensUsed;
    
      // Token sayısı yetersizse hata mesajı ver ve işlemi durdur
      if (remainingTokens < 0) {
        toast.error(`You don't have enough ${subType === 'yearly' ? 'yearly' : 'monthly'} tokens for ${numPatients} invitations.`);
        setIsTokenModalOpen(false); // Tokenlar yetersizse modal açılmasın
        setIsLoading(false); // İşlem durdurulsun
        return false; // İşlem burada sonlandırılacak ve token modalı açılmayacak
      }
    
      // Yeterli token varsa güncelleme yap ve modalı aç
      setTokenDetails({ total: totalTokens, used: tokensUsed, remaining: remainingTokens });
      return true; // Yeterli token varsa devam et
    };
    
    
    const TokenDetailsModal =  React.memo(() => (
      <Modal show={isTokenModalOpen} onHide={() => setIsTokenModalOpen(false)} centered>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="d-flex justify-content-between mb-3">
            <p style={{ fontWeight: 500, fontSize: '16px', fontFamily: 'Poppins', color: '#000000' }}>
              {t("Your total")}{" "}
              {subscriptionType === "yearly" ? t("Yearly Plus") : t("Monthly Plus")}{" "}
              {t("credit count")}
            </p>
            <p style={{ fontWeight: 500, fontFamily: 'Poppins', fontSize: '20px', color: "#000000" }}>{tokenDetails.total}</p>
          </div>
    
          <div className="d-flex justify-content-between mb-3">
            <p style={{ fontWeight: 500, fontSize: '16px', fontFamily: 'Poppins', color: '#000000' }}>
              {t("Number of")}{" "}
              {subscriptionType === "yearly" ? t("Yearly Plus") : t("Monthly Plus")}{" "}
              {t("credits to be used in these invitations")}
            </p>
            <p style={{ fontWeight: 500, fontFamily: 'Poppins', fontSize: '20px', color: "#000000" }}>-{tokenDetails.used}</p>
          </div>
    
          <hr />
    
          <div className="d-flex justify-content-between mb-4">
            <p style={{ fontWeight: 500, fontSize: '16px', fontFamily: 'Poppins', color: '#000000' }}>
              {t("Number of remaining credits after these invitations")}
            </p>
            <p style={{ fontWeight: 500, fontFamily: 'Poppins', fontSize: '20px', color: "#000000" }}>{tokenDetails.remaining}</p>
          </div>
    
          <div className="mt-4 d-flex align-items-center">
            <Form.Check
              type="checkbox"
              label={t("I agree to the use of credits")}
              checked={agreeToCredits}
              onChange={handleCheckboxChange}  // Checkbox durumunu güncelle
              style={{ fontSize: "16px", fontWeight: 500, color: "#677184" }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleButtonClick}  // Butona tıklandığında sadece bu fonksiyon çalışacak
            disabled={!agreeToCredits}  // Checkbox işaretlenmediği sürece buton devre dışı kalacak
            style={{
              backgroundColor: "#141517",
              border: "none",
              width: "100%",
              padding: "12px 0",
              fontWeight: 600,
            }}
          >
            {t("Send")}
          </Button>
        </Modal.Footer>
      </Modal>
    ));
    
    return (
      <>
        <Modal show={isModalOpen} onHide={handleClose} centered size="lg">
          <Modal.Header closeButton style={{ borderBottom: 'none', paddingBottom: '0' }}>
            <Modal.Title>{t('New Patient')}</Modal.Title>
          </Modal.Header>
  
          {/* Ana Sekmeler */}
          <Tabs
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k)}
            className="mt-3 custom-tabs"
            id="invitation-tabs"
          >
            {role !== 'business' && <Tab eventKey="link" title={t('Link')} />}
            {role !== 'business' && <Tab eventKey="qr" title={t('QR Code')} />}
            <Tab eventKey="mail" title={t('Mail')} />
          </Tabs>
          <Modal.Body>
            <Form onSubmit={handleAddPatient}>
              {/* Link Sekmesi İçeriği */}
              {activeTab === "link" && (
                <>
                  <h5 className="section-title">{t("Invitation Link")}</h5>
                  {isLoading ? (
                    <div style={{ width: 100, height: 100, margin: '0 auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </div>
                  ) : (
                    <InputGroup className="mt-2">
                      <Form.Control type="text" style={{ backgroundColor: '#fff' }} value={dynamicLink || "Generating link..."} disabled />
                      <Button variant="" onClick={handleCopyLink} disabled={!dynamicLink}>
                        <img src={copy} alt="Copy" />
                      </Button>
                    </InputGroup>
                  )}
                </>
              )}
  
              {/* QR Code Sekmesi İçeriği */}
              {activeTab === "qr" && (
                <>
                  <h5 className="section-title">{t("Invitation QR Code")}</h5>
                  <div className="text-center mt-3">
                    {dynamicLink ? (
                      <>
                        <div className="mb-3">
                          <QRCodeCanvas id="qrCodeCanvas" ref={qrCodeRef} value={dynamicLink} size={150} />
                        </div>
                        <Button variant="outline-secondary" onClick={downloadQRCode} style={{ width: '100%', backgroundColor: '#000', color: '#fff' }} className="mt-3">
                          {t("Download QR Code")}
                        </Button>
                      </>
                    ) : (
                      <p>{t("Generating QR code...")}</p>
                    )}
                  </div>
                </>
              )}
  
              {/* Mail Sekmesi ve Alt Sekmeleri */}
              {activeTab === "mail" && (
                <>
                  {/* Alt Sekmeler */}
                  <div className="sub-tabs">
                    <Button
                      style={{ textDecoration: 'none' }}
                      variant="link"
                      className={`sub-tab ${activeMailTab === 'one' ? 'active' : ''}`}
                      onClick={() => setActiveMailTab('one')}
                    >
                      {t('Invite One User')}
                    </Button>
                    <Button
                      style={{ textDecoration: 'none' }}
                      variant="link"
                      className={`sub-tab ${activeMailTab === 'multiple' ? 'active' : ''}`}
                      onClick={() => setActiveMailTab('multiple')}
                    >
                      {t('Invite Multiple Users')}
                    </Button>
                  </div>
  
                  {/* Tek Kullanıcı Davet İçeriği */}
                  {activeMailTab === 'one' && (
                    <>
                      <h5 className="section-title">{t("Personal Information")}</h5>
                      <div className="row">
                        <div className="col-12">
                          <Form.Group controlId="formName">
                            <Form.Label>{t("Name")}</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder={t("Enter user name")}
                              value={name}
                              required
                              onChange={(e) => setName(e.target.value)}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-12">
                          <Form.Group controlId="formSurname">
                            <Form.Label>{t("Surname")}</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder={t("Enter user surname")}
                              value={surname}
                              required
                              onChange={(e) => setSurname(e.target.value)}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <Form.Group controlId="formEmail" className="mt-3">
                            <Form.Label>{t("Email")}</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder={t("Type user email")}
                              value={email}
                              required
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-6">
                          <Form.Group controlId="formSex" className="mt-3">
                            <Form.Label>{t("gender")}</Form.Label>
                            <Form.Control
                              as="select"
                              value={sex}
                              onChange={(e) => setSex(e.target.value)}
                              required
                            >
                              <option value="Male">{t("Male")}</option>
                              <option value="Female">{t("Female")}</option>
                              <option value="Other">{t("Other")}</option>
                            </Form.Control>
                          </Form.Group>
                        </div>
                        <div className="col-6">
                          <Form.Group controlId="formAge" className="mt-3">
                            <Form.Label>{t("Age")}</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder={t("Enter user age")}
                              value={age}
                              required
                              onChange={(e) => setAge(e.target.value)}
                              min="0"
                            />
                          </Form.Group>
                        </div>
                        {role === 'business' && (
                          <>
                            <div className="subscription-section text-start mt-4 d-flex align-items-center">
                              <div className="checkbox-container d-flex align-items-center">
                                <Form.Check
                                  type="checkbox"
                                  checked={isCheckboxChecked}
                                  onChange={handleCheckboxToggle}
                                  id="realHealthCheckbox"
                                  className="custom-checkbox"
                                />
                                <span style={{ fontFamily: 'Poppins', fontWeight: 400, fontSize: '16px', color: '#677184', marginLeft: '10px' }}>
                                  {t("Make imported users RealHealth+ members.")}
                                </span>
                              </div>
                            </div>
  
                            {isCheckboxChecked && (
                              <>
                                <span style={{ fontFamily: 'Poppins', fontWeight: 400, fontSize: '16px', color: '#677184', marginLeft: '30px' }}>
                                  {t("Please Select a Subscription Plan.")}
                                </span>
                                <div className="premium-buttons d-flex justify-content-start mt-3">
                                  <button
                                    className={`subscription-btn mr-2 ${subscriptionType === 'monthly' ? 'active' : ''}`}
                                    onClick={() => {
                                      setSubscriptionType('monthly');
                                    }}
                                    type="button"
                                    style={{
                                      backgroundColor: subscriptionType === 'monthly' ? '#7978DE' : '#f0f0f0',
                                      color: subscriptionType === 'monthly' ? '#fff' : '#000',
                                    }}
                                  >
                                    {t("Monthly")}
                                  </button>
                                  <button
                                    className={`subscription-btn ${subscriptionType === 'yearly' ? 'active' : ''}`}
                                    onClick={() => {
                                      setSubscriptionType('yearly');
                                    }}
                                    type="button"
                                    style={{
                                      backgroundColor: subscriptionType === 'yearly' ? '#7978DE' : '#f0f0f0',
                                      color: subscriptionType === 'yearly' ? '#fff' : '#000',
                                    }}
                                  >
                                    {t("Yearly")}
                                  </button>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )}
  
                  {/* Çoklu Kullanıcı Davet İçeriği */}
                  {activeMailTab === 'multiple' && (
                    <>
                      <h5 className="section-title text-start">{t("Invitation")}</h5>
  
                      {/* Excel Yükleme Bölümü */}
                      <Form.Group controlId="formFile" className="mt-3 text-center">
                        <div style={{ border: '1px solid #D9D9D9', padding: '16px', borderRadius: "20px" }} className={`file-upload-box ${dragActive ? 'drag-active' : ''}`}
                          onDragOver={handleDragOver}
                          onDragLeave={handleDragLeave}
                          onDrop={handleDrop}>
                          <Form.Label>{t("Import From Excel")}</Form.Label>
                          <p className="file-upload-text">{t("You can upload / drop .xlsx file/ .numbers file")}</p>
                          <img src={excelpng} alt="Excel Icon" className="excel-icon mb-2" /> {/* Excel icon centered */}
  
                          {/* Dosya seçme inputu */}
                          <Form.Control
                            type="file"
                            accept=".csv, .xlsx, .xls"
                            onChange={handleFileUpload}
                            className="choose-file-button"
                            style={{ display: 'none' }}
                            ref={fileInputRef}   // Hiding the actual file input
                          // Reference for triggering file input via button
                          />
  
                          <p className="file-name">{uploadedFileName || t("No file selected")}</p>
  
                          {/* Dosya seçme butonu */}
                          <Button style={{ backgroundColor: 'rgba(16, 24, 40, 0.05)', color: '#000000', borderColor: 'rgba(255, 255, 255, 1)', width: '50%' }} onClick={handleChooseFileClick} className="mt-2">
                            {t("Choose File")}
                          </Button>
                        </div>
                      </Form.Group>
  
                      {/* Yüklenen Kullanıcıların Listesi */}
                      <div className="uploaded-users mt-4">
                      {csvPatients.slice(0, showMore ? csvPatients.length : 5).map((patient, index) => (
                          <div className="user-row d-flex align-items-center" key={index}>
                            <Form.Control
                              type="text"
                              placeholder={t("Name / Surname")}
                              value={`${patient.name} ${patient.surname}`}
                              className="user-input mr-2"
                              disabled
                            />
                            <Form.Control
                              type="email"
                              placeholder={t("E-Mail")}
                              value={patient.email}
                              className="user-input mr-2"
                              disabled
                            />
                            <Button
                              variant="danger"
                              className="delete-user-btn ml-2"
                              onClick={() => handleDeletePatient(index)} // Hastayı silme fonksiyonu
                            >
                              {t("Delete")}
                            </Button>
                          </div>
  
                        ))}
                         {csvPatients.length > 5 && !showMore && (
            <Button variant="link" style={{textDecoration:'none',color:'#677184',textAlign:'center'}} className="mt-3" onClick={() => setShowMore(true)}>
              {t("Show More")}  ({csvPatients.length - 5})
            </Button>
          )}
          {csvPatients.length > 5 && showMore && (
            <Button variant="link" style={{textDecoration:'none',color:'#677184'}} className="mt-3" onClick={() => setShowMore(false)}>
              {t("Show Less")}
            </Button>
          )}
                      </div>
                      {role === 'business' && (
                        <>
                          <div className="subscription-section text-start mt-4 d-flex align-items-center">
                            <div className="checkbox-container d-flex align-items-center">
                              <Form.Check
                                type="checkbox"
                                checked={isCheckboxChecked}
                                onChange={handleCheckboxToggle}
                                id="realHealthCheckbox"
                                className="custom-checkbox"
                              />
                              <span style={{ fontFamily: 'Poppins', fontWeight: 400, fontSize: '16px', color: '#677184', marginLeft: '10px' }}>
                                {t("Make imported users RealHealth+ members.")}
                              </span>
                            </div>
                          </div>
  
                          {isCheckboxChecked && (
                            <>
                              <span style={{ fontFamily: 'Poppins', fontWeight: 400, fontSize: '16px', color: '#677184', marginLeft: '30px' }}>
                                {t("Please Select a Subscription Plan.")}
                              </span>
                              <div className="premium-buttons d-flex justify-content-start mt-3">
                                <button
                                  className={`subscription-btn mr-2 ${subscriptionType === 'monthly' ? 'active' : ''}`}
                                  onClick={() => {
                                    setSubscriptionType('monthly');
                                  }}
                                  type="button"
                                  style={{
                                    backgroundColor: subscriptionType === 'monthly' ? '#7978DE' : '#f0f0f0',
                                    color: subscriptionType === 'monthly' ? '#fff' : '#000',
                                  }}
                                >
                                  {t("Monthly")}
                                </button>
                                <button
                                  className={`subscription-btn ${subscriptionType === 'yearly' ? 'active' : ''}`}
                                  onClick={() => {
                                    setSubscriptionType('yearly');
                                  }}
                                  type="button"
                                  style={{
                                    backgroundColor: subscriptionType === 'yearly' ? '#7978DE' : '#f0f0f0',
                                    color: subscriptionType === 'yearly' ? '#fff' : '#000',
                                  }}
                                >
                                  {t("Yearly")}
                                </button>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                  <div className="mt-4">
                    <Button variant="primary" type="submit" disabled={isLoading} style={{ backgroundColor: '#141517', border: 'none' }}>
                      {isLoading ? (
                        <>
                          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> {t("Sending Invitations...")}
                        </>
                      ) : (
                        t("Send Invitation")
                      )}
                    </Button>
                  </div>
                </>
              )}
            </Form>
          </Modal.Body>
  
        </Modal>
        {isTokenModalOpen && <TokenDetailsModal />}
      </>
    );
  };

  export default AddPatientModal;
