import React from "react";

import { Link } from "react-router-dom";
const Expired = () => {
  return (
    <div className="flex flex-col items-center mt-20 max-w-5xl mx-auto text-center text-white">
      <h1 className="text-5xl my-5 text-black">The Link Has Been Expired </h1>
      <Link to="/login">
        <span className="text-4xl text-gray-400 mb-5 text-center">Back To Login Page</span>
      </Link>
    </div>
  );
};

export default Expired;
