import React, { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { DoctorContext } from '../context/DoctorContext';
import { db, db2, db3, db4, db5, db6, db7, db8, db9 } from '../firebase';
import { collection, addDoc, onSnapshot, doc, setDoc } from 'firebase/firestore';
import { TeamContext } from '../context/TeamContext';
import { AuthContext } from "../context/AuthContext";

const MAX_CHAR_LIMIT = 200;
const FcmNotificationSender = ({ token, doctorId, userId, region }) => {
  const { t } = useTranslation();
  const [userMessage, setUserMessage] = useState('');
  const [isNotificationAllowed, setIsNotificationAllowed] = useState(true);
  const { doctors } = useContext(DoctorContext);
  const { teams } = useContext(TeamContext);
  const { currentUser, business } = useContext(AuthContext);

  const currentTeamMember = teams.find((team) => team.id === currentUser.uid);
  const currentDoctor = doctors.find((doc) => doc.id === (currentTeamMember?.doctorId || currentUser.uid));

  const userType = currentDoctor || business; // Dynamically determine if it's a doctor or business

  useEffect(() => {
    const checkNotificationStatus = () => {
      let isBlocked = false;
  
      // Current doctor notification control
      if (currentDoctor?.notificationOf?.includes(userId)) {
        isBlocked = true;
      }
  
      // Current team member notification control
      if (currentTeamMember?.notificationOf?.includes(userId)) {
        isBlocked = true;
      }
  
      // Business notification control
      if (business?.notificationOf?.includes(userId)) {
        isBlocked = true;
      }
  
      setIsNotificationAllowed(!isBlocked);
    };
  
    checkNotificationStatus();
  
    // Eğer currentDoctor veya business tanımlıysa snapshot dinleyicisini ayarla
    if (currentDoctor?.id || business?.id) {
      const unsubscribeDoctor = onSnapshot(doc(db, currentDoctor ? 'doctors' : 'business', currentDoctor?.id || business?.id), (doc) => {
        checkNotificationStatus();
      });
  
      return () => {
        unsubscribeDoctor();
      };
    }
  }, [currentDoctor, currentTeamMember, userId, business]);
  
  

  const handleMessageChange = (event) => {
    setUserMessage(event.target.value);
  };

  const sendNotification = async () => {
    if (!isNotificationAllowed) {
      toast.error(t('Bu kullanıcıya bildirim gönderme izniniz yok.'));
      return;
    }
  
    if (!userMessage.trim()) {
      toast.error(t('Göndermeden önce bir mesaj giriniz.'));
      return;
    }
  
    if (!token || token.trim() === "") {
      toast.error(t('Bildirim Gönderirken Bir Hata Oluştu!'));
      return;
    }
  
    const fullMessage = `${userMessage}`;
    const notificationTitle = userType ? `${userType.Name} ${userType.Surname}` : 'RealHealth';
  
    try {
      const response = await fetch('https://us-central1-realworks-health.cloudfunctions.net/sendNotification', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, title: notificationTitle, body: fullMessage }),
      });
  
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(`HTTP hatası! Durum: ${response.status}, mesaj: ${errorMessage}`);
      }
  
      toast.success(t('Notification Send Successfully.'));
  
      let selectedDb = db; // Default DB
      if (region === 'Netherlands') selectedDb = db2;
      else if (region === 'Belgium') selectedDb = db3;
      else if (region === 'Germany') selectedDb = db4;
      else if (region === 'Finland') selectedDb = db5;
      else if (region === 'United Kingdom') selectedDb = db6;
      else if (region === 'United States of America') selectedDb = db7;
      else if (region === 'Poland') selectedDb = db8;
      else if (region === 'Switzerland') selectedDb = db9;
  
      const userRef = doc(selectedDb, 'notifications', userId);
      await setDoc(userRef, { key: 'xxx' }, { merge: true });
  
      // Eğer currentDoctor yoksa currentUser.uid'yi kullan
      const doctorIdToSend = currentDoctor ? currentDoctor.id : currentUser.uid;
  
      await addDoc(collection(selectedDb, 'notifications', userId, 'userNotifications'), {
        doctorId: doctorIdToSend, // Eğer doktor değilse currentUser.uid kullanılır
        isDeleted: false,
        userMessage: fullMessage,
        notificationTitle,
        read: false,
        timestamp: new Date(),
      });
  
    } catch (error) {
      console.error('Notification sending error:', error);
      toast.error(`Failed to Send Notification! ${error.message}`);
    }
  };
  
  if (!isNotificationAllowed) {
    return null;
  }

  return (
    <div className="notif-area">
      <textarea
        className="form-control form-control-notif"
        placeholder={t('enteryourmessage')}
        value={userMessage}
        onChange={handleMessageChange}
        maxLength={MAX_CHAR_LIMIT}
        rows="12"
      />
      <div className="bottom-container">
        <div className="character-count">
          {userMessage.length}/{MAX_CHAR_LIMIT} {t('character left')}
        </div>
        <button
          className="btn btn-primary details-header-btn p-1 m-3"
          onClick={sendNotification}
          disabled={!isNotificationAllowed}
        >
          {t('sendnotif')}
        </button>
      </div>
    </div>
  );
};

export default FcmNotificationSender;
