import './App.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './pages/login/Login';
import Register from './pages/login/Register';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useContext } from 'react';
import Profile from './pages/profile/Profile';
import List from "./pages/list/List";
import { AuthContext } from './context/AuthContext';
import UserDetails from './pages/details/UserDetails';
import { UserProvider } from './context/UserContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DoctorProvider } from './context/DoctorContext';
// import Home from './pages/home/Home';
import NotFound from './NotFound';
// import { UserDetailsProvider } from './context/UserDetailsContext';
import './i18n'
import MyTeam from './components/team/MyTeam';
import { TeamProvider } from './context/TeamContext';
import Pending from './components/pending/Pending';
import MyTeamRegister from './components/team/MyTeamRegister';
import Expired from './components/team/Expired'
import ForgotPassword from './pages/login/ForgetPassword';
import { SidebarProvider } from './context/SidebarContext';
import Settings from './components/profile/settings';
import Password from './components/profile/Password';
import CheckEmail from './pages/login/CheckEmail';
import ResetPassword from './pages/login/ResetPassword';
import AddPatientModal from './components/addPatient/AddPatient';
import QRRedirect from './components/addPatient/QrRedirect';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import InviteHandler from './components/addPatient/Invite';
import Premium from './components/addPatient/Premium';
import Cost from './components/Cost/Cost';
import Token from './components/Cost/Token';
import RegisterCompany from './pages/login/RegisterCompany';
import RegisterSecret from './pages/login/RegisterSecret';
import { BusinessProvider } from './context/BusinessContext';
import Dashboard from './components/Dashboard/Dashboard';



const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
function App() {

  const { currentUser } = useContext(AuthContext);

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  };

  return (
    <Elements stripe ={stripePromise}>
    <div>
      <ToastContainer />
      <SidebarProvider>
        <DoctorProvider>
          <TeamProvider>
            <UserProvider>
            <BusinessProvider>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<RequireAuth><List /></RequireAuth>} />
                  <Route path="profile" element={<RequireAuth><Profile /></RequireAuth>} />
                  <Route path="/login" element={<Login />} />
                  <Route path="register" element={<Register />} />
                  <Route path="register-company" element={<RegisterCompany />} />
                  <Route path="register-secret" element={<RegisterSecret />} />
                  <Route path="expired" element={<Expired />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/reset-password/:oobCode" element={<ResetPassword/>} />
                  <Route path="/settings" element={<RequireAuth><Settings /></RequireAuth>} />
                  <Route path="/password" element={<Password />} />
                  <Route path="/checkemail" element={<CheckEmail/>} />
                  <Route path="MyTeamRegister" element={<RequireAuth><MyTeamRegister/></RequireAuth>} />
                  <Route path="pending" element={<RequireAuth><Pending /></RequireAuth>} />
                  <Route path="MyTeam" element={<RequireAuth><MyTeam /></RequireAuth>} />
                  <Route path="/QRRedirect" element={<QRRedirect/>} />
                  
                  <Route path="/Cost/:clientId/:userName" element={<RequireAuth><Cost/></RequireAuth>} />
                  <Route path="/token/:userId/:userName" element={<RequireAuth><Token /></RequireAuth>} />
                  <Route path="/invite" element={<RequireAuth><InviteHandler /></RequireAuth>} />
                  <Route path="/dashboard" element={<RequireAuth><Dashboard /></RequireAuth>} />
                  <Route path="/premium/:clientId/:userName" element={<RequireAuth><Premium /></RequireAuth>} />
                  <Route path="addPatients" element={<RequireAuth><AddPatientModal /></RequireAuth>} />
                  <Route path="users" element={<RequireAuth><List /></RequireAuth>} />
                  <Route path="users/:userId" element={<RequireAuth><UserDetails /></RequireAuth>} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </BrowserRouter>
              </BusinessProvider>
            </UserProvider>
          </TeamProvider>
        </DoctorProvider>
      </SidebarProvider>
      <ToastContainer />
    </div>
    </Elements>
  );
}

export default App;
