import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../context/AuthContext";
import { DoctorContext } from "../../context/DoctorContext";
import FCMPdfSender from '../../notification/FcmPdfSender';
import FcmTomogramSender from "../../notification/FcmTomogramSender";
import TomogramCommentList from "../../notification/TomogramCommentList";
import PdfCommentList from "../../notification/PdfCommentList";
import { Accordion } from "react-bootstrap";
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
const OtherMedicalDocumentsComponent = ({ user }) => {

    const { t } = useTranslation();
    const { currentUser } = useContext(AuthContext);
    const [selectedTomogramId, setSelectedTomogramId] = useState(null);
    const [selectedTomogramIds, setSelectedTomogramIds] = useState([]);
    const [selectedTomographyDatesCount, setSelectedTomographyDatesCount] = useState(0);
    const [selectedGeneralTestDatesCount, setSelectedGeneralTestDatesCount] = useState(0);
    const [selectedEcgDatesCount, setSelectedEcgDatesCount] = useState(0);
    const [selectedPdfId, setSelectedPdfId] = useState(null);
    const [selectedImagingDatesCount, setSelectedImagingDatesCount] = useState(0);
    const [showModal, setShowModal] = useState(false);
  const [activeVideoUrl, setActiveVideoUrl] = useState("");
    const { doctors } = useContext(DoctorContext);
    const doctor = doctors.find((u) => u.id === currentUser.uid);

    const renderVideos = (VideosVideo) => {
        if (!VideosVideo) {
            return <p>{t("videonotavailable")}</p>;
        }

        const sortedVideos = Object.entries(VideosVideo).sort((a, b) => a[1].timestamp - b[1].timestamp);
        return sortedVideos.map(([key, video]) => (
            <li
                className="accordion-li accordion-video"
                key={key}
                onClick={() => handleVideoClick(video.url)}
            >
                <video
                    src={video.url}
                    alt="User Tomogram"
                    className="user-tomogram-img mb-2"
                    style={{ maxHeight: "200px" }}
                />
                <p>{formatTimestamp(video.timestamp)}</p>
            </li>
        ));
    };
    const handleVideoClick = (url) => {
    
        setActiveVideoUrl(url);
        setShowModal(true);
      };
  
    return (
        <div className="medical-imaging-component mt-5">
          <Accordion>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <span className="head-accordion">{t("othermedicaldocuments")}</span>
              </Accordion.Header>
              <Accordion.Body>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>{t("tomographydocuments")}</Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        {user.TomographyPdf ? (
                          <ComparePdfItems
                            pdfData={user.TomographyPdf}
                            setSelectedPdfId={setSelectedPdfId}
                            setSelectedDatesCount={setSelectedTomographyDatesCount}
                          />
                        ) : (
                          <p style={{ textAlign: 'center' }}>{t("No Tomography PDF Available")}</p>
                        )}
                      </ul>
                      {selectedTomographyDatesCount === 1 && (
                        <>
                          <FCMPdfSender
                            token={user.deviceToken}
                            doctorId={doctor.id}
                            userId={user.id}
                            region={user.region}
                            pdfId={selectedPdfId}
                            pdfType="TomographyPdf"
                          />
                          <PdfCommentList
                            userId={user.id}
                            region={user.region}
                            pdfId={selectedPdfId}
                            pdfType="TomographyPdf"
                          />
                        </>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
  
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>{t("generaldocuments")}</Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        {user.GeneralTestPdf ? (
                          <ComparePdfItems
                            pdfData={user.GeneralTestPdf}
                            setSelectedPdfId={setSelectedPdfId}
                            setSelectedDatesCount={setSelectedGeneralTestDatesCount}
                          />
                        ) : (
                          <p style={{ textAlign: 'center' }}>{t("No General Documents Available")}</p>
                        )}
                      </ul>
                      {selectedGeneralTestDatesCount === 1 && (
                        <>
                          <FCMPdfSender
                            token={user.deviceToken}
                            doctorId={doctor.id}
                            userId={user.id}
                            region={user.region}
                            pdfId={selectedPdfId}
                            pdfType="GeneralTestPdf"
                          />
                          <PdfCommentList
                            userId={user.id}
                            region={user.region}
                            pdfId={selectedPdfId}
                            pdfType="GeneralTestPdf"
                          />
                        </>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
  
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>{t("videodocuments")}</Accordion.Header>
                    <Accordion.Body>
                      <ul className="d-flex">{renderVideos(user.VideosVideo)}</ul>
                    </Accordion.Body>
                  </Accordion.Item>
  
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>{t("ecgdocuments")}</Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        {user.ECGPdf ? (
                          <ComparePdfItems
                            pdfData={user.ECGPdf}
                            setSelectedPdfId={setSelectedPdfId}
                            setSelectedDatesCount={setSelectedEcgDatesCount}
                          />
                        ) : (
                          <p style={{ textAlign: 'center' }}>{t("No ECG Documents Available")}</p>
                        )}
                      </ul>
                      {selectedEcgDatesCount === 1 && (
                        <>
                          <FCMPdfSender
                            token={user.deviceToken}
                            doctorId={doctor.id}
                            userId={user.id}
                            region={user.region}
                            pdfId={selectedPdfId}
                            pdfType="ECGPdf"
                          />
                          <PdfCommentList
                            userId={user.id}
                            region={user.region}
                            pdfId={selectedPdfId}
                            pdfType="ECGPdf"
                          />
                        </>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <div className="mt-5">
            <Accordion>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <span className="head-accordion">{t("medicalimaging")}</span>
                </Accordion.Header>
                <Accordion.Body>
  
  
                  {user.userTomograms ? (
                    <CompareTomograms
                      tomogramData={user.userTomograms}
                      setSelectedTomogramId={setSelectedTomogramId}
                      setSelectedTomogramIds={setSelectedTomogramIds}
                      setSelectedDatesCount={setSelectedImagingDatesCount}
                    />
                  ) : (
                    <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{t("No medical image available.")}</p>
                  )}
  
                  <div className="blood-comment">
                    {selectedImagingDatesCount === 1 && <h3>{t("Comment")}</h3>}
                    <div className="fcm-area">
                      {selectedImagingDatesCount === 1 && (
                        <FcmTomogramSender
                          token={user.deviceToken}
                          doctorId={doctor.id}
                          userId={user.id}
                          region={user.region}
                          tomogramId={selectedTomogramId}
                          tomogramIds={selectedTomogramIds}
                        />
                      )}
                    </div>
                    <div className="last-comment">
                      {selectedImagingDatesCount === 1 && (
                        <TomogramCommentList
                          userId={user.id}
                          region={user.region}
                          tomogramId={selectedTomogramId}
                          tomogramIds={selectedTomogramIds}
                        />
                      )}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
  
            </Accordion>
          </div>
        </div>
      );
  };
  

const formatTimestamp = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    }
    return "Loading...";
  };
const CompareTomograms = ({ tomogramData, setSelectedTomogramIds, setSelectedDatesCount }) => {
    const { t } = useTranslation();
    const [selectedDates, setSelectedDates] = useState([]);
    const [availableDates, setAvailableDates] = useState([]);

    useEffect(() => {
      if (!tomogramData) return;
      const dates = Object.keys(tomogramData).map(id => formatTimestamp(tomogramData[id].timestamp));
      setAvailableDates([...new Set(dates)]);
    }, [tomogramData]);

    useEffect(() => {

      setSelectedDatesCount(selectedDates.length);
      const selectedIds = selectedDates.flatMap(date =>
        Object.keys(tomogramData).filter(id => formatTimestamp(tomogramData[id].timestamp) === date)
      );
      setSelectedTomogramIds(selectedIds);
    }, [selectedDates, tomogramData, setSelectedDatesCount, setSelectedTomogramIds]);

    const handleDateChange = (e) => {
      const date = e.target.value;
      if (date && !selectedDates.includes(date)) {
        setSelectedDates((prev) => [...prev, date].sort((a, b) => new Date(a) - new Date(b)));
        e.target.value = "";
      }
    };

    const removeSelectedDate = (dateToRemove) => {
      setSelectedDates((prev) => prev.filter(date => date !== dateToRemove));
    };

    return (
      <div className="compare-tomograms">
        <div className="d-flex justify-content-between align-items-center">
          <div className="selected-dates-container">
            {selectedDates.map((date) => (
              <div key={date} className="selected-date">
                {date}
                <button
                  type="button"
                  className="btn btn-sm btn-danger ms-2"
                  onClick={() => removeSelectedDate(date)}
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
          <div>
            <select className="form-select" onChange={handleDateChange} defaultValue="">
              <option value="" disabled>{t("Select date")}</option>
              {availableDates.map((date) => (
                <option key={date} value={date}>
                  {date}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="mt-4">
          {selectedDates.map((date) => (
            <div key={date}>
              {Object.keys(tomogramData).map((id) => {
                const tomogram = tomogramData[id];
                if (formatTimestamp(tomogram.timestamp) === date) {
                  return (
                    <Zoom key={id}>
                      <img
                        src={tomogram.url}
                        alt="User Tomogram"
                        className="user-tomogram-img mb-2"
                      />
                    </Zoom>
                  );
                }
                return null;
              })}
            </div>
          ))}
        </div>
      </div>
    );
  };



  const ComparePdfItems = ({ pdfData, setSelectedPdfId, setSelectedDatesCount }) => {
    const { t } = useTranslation();
    const [selectedDates, setSelectedDates] = useState([]);
    const [availableDates, setAvailableDates] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [error, setError] = useState("");
    const { currentUser } = useContext(AuthContext);
    const { doctors } = useContext(DoctorContext);
    // eslint-disable-next-line no-unused-vars
    const doctor = doctors.find((u) => u.id === currentUser.uid);


    useEffect(() => {
      if (!pdfData) return;

      const dates = Object.keys(pdfData).map(id => formatTimestamp(pdfData[id].timestamp));
      setAvailableDates([...new Set(dates)]); // Duplicate'leri kaldır
    }, [pdfData]);

    useEffect(() => {
      setSelectedDatesCount(selectedDates.length);
      if (selectedDates.length === 1) {
        const pdfId = Object.keys(pdfData).find((id) => {
          const { timestamp } = pdfData[id];
          return formatTimestamp(timestamp) === selectedDates[0];
        });
        setSelectedPdfId(pdfId);
      } else {
        setSelectedPdfId(null);
      }
    }, [selectedDates, pdfData, setSelectedDatesCount, setSelectedPdfId]);

    const handleDateChange = (e) => {
      const date = e.target.value;
      if (date && !selectedDates.includes(date)) {
        setSelectedDates((prev) => [...prev, date].sort((a, b) => new Date(a) - new Date(b)));
        e.target.value = ""; // Clear the selection
      }
    };

    const removeSelectedDate = (dateToRemove) => {
      setSelectedDates((prev) => prev.filter((date) => date !== dateToRemove));
    };
    // eslint-disable-next-line no-unused-vars
    const handleCommentSubmission = () => {
      if (selectedDates.length === 0) {
        setError(t("Please select a date"));
        return;
      }

      // Proceed with the comment submission logic
    };

    return (
      <div className="compare-pdf-items">
        <div className="d-flex justify-content-between align-items-center">
          <div className="selected-dates-container">
            {selectedDates.map((date) => (
              <div key={date} className="selected-date">
                {date}
                <button
                  type="button"
                  className="btn btn-sm btn-danger ms-2"
                  onClick={() => removeSelectedDate(date)}
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
          <div>
            <select className="form-select" onChange={handleDateChange} defaultValue="">
              <option value="" disabled>
                {t("Select date")}
              </option>
              {availableDates.map((date) => (
                <option key={date} value={date}>
                  {date}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="mt-4">
          {selectedDates.map((date) => (
            <div key={date}>
              {Object.keys(pdfData).map((id) => {
                const pdfItem = pdfData[id];
                if (formatTimestamp(pdfItem.timestamp) === date) {
                  return (
                    <li
                      key={id}
                      className="pdf-item accordion-li border border-top-0 rounded p-3 justify-content-between align-items-center"
                    >
                      <div className="d-flex align-items-center">
                        <p className="mb-0 me-3">{pdfItem.name}</p>
                      </div>
                      <div className="d-flex align-items-center justify-content-between w-100">
                        <p className="timee m-2">{formatTimestamp(pdfItem.timestamp)}</p>
                        <div className="d-flex align-items-center gap-2">
                          <a
                            style={{ backgroundColor: '#7978DE', color: '#fff' }}
                            href={pdfItem.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-primary p-2"
                          >
                            {t("view")}
                          </a>
                          <a href={pdfItem.url} download className="btn btn-primary p-2">
                            {t("download")}
                          </a>
                        </div>
                      </div>
                    </li>
                  );
                }
                return null;
              })}
            </div>
          ))}
        </div>
      </div>
    );
  };


  export default OtherMedicalDocumentsComponent;