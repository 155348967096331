// src/components/NavbarMenu.jsx

import React, { useContext, useEffect, useState } from "react";
import { Navbar, Nav,Button } from "react-bootstrap";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth, db } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";
import logo from "../../images/newlogo.svg";
import { DoctorContext } from "../../context/DoctorContext";
import { UserContext } from "../../context/UserContext";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import deFlag from "../../images/icons/Germany.svg";
import usFlag from "../../images/icons/United States.svg";
import trFlag from "../../images/icons/Türkiye.svg";
import './navbarMenu.css';
import { TeamContext } from "../../context/TeamContext";
import patients from '../../images/icons/Heart Pulse 2.svg';
import team from '../../images/icons/Stethoscope.svg';
import logout from '../../images/icons/Button.png';
import avatar from '../../images/avatar.png';
import document from '../../images/icons/Document.svg';
import shielduser from '../../images/icons/Shield User.svg';
import shieldwarning from '../../images/icons/Shield Warning.svg';
import { SidebarContext } from "../../context/SidebarContext";
import settings from '../../images/icons/Settings.svg';
import { BusinessContext } from "../../context/BusinessContext"; 
import dashboard from '../../images/dashboardicon.svg'
import credits from '../../images/Group 66.svg'
import { doc, getDoc } from "firebase/firestore";
function NavbarMenu() {
  const { currentUser } = useContext(AuthContext);
  const { t, i18n } = useTranslation();
  const { doctors } = useContext(DoctorContext);
  const { teams } = useContext(TeamContext);
  const { businesses } = useContext(BusinessContext); // Access businesses
  const [loggedInUserData, setLoggedInUserData] = useState(null);
  const [currentFlagUrl, setCurrentFlagUrl] = useState(usFlag);
  const [currentLangName, setCurrentLangName] = useState("English");
  const location = useLocation();
  const [tokenMonthly, setTokenMonthly] = useState(0); 
  const [tokenYearly, setTokenYearly] = useState(0);
  const [selectedLink, setSelectedLink] = useState(location.pathname);
  const { isSidebarCollapsed, toggleSidebar } = useContext(SidebarContext);
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const isPremiumPage = location.pathname === "/premium";

  const isBusiness = loggedInUserData?.role === "business";

  useEffect(() => {
    if (currentUser && currentUser.uid) {
      const fetchUserData = async () => {
        try {
          // Kullanıcının `doctors` ve `businesses` koleksiyonlarındaki verilerini kontrol et
          let userDoc;

          // Öncelikle doctors koleksiyonunu kontrol ediyoruz
          userDoc = await getDoc(doc(db, "doctors", currentUser.uid));

          if (!userDoc.exists()) {
            // Eğer `doctors` koleksiyonunda yoksa `businesses` koleksiyonunu kontrol et
            userDoc = await getDoc(doc(db, "business", currentUser.uid));
          }

          if (userDoc.exists()) {
            const userData = userDoc.data();
            setLoggedInUserData(userData);

            // `tokenMonthly` ve `tokenYearly` değerlerini Firestore'dan al
            setTokenMonthly(userData.tokenMonthly || 0); // Eğer tokenMonthly yoksa 0 yap
            setTokenYearly(userData.tokenYearly || 0);   // Eğer tokenYearly yoksa 0 yap
          }
        } catch (error) {
          console.error("Error fetching user data: ", error);
        }
      };

      fetchUserData();
    }
  }, [currentUser]);
  
  const handleSelect = (link) => {
    setSelectedLink(link);
  };

  useEffect(() => {
    setSelectedLink(location.pathname);
  }, [location]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('appLanguage', lng);
    setCurrentFlagUrl(getCurrentFlag(lng));
    setCurrentLangName(getCurrentLangName(lng));
  };
  const toggleDropdown = () => {
    if (isSidebarCollapsed) {
      // Eğer sidebar kapalıysa, dropdown açılmadan direkt token sayfasına git
      const userId = currentUser?.uid;
      const userName = loggedInUserData?.Name || 'User'; 
      if (userId && userName) {
        navigate(`/cost/${userId}/${userName}`);
      }
    } else {
      setIsDropdownOpen(!isDropdownOpen); // Sidebar açıksa, dropdown aç/kapa
    }
  };
  const getCurrentFlag = (lang) => {
    switch (lang) {
      case "en":
        return usFlag;
      case "tr":
        return trFlag;
      case "de":
        return deFlag; 
      default:
        return usFlag;
    }
  };

  const getCurrentLangName = (lang) => {
    switch (lang) {
      case "en":
        return "English";
      case "tr":
        return "Türkçe";
      case "de":
        return "Deutsch";
      default:
        return "English";
    }
  };

  useEffect(() => {
    const savedLang = localStorage.getItem('appLanguage') || 'en';
    i18n.changeLanguage(savedLang);
    setCurrentFlagUrl(getCurrentFlag(savedLang));
    setCurrentLangName(getCurrentLangName(savedLang));
  }, [i18n, teams]);

  const { logout: logoutAuthContext } = useContext(AuthContext);
  const { logout: logoutUserContext } = useContext(UserContext);

  useEffect(() => {
    if (currentUser && currentUser.uid) {
      // Search in doctors
      let user = doctors.find((u) => u.id === currentUser.uid);
      
      // If not found in doctors, search in teams
      if (!user) {
        user = teams.find((u) => u.id === currentUser.uid);
      }
  
      // If not found in teams, search in businesses
      if (!user) {
        user = businesses.find((u) => u.id === currentUser.uid);
      }
  
      // Set the user data if found
      if (user) {
        setLoggedInUserData(user);
      } else {
        setLoggedInUserData(null); // Optionally handle users not found in any collection
      }
    }
  }, [doctors, currentUser, teams, businesses]);

  
  const handleLogout = async () => {
    try {
      await signOut(auth);
      logoutAuthContext();
      logoutUserContext();
      navigate("/login");
    } catch (error) {
      console.error("Logout Error:", error);
    }
  };
  const handleBuyCreditsClick = () => {
    const userId = currentUser?.uid;  // Extracting userId from currentUser
    const userName = loggedInUserData?.Name || 'User';  // Fallback if Name is missing
    if (userId && userName) {
      navigate(`/cost/${userId}/${userName}`);
    }
  };
  const handleDropdownToggle = () => {
    // Navigasyon kontrolü (mobilde yönlendirme)
    if (window.innerWidth <= 768) {
      const userId = currentUser?.uid;
      const userName = currentUser?.displayName || 'User';
      if (userId && userName) {
        navigate(`/cost/${userId}/${userName}`);
      }
    }
  };

  const privacyPolicyLinks = () => {
    if (currentLangName === "Türkçe") {
      return (
        <div className="mt-4 sheet">
          <a
            style={{ color: '#677184', fontSize: '16px' }}
            className="text-decoration-none p-2 d-flex align-items-center gizli"
            href="https://realhealth.solutions/web-documents/PrivacyPolicyTR.pdf"
          >
            <img src={shielduser} alt="Privacy Policy Icon" className="me-2" width="25" height="25" />
            <span>{t('Privacy Policy')}</span>
          </a>
          <a
            style={{ color: '#677184', fontSize: '16px' }}
            className="text-decoration-none p-2 d-flex align-items-center gizli"
            href="https://realhealth.solutions/web-documents/HealthScoreTR.pdf"
          >
            <img src={shieldwarning} alt="Disclaimer Icon" className="me-2" width="25" height="25" />
            <span>{t('Disclaimer')}</span>
          </a>
          <a
            style={{ color: '#677184', fontSize: '16px' }}
            className="text-decoration-none p-2 d-flex align-items-center gizli"
            href="https://realhealth.solutions/web-documents/UserAgreementTR.pdf"
          >
            <img src={document} alt="User Agreement Icon" className="me-2" width="25" height="25" />
            <span>{t('User Agreement')}</span>
          </a>
          <a
            style={{ color: '#677184', fontSize: '16px' }}
            className="text-decoration-none p-2 d-flex align-items-center"
            href="/settings"
          >
            <img src={settings} alt="Settings" className="me-2" width="25" height="25" />
            <span>{t('Settings')}</span>
          </a>
        </div>
      );
    } else {
      return (
        <div className="mt-4 sheet">
          <a
            style={{ color: '#677184', fontSize: '16px' }}
            className="text-decoration-none p-2 d-flex align-items-center gizli"
            href="https://realhealth.solutions/web-documents/PrivacyPolicyEN.pdf"
          >
            <img src={shielduser} alt="Privacy Policy Icon" className="me-2" width="25" height="25" />
            <span>{t('Privacy Policy')}</span>
          </a>
          <a
            style={{ color: '#677184', fontSize: '16px'}}
            className="text-decoration-none p-2 d-flex align-items-center gizli"
            href="https://realhealth.solutions/web-documents/HealthScoreEN.pdf"
          >
            <img src={shieldwarning} alt="Disclaimer Icon" className="me-2" width="25" height="25" />
            <span>{t('Disclaimer')}</span>
          </a>
          <a
            style={{ color: '#677184', fontSize: '16px' }}
            className="text-decoration-none p-2 d-flex align-items-center gizli"
            href="https://realhealth.solutions/web-documents/UserAgreementEN.pdf"
          >
            <img src={document} alt="User Agreement Icon" className="me-2" width="25" height="25" />
            <span>{t('User Agreement')}</span>
          </a>
          <a
            style={{ color: '#677184', fontSize: '16px' }}
            className="text-decoration-none p-2 d-flex align-items-center gizli"
            href="/settings"
          >
            <img src={settings} alt="Settings" className="me-2" width="25" height="25" />
            <span>{t('Settings')}</span>
          </a>
        </div>
      );
    }
  };

  return (
    <div className={`sidebar d-flex flex-column vh-100 bg-light ${isSidebarCollapsed ? 'collapsed' : ''}`}>
      {!isPremiumPage && (
        <button onClick={toggleSidebar} className="toggle-button">
          {isSidebarCollapsed ? '>' : '<'}
        </button>
      )}
      <Navbar.Brand as={Link} to="/" className="mb-3">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={logo} className="w-32 h-32 mt-2" style={{ margin: '10px', marginTop: '0px' }} alt="Logo" />
          <span className="gizli" style={{ fontFamily: 'Poppins', color: '#7978DE', fontSize: '24px', width: '131px', height: '36px' }}>
            RealHealth
          </span>
        </div>
      </Navbar.Brand>
      <Nav className="flex-column">
        {/* Existing Nav Links */}
        <Nav.Link
          as={Link}
          to="/users"
          className={`navLink mb-2 ${selectedLink === '/users' ? 'selected' : ''}`}
          onClick={() => handleSelect('/users')}
        >
          <img
            src={patients}
            style={{ width: '25px', height: '25px' }}
            alt="Patients"
            className={`logo ${selectedLink === '/users' ? 'active-logo' : 'inactive-logo'}`}
          />
          <span
            style={{
              fontFamily: 'Poppins',
              fontSize: '20px',
              lineHeight: '24px',
            }}
            className={`p-3 ${selectedLink === '/users' ? 'active-text' : 'inactive-text'}`}
          >
            {t('Users')}
          </span>
        </Nav.Link>
        <Nav.Link
          as={Link}
          to="/myTeam"
          className={`navLink mb-2 ${selectedLink === '/myTeam' ? 'selected' : ''}`}
          onClick={() => handleSelect('/myTeam')}
        >
          <img
            src={team}
            style={{ width: '25px', height: '25px' }}
            alt="Team"
            className={`logo ${selectedLink === '/myTeam' ? 'active-logo' : 'inactive-logo'}`}
          />
          <span
            style={{
              fontFamily: 'Poppins',
              fontSize: '20px',
              lineHeight: '24px',
            }}
            className={`p-3 ${selectedLink === '/myTeam' ? 'active-text' : 'inactive-text'}`}
          >
            {t('My Team')}
          </span>
        </Nav.Link>

        {/* Conditionally Render Dashboard for Business Users */}
        {/* {isBusiness && (
          <Nav.Link
            as={Link}
            to="/dashboard"
            className={`navLink mb-2 ${selectedLink === '/dashboard' ? 'selected' : ''}`}
            onClick={() => handleSelect('/dashboard')}
          >
            <img
              src={dashboard}
              style={{ width: '25px', height: '25px' }}
              alt="Dashboard"
              className={`logo ${selectedLink === '/dashboard' ? 'active-logo' : 'inactive-logo'}`}
            />
            <span
              style={{
                fontFamily: 'Poppins',
                fontSize: '20px',
                lineHeight: '24px',
              }}
              className={`p-3 ${selectedLink === '/dashboard' ? 'active-text' : 'inactive-text'}`}
            >
              {t('Dashboard')}
            </span>
          </Nav.Link>
          
        )} */}
     <Dropdown show={isDropdownOpen} onToggle={toggleDropdown}>
        <Dropdown.Toggle as={Nav.Link} onClick={toggleDropdown}>
          <img
            src={credits}
            style={{ width: '25px', height: '25px' }}
            alt="Token"
            className="logo"
          />
          <span
            style={{
              fontFamily: 'Poppins',
              fontSize: '20px',
              lineHeight: '24px',
              color:'#677184'
            }}
            className="p-3"
          >
            {t("Credits")}
          </span>
        </Dropdown.Toggle>

        {isDropdownOpen && !isSidebarCollapsed && (
          <Dropdown.Menu className="token-dropdown">
            <div className="token-card">
          
              <p className="token-text">{t("Remaining Credits")}</p>
              <p className="token-subtext">{t("Monthly")}: {tokenMonthly} {t("Credits left")}</p>
              <p className="token-subtext">{t("Yearly")}: {tokenYearly} {t("Credits left")}</p>
              <Button className="buy-credits-btn" onClick={handleBuyCreditsClick}>
                {t("Buy Credits")}
              </Button>
            </div>
          </Dropdown.Menu>
        )}
      </Dropdown>



      </Nav>
      <div className="mt-auto mb-3 language">
        <div className="mb-3">
          <Dropdown>
            <Dropdown.Toggle
              style={{ backgroundColor: "transparent", border: "none" }}
              variant="success"
              id="dropdown-basic"
              className="btn-info d-flex align-items-center p-0"
            >
              <img
                src={currentFlagUrl}
                alt="Current Language"
                width="24"
                height="24"
                className="me-2 rounded-flag m-2"
              />
              <span style={{ color: '#677184' }}>{currentLangName}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => changeLanguage("en")}>
                <img src={usFlag} alt="English" width="30" height="20" className="me-2" /> 
                <span className={isSidebarCollapsed || window.innerWidth <= 768 ? 'hide-text' : ''}>
                  English
                </span>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => changeLanguage("tr")}>
                <img src={trFlag} alt="Türkçe" width="30" height="20" className="me-2" /> 
                <span className={isSidebarCollapsed || window.innerWidth <= 768 ? 'hide-text' : ''}>
                  Türkçe
                </span>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => changeLanguage("de")}>
                <img src={deFlag} alt="Deutsch" width="30" height="20" className="me-2" /> 
                <span className={isSidebarCollapsed || window.innerWidth <= 768 ? 'hide-text' : ''}>
                  Deutsch
                </span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {privacyPolicyLinks()}
        {loggedInUserData && (
          <div
            className="user-info d-flex align-items-center"
            style={{ borderTop: '1px solid #ccc', paddingTop: '15px', marginTop: '20px', maxWidth: '100%' }}
          >
            <Link to="/profile" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit', flex: '1' }}>
              <img
                src={loggedInUserData.ProfileImage || avatar}
                alt="Profile"
                className="profile-image"
                style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px' }}
              />
              <div className="user-info text-left ms-2" style={{ maxWidth: 'calc(100% - 80px)', flex: '1 1 auto', wordBreak: 'break-word' }}>
                <div style={{ fontSize: '15px', lineHeight: '20px', marginBottom: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {loggedInUserData.Name} {loggedInUserData.Surname} 
                </div>
                <div className="text-muted gizli" style={{ fontSize: '15px', lineHeight: '20px' }}>
                  {loggedInUserData.email}
                </div>
              </div>
            </Link>
            <div className="ms-auto logout-btn" style={{ flexShrink: '0' }}>
              <img
                src={logout}
                alt="Logout Icon"
                className="logout-icon"
                onClick={handleLogout}
                style={{ width: '35px', height: '35px', cursor: 'pointer' }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default NavbarMenu;
