import React, { useContext, useEffect, useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarMenu from '../navbar/NavbarMenu';
import { useTranslation } from 'react-i18next';
import { SidebarContext } from '../../context/SidebarContext';
import { UserContext } from '../../context/UserContext';
import { CircularProgress } from '@mui/material';
import pay from '../../images/pay.svg';
import './Premium.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
// eslint-disable-next-line
import { db, db2, db3, db4, db5, db6, db7, db8, db9 } from "../../firebase";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getFirestore, doc, updateDoc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import i18n from '../../i18n';
import { AuthContext } from '../../context/AuthContext';

const stripePromise = loadStripe('pk_live_51QECqdG6LBnU5s9ShtNmlMUfa70KN5dYEoXagL2iJjwKh9NYhkRdqxdHffjggitwUEKSSYCTFy935iNQbVJa9avr00GAOew2se');

const Premium = () => {
  const { setIsSidebarCollapsed } = useContext(SidebarContext);
 
  const { loading } = useContext(UserContext);
  const { t } = useTranslation();
  const { userName, clientId } = useParams();
  const location = useLocation();
  const [planType, setPlanType] = useState('');
  const [amount, setAmount] = useState('');
  const [patients, setPatients] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState('Monthly');
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [paymentError, setPaymentError] = useState(null);
  const [paymentSucceeded, setPaymentSucceeded] = useState(false);
  const [isCorporate, setIsCorporate] = useState(false); // Yeni ekleme: Kurumsal alım durumu
  const [taxId, setTaxId] = useState('');
  useEffect(() => {
    setIsSidebarCollapsed(true);
  
    // URL parametrelerinden planType ve patients değerlerini al
    const params = new URLSearchParams(location.search);
    const plan = params.get('plan') || 'Monthly'; // Eğer plan URL'de yoksa varsayılan olarak Monthly seçiyoruz
    const patientsCount = parseInt(params.get('patients'), 10) || 0;
  
    setPlanType(plan);
    setSelectedPlan(plan); // URL'den gelen plan'ı selectedPlan olarak ayarlıyoruz
    setPatients(patientsCount);
  
    // Aylık ve yıllık fiyat hesaplaması
    const calculatedAmount = plan === 'Monthly' ? patientsCount * 1 : patientsCount * 89.99;
    setAmount(calculatedAmount.toFixed(2)); // Fiyatı 2 ondalık basamağa yuvarla
  }, [setIsSidebarCollapsed, location]);
  const translatedPlanType = planType === 'Monthly' ? t('Monthly') : t('Yearly');
  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
  };

  return (
    <Elements stripe={stripePromise}>
      <div>
        <NavbarMenu />
        {loading ? (
          <div className="d-flex align-items-start justify-content-start vh-100">
            <CircularProgress />
          </div>
        ) : (
          
          <PremiumPaymentForm
            selectedPlan={selectedPlan}
            handlePlanSelect={handlePlanSelect}
            t={t}
            userName={userName}
            clientId={clientId}
            loadingPayment={loadingPayment}
            setLoadingPayment={setLoadingPayment}
            paymentError={paymentError}
            setPaymentError={setPaymentError}
            paymentSucceeded={paymentSucceeded}
            setPaymentSucceeded={setPaymentSucceeded}
            planType={translatedPlanType}
            amount={amount}
            patients={patients}
            isCorporate={isCorporate} // Yeni ekleme: Kurumsal alım bilgisi
            setIsCorporate={setIsCorporate} // Yeni ekleme: Kurumsal alım durumu ayarlayıcı
            taxId={taxId} // Yeni ekleme: VAT/ID numarası 
            setTaxId={setTaxId}
          />
           
        )}
      </div>
    </Elements>
  );
};

const PremiumPaymentForm = ({
  selectedPlan,
  handlePlanSelect,
  t,
  userName,
  clientId,
  loadingPayment,
  setLoadingPayment,
  paymentError,
  setPaymentError,
  paymentSucceeded,
  setPaymentSucceeded,
  planType,
  amount,
  patients,
  isCorporate, // Yeni ekleme: Kurumsal alım bilgisi
  setIsCorporate, // Yeni ekleme: Kurumsal alım durumu ayarlayıcı
  taxId, // Yeni ekleme: VAT/ID numarası
  setTaxId 
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const db = getFirestore();
  const auth = getAuth();
  const navigate = useNavigate();

  const [cardholderName, setCardholderName] = useState('');
  const [cardholderEmail, setCardholderEmail] = useState('');
  const [line1, setLine1] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  
  const countryOptions = [
    { code: 'TR', name: 'Turkey' },
    { code: 'EN', name: 'England' },
    { code: 'GB', name: 'United Kingdom' },
    { code: 'US', name: 'United States' },
    { code: 'FR', name: 'France' },
    { code: 'DE', name: 'Germany' },
    { code: 'IT', name: 'Italy' },
    { code: 'ES', name: 'Spain' },
    { code: 'NL', name: 'Netherlands' },
    { code: 'CA', name: 'Canada' },
    { code: 'AU', name: 'Australia' },
    { code: 'JP', name: 'Japan' },
    { code: 'CN', name: 'China' },
    { code: 'IN', name: 'India' },
    { code: 'BR', name: 'Brazil' },
    { code: 'RU', name: 'Russia' },
    // Diğer ülke kodlarını buraya ekleyin...
  ];
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoadingPayment(true);
    setPaymentError(null);
  
    // Örneğin Almanya EU VAT örneği için (DE123456789 gibi)
    const euVatFormat = /^[A-Z]{2}[0-9]{8,12}$/;
  
    const cardElement = elements.getElement(CardElement);
    const { error: cardError } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: cardholderName,
        email: cardholderEmail,
        address: {
          line1,
          postal_code: postalCode,
          city,
          state,
          country,
        },
       
      },
    });
  
    if (cardError) {
      setPaymentError(cardError.message);
      setLoadingPayment(false);
      return;
    }
  
    const currency = 'usd';
    const numericAmount = Math.round(parseFloat(amount) * 100);
  
    if (isNaN(numericAmount)) {
      setPaymentError('Invalid amount. Please check the payment details.');
      setLoadingPayment(false);
      return;
    }
  
    try {
      const bodyData = {
        amount: numericAmount,
        currency,
        receiptEmail: cardholderEmail,
        address: { line1, postalCode, city, state, country },
        isCorporate,
        vatNumber: taxId,
      };
  
      if (isCorporate && euVatFormat.test(taxId)) {
        bodyData.vatNumber = taxId; // Yeni ekleme: VAT numarası
      }
      const response = await fetch('https://us-central1-realworks-health.cloudfunctions.net/createPaymentIntent', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(bodyData),
      });
  
      const data = await response.json();
      if (response.status !== 200) throw new Error(data.error);
  
      const result = await stripe.confirmCardPayment(data.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: cardholderName,
            email: cardholderEmail,
          },
        },
      });
  
      if (result.error) {
        setPaymentError(result.error.message);
      } else if (result.paymentIntent.status === 'succeeded') {
        setPaymentSucceeded(true);
        toast.success('Payment succeeded! Receipt sent to your email.', { position: 'top-right', autoClose: 5000 });
        navigate('/users');
      }
    } catch (error) {
      setPaymentError(error.message);
      console.error('Error during payment process:', error);
    }
  
    setLoadingPayment(false);
  };
  
  return (
    <>
      <div className="payment-container my-4">
        <div className="border-bottom">
          <h1 className="payment-title">{t('Payment')}</h1>
        </div>
        <div className="row g-0" style={{ marginTop: '20px' }}>
          <div className="col-lg-7 col-12 mb-4 p-0">
            <div className="payment-img">
              <img src={pay} alt="Payment" />
            </div>
          </div>
          <div className="col-lg-5 col-12 premium-content">
          <h2>
  {t("purchaseCredits", { count: patients, plan: planType, price: amount })}
</h2>
        <Form onSubmit={handleSubmit} style={{ marginTop: '10px' }}>
        <Form.Group controlId="isCorporate" className="mb-3">
                <Form.Check 
                  type="checkbox"
                  label={t('Corporate Purchase')}
                  checked={isCorporate}
                  onChange={(e) => setIsCorporate(e.target.checked)}
                />
              </Form.Group>

              <Form.Group controlId="taxId" className="mb-3">
                <Form.Label>{isCorporate ? t('VAT Number') : t('ID Number')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={isCorporate ? t('Enter your VAT Number') : t('Enter your ID Number')}
                  required={isCorporate}
                  value={taxId}
                  onChange={(e) => setTaxId(e.target.value)}
                />
              </Form.Group>
          <Form.Group controlId="name" className="mb-3">
            <Form.Label>{t('Card Holder')}</Form.Label>
            <Form.Control
            size='sm'
              type="text"
              placeholder={t('Enter your name')}
              required
              value={cardholderName}
              onChange={(e) => setCardholderName(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="email" className="mb-3">
            <Form.Label>{t('E-Mail')}</Form.Label>
            <Form.Control
              type="email"
              placeholder={t('Enter your email')}
              required
              value={cardholderEmail}
              onChange={(e) => setCardholderEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="line1" className="mb-3">
            <Form.Label>{t('Street Address')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('Enter your street address')}
              required
              value={line1}
              onChange={(e) => setLine1(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="postalCode" className="mb-3">
            <Form.Label>{t('Postal Code')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('Enter your postal code')}
              required
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="city" className="mb-3">
            <Form.Label>{t('City')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('Enter your city')}
              required
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="state" className="mb-3">
            <Form.Label>{t('State')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('Enter your state')}
              required
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="country" className="mb-3">
                <Form.Label>{t('Country')}</Form.Label>
                <Form.Select
                  required
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                >
                  {countryOptions.map((option) => (
                    <option key={option.code} value={option.code}>
                      {option.name} ({option.code})
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

          {/* <Form.Group controlId="taxId" className="mb-3">
            <Form.Label>{t('ID Number')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('Enter your ID Number')}
              required
              value={taxId}
              onChange={(e) => setTaxId(e.target.value)}
            />
          </Form.Group> */}

          <Form.Group controlId="cardInformation" className="mb-3">
            <Form.Label>{t('Card Information')}</Form.Label>
            <div style={{ border: '1px solid #ced4da', padding: '10px', borderRadius: '4px' }}>
              <CardElement options={{ hidePostalCode: true }} />
            </div>
          </Form.Group>

          <Button variant="dark" type="submit" disabled={loadingPayment} style={{ marginTop: '20px', width: '100%' }}>
            {loadingPayment ? t('Processing...') : t('Pay')}
          </Button>

          {paymentError && <div style={{ color: 'red', marginTop: '10px' }}>{paymentError}</div>}
        </Form>
      </div>
      </div>
      </div>
      <ToastContainer />
    </>
  );
};
export default Premium;
