import React from "react";
import FcmNotificationSender from "../../notification/FcmNotificationSender";
import { useTranslation } from 'react-i18next';

const AboutComponent = ({
    riskScoreNeurology,
    riskScoreKardio,
    riskScoreObezite,
    riskScoreHiperTansiyon,
    riskScoreDiabet,
    user,
    doctor,
    business,
    currentDoctor
}) => {
    const { t } = useTranslation();
    const userType = doctor || business; // Dynamically choose doctor or business

    return (
        <>
            <div className="row risk-section justify-content-between mb-3">
                <div className="col-sm-2">
                    <div className="button-risk">
                        <p className="risk-text">{t("Nörolojik Risk")}</p>
                        <span className="risk-subtext">{riskScoreNeurology}</span>
                    </div>
                </div>
                <div className="col-sm-2">
                    <div className="button-risk">
                        <p className="risk-text">{t("Kardiyovasküler Risk")}</p>
                        <span className="risk-subtext">{riskScoreKardio}</span>
                    </div>
                </div>
                <div className="col-sm-2">
                    <div className="button-risk">
                        <p className="risk-text">{t("Obezite Riski")}</p>
                        <span className="risk-subtext">{riskScoreObezite}</span>
                    </div>
                </div>
                <div className="col-sm-2">
                    <div className="button-risk">
                        <p className="risk-text">{t("Tansiyon Riski")}</p>
                        <span className="risk-subtext">{riskScoreHiperTansiyon}</span>
                    </div>
                </div>
                <div className="col-sm-2">
                    <div className="button-risk">
                        <p className="risk-text">{t("Diabet Riski")}</p>
                        <span className="risk-subtext">{riskScoreDiabet}</span>
                    </div>
                </div>
            </div>
            <div>
                <div className="patient-details mt-5">
                    <h3>{t("User Details")}</h3>
                    <p className="description">{t("View detailed information about your patients")}</p>
                    <div className="details">
                        <div className="detail-item">
                            <strong className="strong">{t("birthday")}</strong>
                            <span>{user.birthday}</span>
                        </div>
                        <div className="detail-item">
                            <strong className="strong">{t("gender")}</strong>
                            <span className="spa">{t(user.genderType)}</span>
                        </div>
                        <div className="detail-item">
                            <strong className="strong">{t("height")}</strong>
                            <span className="spa">{user.height}</span>
                        </div>
                        <div className="detail-item">
                            <strong className="strong">{t("weight")}</strong>
                            <span className="spa">{parseFloat(user.weight).toFixed(1)} kg</span>
                        </div>
                        <div className="detail-item notification-item">
                            <strong className="strong">{t("Notification")}</strong>
                            <div className="textarea-container">
                                <FcmNotificationSender
                                    token={user.deviceToken}
                                    userId={user.id}
                                    doctorId={currentDoctor ? currentDoctor.id : undefined}// Dynamically pass doctor or business ID
                                    region={user.region}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutComponent;
