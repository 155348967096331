import React, { useContext, useState, useEffect } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth,db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./login.css";
import logo from "../../images/icons/Content.svg";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import deFlag from "../../images/de.png";
import usFlag from "../../images/eng.svg";
import trFlag from "../../images/turkey.svg";
import { doc, updateDoc, arrayUnion } from "firebase/firestore"

function Login() {
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);
  const [currentFlagUrl, setCurrentFlagUrl] = useState(usFlag);
  const isFormValid = email !== "" && password !== "";
  
  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      dispatch({ type: "LOGIN", payload: user });

      const clientId = localStorage.getItem('clientId');
      if (clientId) {
        try {
          const doctorRef = doc(db, 'doctors', user.uid);
          await updateDoc(doctorRef, {
            clients: arrayUnion(clientId)
          });
          localStorage.removeItem('clientId');
          toast.success(t('Patient added successfully.'));
        } catch (error) {
          console.error('Hasta ekleme hatası:', error);
          toast.error(t('Error adding patient.'));
        }
      }

      const redirectUrl = localStorage.getItem('redirectAfterLogin');
      if (redirectUrl) {
        localStorage.removeItem('redirectAfterLogin');
        navigate(redirectUrl);
      } else {
        navigate("/");
      }
    } catch (error) {
      setError(true);
      toast.error(t("Login failed. Please check your email and password."));
    }
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("appLanguage", lng);
    setCurrentFlagUrl(getCurrentFlag(lng));
  };

  const getCurrentFlag = (lang) => {
    switch (lang) {
      case "en":
        return usFlag;
      case "tr":
        return trFlag;
      case "de":
        return deFlag;
      default:
        return usFlag;
    }
  };

  useEffect(() => {
    const savedLang = localStorage.getItem("appLanguage") || "en";
    i18n.changeLanguage(savedLang);
    setCurrentFlagUrl(getCurrentFlag(savedLang));
  }, [i18n]);

  return (
    <div className="login">
      <div className="login-container">
        <div className="login-left">
          <div className="login-header">
            <img src={logo} alt="Realhealth Logo" className="logo-img" />
            <span className="logo-title">Realhealth</span>
          </div>
          <h2 className="login-title">{t("Log in to your account")}</h2>
          <p className="login-subtitle">{t("Welcome back! Please enter your details.")}</p>
          <form className="login-form" onSubmit={handleLogin}>
          <label htmlFor="email" className="input-label">{t("Email")}</label>
            <div>
            
              <input
                type="email"
                placeholder={t("Enter your email")}
                className={`form-input ${email ? "filled" : ""}`}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onFocus={(e) => e.target.classList.add('focused')}
                onBlur={(e) => {
                  if (!e.target.value) {
                    e.target.classList.remove('focused');
                  }
                }}
              />
            </div>
            <label htmlFor="email" className="input-label">{t("Passwowrd")}</label>
            <div>
             
              <input
                type="password"
                placeholder={t("Password")}
                className={`form-input ${password ? "filled" : ""}`}
              
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onFocus={(e) => e.target.classList.add('focused')}
                onBlur={(e) => {
                  if (!e.target.value) {
                    e.target.classList.remove('focused');
                  }
                }}
              />
            </div>
            <div className="login-actions">
            
              <a href="/forgot-password" className="forgot-password">{t("Forgot password?")}</a>
            </div>
            <button type="submit" className={`form-button ${isFormValid ? "active" : ""}`} disabled={!isFormValid}>
              {t("Sign in")}
            </button>
          </form>
          <p className="sign-up">
            {t("Don't have an account?")} <a href="/register">{t("Sign up")}</a>
          </p>
          <Dropdown className="language-dropdown">
            <Dropdown.Toggle
              style={{ backgroundColor: "transparent", border: "none" }}
              variant="success"
              id="dropdown-basic"
              className="btn-info"
            >
              <img
                src={currentFlagUrl}
                alt="Current Language"
                width="30"
                height="20"
              />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => changeLanguage("en")}>
                <img src={usFlag} alt="English" width="30" height="20" />
                English
              </Dropdown.Item>
              <Dropdown.Item onClick={() => changeLanguage("tr")}>
                <img src={trFlag} alt="Türkçe" width="30" height="20" />
                Türkçe
              </Dropdown.Item>
              <Dropdown.Item onClick={() => changeLanguage("de")}>
                <img src={deFlag} alt="Deutsch" width="30" height="20" />
                Deutsch
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="login-right">
          {/* Add background image or styling here if needed */}
        </div>
      </div>
    </div>
  );
}

export default Login;
