const healthDatade = {
    healthkit: {
      healthScore: "Gesundheitswert",
      activityEnergy: "Aktivitätsenergie",
      bloodPressure: "Blutdruck",
      bodyFatPercente: "Körperfettanteil",
      bodyMassIndex: "Body-Mass-Index",
      cardioRecovery: "Kardio-Recovery",
      exerciseTime: "Trainingszeit",
      flightsClimbed: "Gestiegene Flüge",
      heartRate: "Herzfrequenz",
      heartRateVeriability: "Herzfrequenzvariabilität",
      leanBodyMass: "Magerkörpermasse",
      oxygenSaturation: "Sauerstoffsättigung",
      respiratoryRate: "Atemfrequenz",
      restingEnergy: "Ruheenergie",
      restingHeartRate: "Ruheherzfrequenz",
      standHours: "Stunden im Stehen",
      steps: "Schritte",
      vo2Max: "VO2 Max",
      walkingDistance: "Gehdistanz",
      walkingHeartRate: "Gehherzfrequenz"
    },
    units: {
      vo2Max: "mL/kg/min",
      heartRate: "Schläge/min",
      oxygenSaturation: "%",
      standHours: "Stunden",
      heartRateVeriability: "ms",
      walkingDistance: "Meter",
      steps: "Schritte",
      walkingHeartRate: "Schläge/min",
      respiratoryRate: "Atemzüge/min",
      restingHeartRate: "Schläge/min",
      bloodPressure: "mmHg",
      restingEnergy: "kcal",
      flightsClimbed: "Stockwerke",
      exerciseTime: "Minuten",
      activityEnergy: "kcal",
      cardioRecovery: "kcal"
    },
    categories: {
        'General Health Metrics': 'Allgemeine Gesundheitsmetriken',
        'Exercise and Activity': 'Übung und Aktivität',
        'Heart and Respiratory Metrics': 'Herz- und Atemmetriken',
        'Body Composition': 'Körperzusammensetzung',
        'Metabolic Metrics': 'Stoffwechselmetriken'
      }
  };
  export default healthDatade;