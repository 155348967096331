import React, { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { DoctorContext } from '../context/DoctorContext';
import { db, db2, db3, db4, db5, db6, db7, db8,db9 } from '../firebase'; // Import the Firestore instances
import { doc, updateDoc, getDoc,addDoc,collection } from 'firebase/firestore';
import { TeamContext } from '../context/TeamContext';
import { AuthContext } from "../context/AuthContext";
import './FcmBloodSender.css';
import { Timestamp } from 'firebase/firestore';
const MAX_CHAR_LIMIT = 200;

const FcmTomogramSender = ({ token, doctorId, userId, region, tomogramIds }) => {
  const { t } = useTranslation();
  const [userMessage, setUserMessage] = useState('');
  const [isNotificationAllowed, setIsNotificationAllowed] = useState(true);
  const { doctors } = useContext(DoctorContext);
  const { teams } = useContext(TeamContext);
  const { currentUser } = useContext(AuthContext);
  const doctor = doctors.find((u) => u.id === currentUser.uid);
  const currentTeamMember = teams.find((team) => team.id === currentUser.uid);
  const currentDoctor = doctors.find((doc) => doc.id === (currentTeamMember?.doctorId || currentUser.uid));

  useEffect(() => {
    let isBlocked = false;

    if (currentDoctor?.notificationOf?.includes(userId)) {
      isBlocked = true;
    }

    if (currentTeamMember?.notificationOf?.includes(userId)) {
      isBlocked = true;
    }

    setIsNotificationAllowed(!isBlocked);
  }, [currentDoctor, currentTeamMember, userId]);

  const handleMessageChange = (event) => {
    setUserMessage(event.target.value);
  };

  const sendNotification = async () => {
    if (!isNotificationAllowed) {
      toast.error(t("notificationPermissionDenied"));
      return;
    }

    if (!userMessage.trim()) {
      toast.error(t("enterMessageBeforeSending"));
      return;
    }

    if (!token || token.trim() === "") {
      toast.error(t("notificationSendError"));
      return;
    }

    const fullMessage = `${userMessage}`;
    const notificationTitle = doctor ? `${doctor.Name} ${doctor.Surname}` : 'RealHealth';

    const message = {
      to: token,
      notification: {
        body: fullMessage,
        title: notificationTitle,
      },
      data: {
        screen: 'TomografiScreen',
        tomogramIds: tomogramIds.join(',')
      }
    };

    try {
      console.log('Gönderilen bildirim yükü:', JSON.stringify(message));

      // FCM'ye bildirim gönderme isteği
      const response = await fetch('https://us-central1-realworks-health.cloudfunctions.net/sendNotification', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            token: token,
            title: notificationTitle,
            body: fullMessage
        }),
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        console.error('FCM\'den hata yanıtı:', errorMessage);
        throw new Error(`HTTP hatası! Durum: ${response.status}, mesaj: ${errorMessage}`);
      }
      // eslint-disable-next-line no-unused-vars
      const responseData = await response.json();

      toast.success(t("notificationSent"));

      // Select the correct Firestore instance
      let selectedDb;
      if (region === 'Netherlands') {
          selectedDb = db2;
      } else if (region === 'Belgium') {
          selectedDb = db3;
      }
      else if (region === 'Germany') {
        selectedDb = db4;
    } else if (region === 'Finland') {
          selectedDb = db5;
      } else if (region === 'United Kingdom') {
          selectedDb = db6;
      } else if (region === 'United States of America') {
          selectedDb = db7;
      } else if (region === 'Poland') {
          selectedDb = db8;
      } else if (region === 'Switzerland') {
          selectedDb = db9;
      }  
      else {
          selectedDb = db;
      }

      if (doctorId && token && fullMessage && notificationTitle && userId && tomogramIds.length > 0) {
        const now = new Date();
        // const timestamp = now.toLocaleString('en-GB', {
        //   day: 'numeric',
        //   month: 'numeric',
        //   year: 'numeric',
        //   hour: '2-digit',
        //   minute: '2-digit',
        //   second: '2-digit'
        // });

        const commentData = {
          userMessage: fullMessage,
          doctorID: doctor.id,
          notificationTitle: `${doctor.Name} ${doctor.Surname}`,
          timestamp: Timestamp.now(),
         isDeleted:false
        };

        const userDocRef = doc(selectedDb, 'users', userId); // Use selectedDb for the region
        const docSnapshot = await getDoc(userDocRef);
        if (docSnapshot.exists()) {
          const tomogramData = docSnapshot.data().userTomograms || {};
          const updatedTomogramData = { ...tomogramData };

          tomogramIds.forEach(id => {
            updatedTomogramData[id] = {
              ...tomogramData[id],
              comments: [...(tomogramData[id]?.comments || []), commentData]
            };
          });

          await updateDoc(userDocRef, {
            userTomograms: updatedTomogramData
          });
          await addDoc(collection(selectedDb, 'notifications', userId, 'userNotifications'), {
            ...commentData,
            read: false
          });
        } else {
          toast.error(t("userDocumentNotFound"));
          console.error('User document not found.');
        }
      }
      setUserMessage(''); // Clear the textarea after successful notification
    }  catch (error) {
      toast.error(`${t("notificationSendFailed")}: ${error.message}`);
      console.error("Full error details:", error);
  }
  
  };

  if (!isNotificationAllowed) {
    return null;
  }

  return (
    <div className="notif-area">
      <textarea
        className="form-control form-control-notif"
        placeholder={t("Enter your comment")}
        value={userMessage}
        onChange={handleMessageChange}
        maxLength={MAX_CHAR_LIMIT}
        rows="4"
      />
      <div className="bottom-container">
        <div className="character-count">
          {userMessage.length}/{MAX_CHAR_LIMIT} {t("character left")}
        </div>
        <button
          className="btn btn-primary send-notif-btn p-1 m-3"
          onClick={sendNotification}
        >
          {t("Send Notification")}
        </button>
      </div>
    </div>
  );
};

export default FcmTomogramSender;
