import React, { useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush
} from 'recharts';
import { useTranslation } from 'react-i18next'; 
const Charts = ({ type, data, unit }) => {
  
  const [selectedTimeFrame, setSelectedTimeFrame] = useState('daily');
  const { t } = useTranslation();
  // Verilen zaman dilimine göre veriyi filtreleyin
  const filteredData = data[selectedTimeFrame] || [];

  // Zaman diliminde veri olup olmadığını kontrol edin
  const hasData = filteredData.length > 0;
  
  let effectiveDataKey;

  // Zaman dilimine göre dataKey'i ayarlayın
  if (selectedTimeFrame === 'daily') {
    effectiveDataKey = 'dayTime';
  } else if (selectedTimeFrame === 'yearly' || selectedTimeFrame === 'sixMonths') {
    effectiveDataKey = 'monthYear';
  } else {
    effectiveDataKey = 'dayMonth';
  }
  
  // Zaman dilimi seçim butonlarını oluştur
  const renderTimeFrameButtons = () =>
    ["daily", "weekly", "monthly", "sixMonths", "yearly"].map((frame) => (
      <Button
        key={frame}
        style={{
          backgroundColor: selectedTimeFrame === frame ? "#7978DE" : "#f8f9fa",
          borderColor: "none",
          flex: "1 1 auto", // Make buttons flexible
          color: selectedTimeFrame === frame ? "#fff" : "#000",
          border: "none"
        }}
        className="timeframe-button"
        onClick={() => setSelectedTimeFrame(frame)}
      >
        {t(frame.charAt(0).toUpperCase() + frame.slice(1))}
      </Button>
    ));


  if (!data || data.length === 0) {
    return (
      <div style={{ textAlign: 'center', margin: '20px' }}>
        
        <p>{t('No Data')}</p>
      </div>
    );
  }
  // Tooltip için özelleştirilmiş formatter fonksiyonu
  const customTooltipFormatter = (value) => {
    return [`${t(type)}: ${t(value)} ` + (unit ? ` ${t(unit)} ` : '')]; // 'unit' boş değilse ekle
  };

  if (type === "") {
    if (data.length === undefined) {
      return (
        <div style={{ textAlign: 'center', margin: '20px' }}>
          <h3>{t(`${type}`)} {t('Graph')}</h3>
          <p>{t('No Data')}</p>
        </div>
      );
    }
    return (
      <div className='text-center'>
        <ResponsiveContainer width="95%" >
          <LineChart data={data}>
            <Line type="monotone" dataKey="value" stroke="#82ca9d" name={`${t(type)} ${t('Value')}`} />
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="dayTime" angle={-45} textAnchor="end" height={100} padding={{ left: 8, right: 8}} />
            <YAxis />
            <Tooltip formatter={customTooltipFormatter} />
            <Legend />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }

  if (type === "Sleep") {
    return (
      <div className='text-center'>
        
        <ResponsiveContainer width="100%">
          <LineChart data={data}>
            <Line type="monotone" dataKey="value" stroke="red" name={`${t(type)} ${t('Value')}`} />
            <CartesianGrid stroke="#E2E2E2" />
            <XAxis dataKey="dayMY" scale="point" angle={-45}  textAnchor="end" height={100} padding={{ left: 8, right: 8}} />
            <YAxis  domain={[0, 24]} ticks={[0,12,24]} />
            <Legend />
            <Tooltip formatter={customTooltipFormatter} />
            <Brush dataKey='dayMY' height={30} stroke="#514FFE"/>
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  } else {
    return (
      <div className='text-center'>
        <ButtonGroup className='py-2 time-category'>
          {renderTimeFrameButtons()}
        </ButtonGroup>
        {hasData ? (
          <ResponsiveContainer width="95%">
            <LineChart data={filteredData}>
              <Line type="monotone" dataKey="value" stroke="red" name={`${t(type)} ${t('Value')}`} />
              <CartesianGrid stroke="#E2E2E2" />
              <XAxis dataKey={effectiveDataKey} scale="point" {...(effectiveDataKey !== "dayTime" ? { angle: -45, textAnchor: "end" } : {})} height={100} padding={{ left: 8, right: 8}} />
              <YAxis />
              <Legend />
              <Tooltip formatter={customTooltipFormatter} />
            </LineChart>
          </ResponsiveContainer>  
        ) : (
          // Veri yoksa bilgilendirme mesajı göster
          <div style={{ textAlign: 'center', marginTop: '2rem' }}>
            <p>{t('No data available for the selected time frame')}</p>
          </div>
        )}
      </div>
    );
  }
};

export default Charts;
