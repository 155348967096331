
const healthDataTr = {
    healthkit: {
      healthScore: "Sağlık Skoru",
      activityEnergy: "Aktivite Enerjisi",
      bloodPressure: "Kan Basıncı",
      bodyFatPercente: "Vücut Yağ Oranı",
      bodyMassIndex: "Vücut Kitle İndeksi",
      cardioRecovery: "Kardiyo Toparlanma",
      exerciseTime: "Egzersiz Süresi",
      flightsClimbed: "Çıkılan Katlar",
      heartRate: "Kalp Atış Hızı",
      heartRateVeriability: "Kalp Atış Hızı Değişkenliği",
      leanBodyMass: "Yağsız Vücut Kütlesi",
      oxygenSaturation: "Oksijen Doygunluğu",
      respiratoryRate: "Solunum Hızı",
      restingEnergy: "Dinlenme Enerjisi",
      restingHeartRate: "Dinlenme Kalp Atış Hızı",
      standHours: "Ayakta Durma Süresi",
      steps: "Adımlar",
      vo2Max: "VO2 Maks",
      walkingDistance: "Yürüme Mesafesi",
      walkingHeartRate: "Yürüyüş Kalp Atış Hızı"
    },
    units: {
      vo2Max: "mL/kg/dk",
      heartRate: "Atım/dk",
      oxygenSaturation: "%",
      standHours: "Saat",
      heartRateVeriability: "ms",
      walkingDistance: "Metre",
      steps: "Adım",
      walkingHeartRate: "Atım/dk",
      respiratoryRate: "Nefes/dk",
      restingHeartRate: "Atım/dk",
      bloodPressure: "mmHg",
      restingEnergy: "kcal",
      flightsClimbed: "Kat",
      exerciseTime: "Dakika",
      activityEnergy: "kcal",
      cardioRecovery: "kcal"
    },
    categories: {
        'General Health Metrics': 'Genel Sağlık Metrikleri',
        'Exercise and Activity': 'Egzersiz ve Aktivite',
        'Heart and Respiratory Metrics': 'Kalp ve Solunum Metrikleri',
        'Body Composition': 'Vücut Kompozisyonu',
        'Metabolic Metrics': 'Metabolik Metrikler'
      }
  };
  export default healthDataTr;