import React, { useState, useEffect } from 'react';
import { getDoc, doc } from 'firebase/firestore';
import { db, db2, db3, db4, db5, db6, db7, db8, db9 } from '../firebase';
import { useTranslation } from 'react-i18next';
import avatar from '../images/avatar.png';
import { Timestamp } from 'firebase/firestore';

// Helper function to format Firebase Timestamp
const formatTimestamp = (timestamp) => {
  if (timestamp instanceof Timestamp) {
    const date = timestamp.toDate();
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  }
  return "Invalid Date";
};

const TomogramCommentList = ({ userId, tomogramIds, region }) => {
  const { t } = useTranslation();
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        let selectedDb;
        if (region === 'Netherlands') selectedDb = db2;
        else if (region === 'Belgium') selectedDb = db3;
        else if (region === 'Germany') selectedDb = db4;
        else if (region === 'Finland') selectedDb = db5;
        else if (region === 'United Kingdom') selectedDb = db6;
        else if (region === 'United States of America') selectedDb = db7;
        else if (region === 'Poland') selectedDb = db8;
        else if (region === 'Switzerland') selectedDb = db9;
        else selectedDb = db; // Default Firestore instance

        console.log('Selected Firestore Database:', selectedDb);

        const userDocRef = doc(selectedDb, 'users', userId);
        const docSnapshot = await getDoc(userDocRef);

        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          const tomogramData = userData.userTomograms || {};
          const allComments = tomogramIds.flatMap(id => tomogramData[id]?.comments || []);
          
          console.log('Fetched comments:', allComments);
          setComments(allComments);
        } 
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };

    if (userId && tomogramIds.length > 0) {
      fetchComments();
    }
  }, [userId, tomogramIds, region]);

  if (comments.length === 0) {
    return <p>{t('No comments available')}</p>;
  }

  return (
    <div className="comments-section">
      <h4>{t("Last Comments")}</h4>
      {comments.map((comment, index) => (
        <div key={index} className="comment-item list-group-item">
          <div className="comment-header">
            <img src={avatar} alt="Doctor" className="doctor-image" />
            <div className="comment-details">
              <strong>{comment.notificationTitle}</strong>
              <span className="comment-timestamp">{formatTimestamp(comment.timestamp)}</span>
            </div>
          </div>
          <p className="comment-text">{comment.userMessage}</p>
        </div>
      ))}
    </div>
  );
};

export default TomogramCommentList;
