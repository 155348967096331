import React, { createContext, useState, useEffect, useContext } from 'react';
import { doc, getDoc, collection, query, where, onSnapshot } from 'firebase/firestore';
import { db, db2,db3,db4,db5,db6,db7,db8,db9 } from '../firebase'; // Include additional databases
import { DoctorContext } from './DoctorContext';
import { AuthContext } from './AuthContext';
import { TeamContext } from './TeamContext';

export const UserContext = createContext({});

export const UserProvider = ({ children }) => {
  const [clients, setClients] = useState([]);
  const [pending, setPending] = useState([]);
  const [pendingCount, setPendingCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const { doctors } = useContext(DoctorContext);
  const { currentUser } = useContext(AuthContext);
  const { teams } = useContext(TeamContext);

  const databases = [ db, db2,db3,db4,db5,db6,db7,db8,db9]; // Add additional databases here

  useEffect(() => {
    const fetchPending = () => {
      const pendingUsers = [];
      const unsubscribes = databases.map((database) => {
        const q = query(collection(database, "doctors"), where("status", "==", "pending"));
        return onSnapshot(q, (snapshot) => {
          snapshot.forEach(doc => {
            pendingUsers.push({ id: doc.id, ...doc.data() });
          });
          setPending(pendingUsers);
          setPendingCount(pendingUsers.length);
        });
      });
      return () => unsubscribes.forEach(unsubscribe => unsubscribe());
    };

    fetchPending();
     // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchClientsAndPending = async () => {
      setLoading(true);
      if (!currentUser) {
        setLoading(false);
        return;
      }

      let currentProfile = doctors.find((doctor) => doctor.id === currentUser.uid);
      if (!currentProfile && teams) {
        currentProfile = teams.find((team) => team.uid === currentUser.uid);
      }
      if (!currentProfile) {
        const businessDoc = await getDoc(doc(db, 'business', currentUser.uid));
        if (businessDoc.exists()) {
            currentProfile = { id: businessDoc.id, ...businessDoc.data() };
        }
    }

      const fetchUserDocs = async (database, ids) => {
        return await Promise.all(
          ids.map(async (id) => {
            const userDoc = await getDoc(doc(database, 'users', id));
            return userDoc.exists() ? { id: userDoc.id, ...userDoc.data() } : null;
          })
        );
      };

      if (currentProfile) {
        const allClients = [];
        const allPending = [];

        for (const database of databases) {
          if (currentProfile.clients) {
            const clientList = await fetchUserDocs(database, currentProfile.clients);
            allClients.push(...clientList.filter(Boolean));
          }

          if (currentProfile.pending) {
            const pendingList = await fetchUserDocs(database, currentProfile.pending);
            allPending.push(...pendingList.filter(Boolean));
          }
        }

        setClients(allClients);
        setPending(allPending);
        setPendingCount(allPending.length);
      } else {
        setClients([]);
        setPending([]);
        setPendingCount(0);
      }

      setLoading(false);
    };

    fetchClientsAndPending();
     // eslint-disable-next-line
  }, [doctors, teams, currentUser]);

  const logoutUserContext = () => {
    setClients([]);
    setPending([]);
  };

  const { logout: logoutAuthContext } = useContext(AuthContext);

  const logout = () => {
    logoutUserContext();
    logoutAuthContext();
  };

  return (
    <UserContext.Provider value={{ clients, setClients, pending, setPending, pendingCount, loading, logout }}>
      {children}
    </UserContext.Provider>
  );
};
