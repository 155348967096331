import React, { useContext, useEffect, useState } from "react";
import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { UserContext } from "../../context/UserContext";
import {
  CircularProgress,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import filtre from '../../images/Tuning 2.svg'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import avatar from "../../images/avatar.png";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { DoctorContext } from "../../context/DoctorContext";
import { TeamContext } from "../../context/TeamContext";
import { doc, updateDoc, arrayRemove, getDoc } from "firebase/firestore";
import { db, db2, db3, db4, db5, db6, db7, db8, db9 } from "../../firebase";
import NavbarMenu from "../navbar/NavbarMenu";
import searchicon from "../../images/icons/search-lg.svg"
import CloseIcon from '@mui/icons-material/Close';
import '../../index.css'
import { SidebarContext } from "../../context/SidebarContext";
import AddPatientModal from "../addPatient/AddPatient";
import AddIcon from '@mui/icons-material/Add';
import ucnokta from '../../images/icons/ucnokta.svg'
import { toast } from "react-toastify";
import { BusinessContext } from "../../context/BusinessContext";

const Datatable = () => {
  const { clients, pending, loading, setClients } = useContext(UserContext);
  const [buttonLoading, setButtonLoading] = useState({});
  const [search, setSearch] = useState('');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userLabels, setUserLabels] = useState({});
  const [sortModel, setSortModel] = useState([]);
  const [loggedInUserData, setLoggedInUserData] = useState(null);
  const [pendingCount, setPendingCount] = useState(0);
  const { doctors } = useContext(DoctorContext);
  const { teams } = useContext(TeamContext);
  const { currentUser } = useContext(AuthContext);
  const databases = [db, db2, db3, db4, db5, db6, db7, db8, db9];
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);
  const { isSidebarCollapsed, setIsSidebarCollapsed } = useContext(SidebarContext);
  const [isAddPatientModalOpen, setIsAddPatientModalOpen] = useState(false); // Add Patient Modal state
  const [isLabelModalOpen, setIsLabelModalOpen] = useState(false);
  const [label, setLabel] = useState('');
  const [userPayments, setUserPayments] = useState({});
  const [selectionModel, setSelectionModel] = useState([]);
  const [tokenMonthly, setTokenMonthly] = useState(0);
  const [tokenYearly, setTokenYearly] = useState(0);
  const { businesses } = useContext(BusinessContext);
  const [editMode, setEditMode] = useState(false);
  const [selectedLabelFilter, setSelectedLabelFilter] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
const [selectedFilterLabel, setSelectedFilterLabel] = useState('');
const [isDeleteLabelModalOpen, setIsDeleteLabelModalOpen] = useState(false); // New state for delete label modal
const [selectedLabelToDelete, setSelectedLabelToDelete] = useState(''); // Label to delete


useEffect(() => {
  const savedLabels = JSON.parse(localStorage.getItem("userLabels"));
  if (savedLabels) {
    setUserLabels(savedLabels);
  }
}, []);
const handleOpenDeleteLabelModal = () => setIsDeleteLabelModalOpen(true);
const handleCloseDeleteLabelModal = () => setIsDeleteLabelModalOpen(false);
const handleLabelDelete = () => {
  const updatedLabels = { ...userLabels };

  // Remove the selected label from all users
  for (const userId in updatedLabels) {
    if (updatedLabels[userId] === selectedLabelToDelete) {
      delete updatedLabels[userId];
    }
  }

  // Save updated labels to state and localStorage
  setUserLabels(updatedLabels);
  localStorage.setItem("userLabels", JSON.stringify(updatedLabels));

  // Reset selection and close modal
  setSelectedLabelToDelete('');
  handleCloseDeleteLabelModal();
};

  const handleOpenLabelModal = () => setIsLabelModalOpen(true);
  const handleCloseLabelModal = () => setIsLabelModalOpen(false);
  const toggleFilter = () => {
    setIsFilterOpen((prev) => !prev); // Toggle the filter open/close state
  };

  const handleLabelInputChange = (event) => {
    setLabel(event.target.value);
  };
  const handleOpenAddPatientModal = () => setIsAddPatientModalOpen(true);
  const handleCloseAddPatientModal = () => setIsAddPatientModalOpen(false);
  const handleApplyLabel = () => {
    const updatedLabels = { ...userLabels };
    selectionModel.forEach((userId) => {
      updatedLabels[userId] = label; // Seçilen kullanıcılara etiket ekle
    });
  
    // Etiketleri state'e ve localStorage'a kaydet
    setUserLabels(updatedLabels);
    localStorage.setItem("userLabels", JSON.stringify(updatedLabels)); // localStorage'a kaydet
  
    // Seçimi temizle ve modalı kapat
    setSelectionModel([]); 
    handleCloseLabelModal(); 
  };
  
  // Component mount olduğunda localStorage'dan etiketleri çekme
  useEffect(() => {
    const savedLabels = JSON.parse(localStorage.getItem("userLabels"));
    if (savedLabels) {
      setUserLabels(savedLabels); // Etiketleri state'e yükle
    }
  }, []);
  
  const toggleEditMode = () => {
    setEditMode(!editMode);
  };
  const handleLabelFilterChange = (event) => {
    setSelectedLabelFilter(event.target.value);
  };

  useEffect(() => {
    const fetchTokenData = async () => {
      try {
        const doctorRef = doc(db, "doctors", currentUser.uid);
        const doctorSnap = await getDoc(doctorRef);
        if (doctorSnap.exists()) {
          const data = doctorSnap.data();
          setTokenMonthly(data.tokenMonthly || 0);
          setTokenYearly(data.tokenYearly || 0);
        }
      } catch (error) {
        console.error("Error fetching doctor tokens:", error);
      }
    };

    if (currentUser) {
      fetchTokenData();
    }
  }, [currentUser]);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1240) {
        setIsSidebarCollapsed(true); // Collapse sidebar if width is 1240px or less
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setIsSidebarCollapsed]);

  useEffect(() => {
    // Initialize buttonLoading for all clients as true
    const initialButtonLoading = clients.reduce((acc, client) => {
      acc[client.id] = true;
      return acc;
    }, {});
    setButtonLoading(initialButtonLoading);

    fetchUserPayments();
  }, [clients]);

  const fetchUserPayments = async () => {
    const paymentStatus = {};
    const updatedButtonLoading = { ...buttonLoading };

    await Promise.all(
      clients.map(async (client) => {
        let paymentFound = false;

        for (const database of databases) {
          const docRef = doc(database, "users", client.id);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const data = docSnap.data();
            paymentStatus[client.id] = {
              payment: data.payment || false,
              doctorPayment: data.doctorPayment || false,
            };
            paymentFound = true;
            break; // Exit loop if user is found
          }
        }

        if (!paymentFound) {
          paymentStatus[client.id] = {
            payment: false,
            doctorPayment: false,
          };
        }

        updatedButtonLoading[client.id] = false;
      })
    );

    setUserPayments(paymentStatus);
    setButtonLoading(updatedButtonLoading);
  };


  useEffect(() => {
    if (clients.length > 0) {
      fetchUserPayments();
    }
  }, [clients]);

  useEffect(() => {
    const savedSortModel = JSON.parse(localStorage.getItem('sortModel'));
    if (savedSortModel) {
      setSortModel(savedSortModel);
    }
  }, []);

  useEffect(() => {
    setPendingCount(pending.length);
  }, [pending]);

  const handleSortModelChange = (newModel) => {
    setSortModel(newModel);
    localStorage.setItem('sortModel', JSON.stringify(newModel));
  };



  const isMediumScreen = useMediaQuery('(max-width:1024px)');

  const columnVisibilityModel = {
    email: !isMediumScreen,
    genderType: !isMediumScreen,
    birthday: !isMediumScreen,
    updateData: !isMediumScreen,
    lastUpdated: !isMediumScreen,
  };

  const handleSearch = (event) => {
    setSearch(event.target.value.toLowerCase());
  };

  const filteredClients = clients
  .filter((client) => {
    const name = client.name && client.name.trim() ? client.name : t("Nameless");
    const email = client.email || "";
    if (name.toLowerCase().includes(search) || email.toLowerCase().includes(search)) {
      // Filter by label if a label is selected
      if (selectedLabelFilter && userLabels[client.id] !== selectedLabelFilter) {
        return false;
      }
      return true;
    }
    return false;
  });

  const Theme = createTheme({
    palette: {
      mode: 'light',
    },
    components: {
      MuiDataGrid: {
        styleOverrides: {
          row: {
            borderBottom: '2px solid #E2E2FF',
          },
          root: {
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus': {
              outline: 'none',
            },
            '& .css-yrdy0g-MuiDataGrid-columnHeaderRow': {
              borderBottom: '1px solid #E2E2FF',
            },
            '& .MuiDataGrid-row.Mui-hovered': {
              backgroundColor: '#E2E2FF',
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: '#E2E2FF',
            },
            '& .MuiToolbar-root>p': {
              margin: '0',
            }
          },
        },
      },
    },
  });

  const handleOpenDeleteDialog = (client) => {
    setClientToDelete(client);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setClientToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (clientToDelete) {
      const doctorRef = doc(db, "doctors", currentUser.uid);
      await updateDoc(doctorRef, {
        clients: arrayRemove(clientToDelete.id)
      });

      setClients(prevClients => prevClients.filter(client => client.id !== clientToDelete.id));
      handleCloseDeleteDialog();
    }
  };

  const handleUpgradeClick = (userName, clientId) => {
    setIsSidebarCollapsed(true);

    // Check if multiple users are selected
    if (selectionModel.length > 0) {
      const selectedUserIds = selectionModel.join(',');
      // Navigate to the bulk upgrade page with all selected users' IDs
      navigate(`/token/${selectedUserIds}/bulk-upgrade`);
    } else {
      // If no selection, navigate with the clicked user's ID
      navigate(`/token/${clientId}/${userName}`);
    }
  };

  const handleBulkUpgrade = async () => {
    if (selectionModel.length === 0) return; // If no users are selected, don't proceed.

    // Navigate to the token page and pass selected user IDs as a comma-separated string
    const selectedUserIds = selectionModel.join(',');
    navigate(`/token/${selectedUserIds}/bulk-upgrade`);
  };

  const handleCheckboxChange = (userId) => {
    setSelectionModel((prevSelection) => {
      if (prevSelection.includes(userId)) {
        // If user is already selected, remove it from the selection
        return prevSelection.filter((id) => id !== userId);
      } else {
        // Otherwise, add the user to the selection
        return [...prevSelection, userId];
      }
    });
  };


  useEffect(() => {
    // Set a timer for 2 seconds
    const timer = setTimeout(() => {
      setButtonLoading(false);
    }, 2000);

    return () => clearTimeout(timer); // Clear the timer on component unmount
  }, [clients]);



  const columns = [
    editMode && {
      field: "checkbox",
      headerName: "",
      sortable: false,
      filterable: false,
      flex: 0.5,
      renderCell: (params) => {
        const isChecked = selectionModel.includes(params.row.id);
        return (
          <input
            type="checkbox"
            checked={isChecked}
            onChange={() => handleCheckboxChange(params.row.id)}
          />
        );
      },
    },
    {
      field: "user",
      headerName: t('Users'),
      sortable: true,
      flex: 2,
      height: 100,
      valueGetter: (params) => `${params.row.name || t("Nameless")}  ${params.row.surname}`,
      renderCell: (params) => {
        const isDataShareFalse = params.row.companyDataShare === false; // Check if companyDataShare is false
        return (
          <div className="cellWithImg">
            <Zoom>
              <img
                onClick={(event) => { event.stopPropagation(); }}
                className="cellImg object-fit-cover"
                src={params.row.profileImageUrl ? params.row.profileImageUrl : avatar}
                alt="avatar"
              />
            </Zoom>
            <div className="user-info">
              {/* Apply conditional style for name color */}
              <div
                className="user-name"
                style={{ color: isDataShareFalse ? 'gray' : '#141517' }}
              >
                {params.row.name + " " + params.row.surname}
              </div>
              <div className="user-email">{params.row.email}</div>
            </div>
          </div>
        );
      },
    },
    
    {
      field: "label",
      headerName: t("Label"),
      flex: 1,
      renderCell: (params) => {
        const userId = params.row.id;
        return userLabels[userId] ? userLabels[userId] : ''; // Eğer etiket varsa göster
      },
    },
    {
      field: "genderType",
      headerName: t('gender'),
      flex: 1,
      valueFormatter: (params) => t(`Gender.${params.value}`),
    },
    {
      field: "birthday",
      headerName: t('birthday'),
      flex: 1,
      type: 'date',
      valueGetter: (params) => {
        if (params.row.birthday && params.row.birthday.split("/").length === 3) {
          const dateParts = params.row.birthday.split("/");
          return new Date(`${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`);
        }
        return null;
      },
    },
    {
      field: "lastUpdated",
      headerName: t('lastUpdated'),
      flex: 1,
      type: 'dateTime',
      valueGetter: (params) => {
        if (params.row.HealthDataUser && params.row.HealthDataUser.timestamp && params.row.HealthDataUser.timestamp.seconds) {
          return new Date(params.row.HealthDataUser.timestamp.seconds * 1000);
        }
        return null;
      },
      renderCell: (params) => {
        const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
        const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }; // 24-hour format
        const date = params.value ? new Intl.DateTimeFormat('tr-TR', dateOptions).format(params.value) : '';
        const time = params.value ? new Intl.DateTimeFormat('tr-TR', timeOptions).format(params.value) : '';
        return (
          <div className="dateTimeCell">
            <div className="time">{time}</div>
            <div className="date">{date}</div>
          </div>
        );
      }
    },
    {
      field: "account",
      headerName: t("Account"),
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const { payment, doctorPayment } = userPayments[params.row.id] || {};
        const isPremium = payment || doctorPayment; // Check if the user is premium
        const isButtonLoading = buttonLoading[params.row.id];
        return (
          <div className="account-action">
            {isButtonLoading ? (
              <CircularProgress size={24} />
            ) : (
              <button
                className={`premium-button ${isPremium ? 'premium' : 'upgrade'}`}
                onClick={() => handleUpgradeClick(params.row.name, params.row.id)}
                disabled={isPremium}
                style={{
                  backgroundColor: isPremium ? "#7978DE" : "#fff",
                  color: isPremium ? "#fff" : "#7978DE",
                  border: isPremium ? "none" : "1px solid #7978DE",
                }}
              >
                {isPremium ? t("Premium") : t("Upgrade")}
              </button>
            )}
          </div>
        );
      },
    },
    {
      field: "delete",
      headerName: t(""),
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const isButtonLoading = buttonLoading[params.row.id]; // Check loading state
        return (
          <div className="action-buttons" style={{ display: 'flex' }}>
            {isButtonLoading ? (
              <CircularProgress size={24} /> // Display progress until loading completes
            ) : (
              <button
                className="details-button"
                onClick={(event) => {
                  event.stopPropagation(); // Prevent the row click event

                  const { payment, doctorPayment } = userPayments[params.row.id] || {};
                  const userId = params.row.id;
                  const userName = params.row.name;

                  // Redirect to the payment page if neither payment nor doctorPayment is true
                  if (!payment && !doctorPayment) {
                    navigate(`/token/${userId}/${userName}`);
                  } else {
                    navigate(`/users/${userId}`);

                  }
                }}
              >
                {t("Details")}
              </button>
            )}
          
          </div>
        );
      },
    }
  ].filter(Boolean);

  useEffect(() => {
    if (doctors && currentUser && currentUser.uid) {
      // Business kullanıcılarını kontrol edeceğiz
      const user = doctors.find((u) => u.id === currentUser.uid) ||
        teams.find((u) => u.id === currentUser.uid) ||
        businesses.find((u) => u.id === currentUser.uid);  // Eğer business kullanıcıları başka bir yapıdaysa

      if (user) {
        setLoggedInUserData(user);
      }

    }
  }, [doctors, currentUser, teams, businesses]); // Gerekli contextleri ekliyoruz


  useEffect(() => {
    const updatePendingCount = () => {
      if (currentUser && currentUser.uid && loggedInUserData && loggedInUserData.type !== "Assistant") {
        setPendingCount(pending.length);
      } else {
        setPendingCount(0);
      }
    };

    updatePendingCount();
  }, [currentUser, loggedInUserData, pending]);

  const handleBulkRemove = async () => {
    if (selectionModel.length === 0) return; // If no users are selected, don't proceed.
  
    try {
      // Loop through selected users
      await Promise.all(
        selectionModel.map(async (clientId) => {
          // Remove from Firestore (assuming "clients" are stored in "doctors" collection)
          const doctorRef = doc(db, "doctors", currentUser.uid);
  
          await updateDoc(doctorRef, {
            clients: arrayRemove(clientId),
          })
        })
      );
  
      // Remove clients from local state after deletion
      setClients(prevClients => prevClients.filter(client => !selectionModel.includes(client.id)));
  
      // Clear the selection model
      setSelectionModel([]);
  
      toast.success("Selected users removed successfully!");
    } catch (error) {
      console.error("Error removing users:", error);
      toast.error("Error removing selected users.");
    }
  };
  

  const toggleFilterModal = () => {
    setIsFilterModalOpen(prev => !prev);
  };
  
  // Seçilen filtreyi yönetmek için fonksiyon
  const handleFilterLabelChange = (event) => {
    setSelectedFilterLabel(event.target.value);
  };

  return (
    <ThemeProvider theme={Theme}>
      <div>
        <NavbarMenu />
        <div>
          {loading ? (
            <div className="d-flex align-items-center justify-content-center vh-100">
              <CircularProgress />
            </div>
          ) : (
            <div className="container">
              <div className={`datatable ${isSidebarCollapsed ? "sidebar-collapsed" : "sidebar-open"}`}>
                {/* Header Section */}
                <div className="row align-items-center">
                  <div className="patient-container">
                    <div>
                      <h1 className="head">{t('Users')}</h1>
                      <p style={{ color: '#677184' }}>{t("View your user's details")}</p>
                    </div>
                    <button onClick={handleOpenAddPatientModal} className="add-patients">
                      <AddIcon style={{ marginRight: '8px' }} /> {/* Icon */}
                      {t("Add User")}
                    </button>
                  </div>

                  {clients.length > 0 && (
                    <div className="col-12 col-sm-3 justify-content-end" >
                      <div className="search-bar-container position-relative" style={{ border: '1px solid #ccc', borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
                        <img
                          src={searchicon}
                          alt="Search Icon"
                          style={{ marginLeft: '10px' }}
                        />
                        <input
                          type="text"
                          className="form-control"
                          id="searchInput"
                          placeholder={t("searchusers")}
                          value={search}
                          onChange={handleSearch}
                          style={{ border: 'none', paddingBottom: '5px', flex: '1' }}
                        />
                      </div>
                    </div>
                  )}
                </div>

                {/* Bulk Upgrade Button */}
                {/* {selectionModel.length > 0 && (
                  <div className="bulk-upgrade-button" style={{ margin: '10px 0', display: 'flex', justifyContent: 'flex-end' }}>
                    <button
                      className="upgrade-selected-button"
                      onClick={handleBulkUpgrade}
                      style={{
                        backgroundColor: "#7978DE",
                        color: "#fff",
                        border: "none",
                        padding: "10px 20px",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      {t("Upgrade Selected")}
                    </button>
                  </div>
                )} */}

                {/* Information Section */}
                <div>
                <div className="row align-items-center mb-3">
  <div className="col-12 pe-2 pt-5 d-flex justify-content-between">
    <div className="d-flex align-items-center">
      <span style={{
        fontFamily: "Poppins",
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: "28px",
        color: "#141517",
      }}>
        {t("Total Users")}: {clients.length}
      </span>
    </div>

    {/* Right Aligned Buttons */}
    <div className="d-flex align-items-center">
  {editMode && (
    <>
      <button onClick={handleBulkUpgrade} className="edit-delete" style={{ marginRight: '10px' }}>
        {t("Upgrade")}
      </button>
      {/* Here is the label button */}
      <button onClick={handleOpenLabelModal} className="edit" style={{ marginRight: '10px' }}>
        {t("Label")}
      </button>
      <button onClick={handleOpenDeleteLabelModal} className="edit-delete">
            {t("Delete Label")}
          </button>
      <button onClick={handleBulkRemove} className="edit-delete" style={{ marginRight: '10px' }}>
        {t("Remove")}
      </button>
    </>
  )}
  
  <button onClick={toggleEditMode} className="edit">
    {editMode ? t("Done") : t("Edit")}
  </button>
  
  {!editMode && (
                        <img
                          src={filtre}
                          alt="Filter"
                          style={{ cursor: "pointer", marginRight: "10px" }}
                          onClick={toggleFilterModal} // This will open the filter dropdown
                        />
                      )}
</div>

  </div>
  
</div>
<Dialog
        open={isDeleteLabelModalOpen}
        onClose={handleCloseDeleteLabelModal}
        aria-labelledby="delete-label-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="delete-label-dialog-title">
          {t("Select a label to delete")}
          <IconButton
            aria-label="close"
            onClick={handleCloseDeleteLabelModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <Select
              value={selectedLabelToDelete}
              onChange={(e) => setSelectedLabelToDelete(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">{t("Select a Label")}</MenuItem>
              {[...new Set(Object.values(userLabels))].map((label, index) => (
                <MenuItem key={index} value={label}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', paddingBottom: 2 }}>
          <Button
            onClick={handleLabelDelete}
            variant="contained"
            style={{
              border: '1px solid #000',
              borderRadius: '10px',
              backgroundColor: '#fff',
              color: '#000',
            }}
          >
            {t("Delete Label")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Existing code for Add Label Modal */}
      <Dialog
        open={isLabelModalOpen}
        onClose={handleCloseLabelModal}
        aria-labelledby="label-dialog-title"
        aria-describedby="label-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="label-dialog-title">
          {t("Put a label on selected people")}
          <IconButton
            aria-label="close"
            onClick={handleCloseLabelModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {/* Add label input */}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', paddingBottom: 2 }}>
          <Button
            onClick={handleApplyLabel}
            variant="contained"
            style={{
              border: '1px solid #000',
              borderRadius: '10px',
              backgroundColor: '#fff',
              color: '#000',
            }}
          >
            {t("Add Label")}
          </Button>
        </DialogActions>
      </Dialog>
<Dialog
          open={isFilterModalOpen} // Modalın açık/kapalı durumu
          onClose={toggleFilterModal} // Modalı kapatmak için
          aria-labelledby="filter-dialog-title"
          aria-describedby="filter-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="filter-dialog-title">
            {t("Select a Label to Filter Users")}
            <IconButton
              aria-label="close"
              onClick={toggleFilterModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
          <FormControl fullWidth>
  
  <Select
    labelId="label-filter-select-label"
    id="label-filter-select"
    value={selectedFilterLabel}
    label={t("Filter by Label")}
    onChange={handleFilterLabelChange} // Seçim işlemi için
  >
    <MenuItem value="">{t("All Labels")}</MenuItem>
    {/* Set kullanarak benzersiz etiketleri gösteriyoruz */}
    {[...new Set(Object.values(userLabels))].map((label, index) => (
      <MenuItem key={index} value={label}>{label}</MenuItem>
    ))}
  </Select>
</FormControl>

          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', paddingBottom: 2 }}>
            <Button
              onClick={() => {
                setSelectedLabelFilter(selectedFilterLabel); // Seçilen filtreyi uygulamak
                toggleFilterModal(); // Modalı kapatmak
              }}
              variant="contained"
              style={{
                border: '1px solid #000',
                borderRadius: '10px',
                backgroundColor: '#fff',
                color: '#000'
              }}
            >
              {t("Apply Filter")}
            </Button>
          </DialogActions>
        </Dialog>

<DataGrid
  autoHeight
  disableRowSelectionOnClick
  initialState={{
    pagination: {
      paginationModel: { pageSize: 10, page: 0 },
    },
  }}
  rows={filteredClients}
  columns={columns}
  pageSizeOptions={[10]}
  pagination
  className="datagrid"
  columnVisibilityModel={columnVisibilityModel}
  rowHeight={65}
  sortModel={sortModel}
  onSortModelChange={handleSortModelChange}
  selectionModel={selectionModel}
  onSelectionModelChange={(newSelection) => {
    setSelectionModel(newSelection);
  }}
  components={{
    NoRowsOverlay: () => (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: '18px',
        color: '#141517',
        fontFamily: 'Poppins',
      }}>
        {t("No User")}
      </div>
    ),
  }}
/>


                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="delete-dialog-title">
          <IconButton
            aria-label="close"
            onClick={handleCloseDeleteDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography id="delete-dialog-description" variant="body1" align="center">
            {t("Do you want to remove this person?")}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', paddingBottom: 2 }}>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            style={{
              border: '1px solid #000',
              borderRadius: '10px',
              backgroundColor: '#fff',
              color: '#000'
            }}
          >
            {t("Remove")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
  open={isLabelModalOpen}
  onClose={handleCloseLabelModal}
  aria-labelledby="label-dialog-title"
  aria-describedby="label-dialog-description"
  maxWidth="sm"
  fullWidth
>
  <DialogTitle id="label-dialog-title">
    {t("Put a label on selected people")}
    <IconButton
      aria-label="close"
      onClick={handleCloseLabelModal}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
      }}
    >
      <CloseIcon />
    </IconButton>
  </DialogTitle>
  <DialogContent>
    <TextField
      fullWidth
   
      value={label}
      onChange={handleLabelInputChange}
      variant="outlined"
    />
  </DialogContent>
  <DialogActions sx={{ justifyContent: 'center', paddingBottom: 2 }}>
    <Button
      onClick={handleApplyLabel}
      variant="contained"
      style={{
        border: '1px solid #000',
        borderRadius: '10px',
        backgroundColor: '#fff',
        color: '#000'
      }}
    >
      {t("Add Label")}
    </Button>
  </DialogActions>
</Dialog>

      <AddPatientModal
        isModalOpen={isAddPatientModalOpen}
        handleClose={handleCloseAddPatientModal}
      />
    </ThemeProvider>
  );
};

export default Datatable;

