import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css'; // Include Bootstrap
import './QrRedirect.css'; // Use this for additional styling
import logo from '../../images/minilogo.svg'; // Include your minilogo.svg file
import talk from '../../images/DoctorTalk.svg';
import userdoctor from '../../images/userdoctor.svg';

import { doc, getDoc, updateDoc } from 'firebase/firestore'; 
import { db } from '../../firebase';

const QRRedirect = () => {
  const [appLink, setAppLink] = useState('');
  const [storeName, setStoreName] = useState('');
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');

  const queryParams = new URLSearchParams(window.location.search);
  const doctorName = queryParams.get('doctorName');
  const doctorSurname = queryParams.get('doctorSurname');

  const displayedDoctorName = doctorName || "Doktor";
  const displayedDoctorSurname = doctorSurname || "";
  const uniqueID = queryParams.get('uniqueID');

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      setAppLink('https://play.google.com/store/apps/details?id=YOUR_ANDROID_APP_ID');
      setStoreName('Google Play Store');
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setAppLink('https://apps.apple.com/tr/app/realhealth-ai-health-mate/id6467396064');
      setStoreName('App Store');
    } else {
      setAppLink('https://apps.apple.com/tr/app/realhealth-ai-health-mate/id6467396064');
      setStoreName('our website');
    }

    const checkLinkStatus = async () => {
      try {
        const linkRef = doc(db, 'invitationLinks', uniqueID);
        const linkDoc = await getDoc(linkRef);
        
        if (linkDoc.exists() && linkDoc.data().used === false) {
          // Mark the link as used
          await updateDoc(linkRef, { used: true });
          console.log("Link marked as used successfully!");
        } else {
          setErrorMessage('This link does not exist or has already been used.');
        }
      } catch (error) {
        console.error("Error checking or updating the link status:", error);
        setErrorMessage(`Error processing the link: ${error.message}`);
      }
    };

    checkLinkStatus();
  }, [uniqueID]);

  return (
    <div className="container mt-5">
      {/* Logo and User Information */}
      <div className="d-flex align-items-center justify-content-start">
        <img src={logo} alt="Realhealth Logo" className="logo" style={{ width: '30px', height: '30px' }} />
        <h2 className="ms-2">{t('Realhealth')}</h2> {/* ms-2 adds left margin */}
      </div>

      <div className="text-center">
        <div className="invite-section d-flex align-items-center justify-content-center mt-5 p-1">
          <img
            src={userdoctor} // User profile picture
            alt="User Avatar"
            className="rounded-circle"
            style={{ width: '100px', height: '100px' }}
          />
          <div className="ms-2">
            <h5>
              <strong>{`${displayedDoctorName} ${displayedDoctorSurname}`}</strong> {/* Display fetched doctor name and surname */}
             invites <br /> you to <strong>{t('Realhealth App')}</strong>
            </h5>
          </div>
        </div>
      </div>

      {/* Step-by-Step Instructions */}
      <div className="container mt-4 px-5" style={{ backgroundColor: '#F5F6F7' }}>
        <div className="step-item d-flex align-items-center mb-3">
          <span className="step-number" style={{ height: '30px', width: '25px' }}>1</span>
          <p className="ms-4">
            <a style={{ textDecoration: 'none', color: '#7978DE',fontWeight:'600' }} href={appLink} target="_blank" rel="noopener noreferrer">
              {t('Install Realhealth app ')} <span style={{color:'#141517' ,fontWeight:'500',fontSize:'16px'}}>from <br/> {t(storeName)}</span>
            </a>
          </p>
        </div>
        <div className="step-item d-flex align-items-center mb-3">
          <span className="step-number" style={{ width: '40px', height: '30px' }}>2</span>
          <p className="ms-4">
            <span style={{fontWeight:'500',color:'#141517'}}>Allow me to see your medical data in Realhealth.</span> <a style={{textDecoration:'none'}} href={appLink}>
              <span  style={{ textDecoration: 'none', color: '#7978DE',fontWeight:'600' }}>{t('Allow in the app')}</span></a>
          </p>
        </div>
        <div className="step-item d-flex align-items-center mb-3">
          <span className="step-number" style={{ width: '25px', height: '30px' }}>3</span>
          <p className="ms-4">
            <span style={{color:'#141517',fontWeight:'600',fontSize:'16px'}}>Upload your lab results into <br/> Realhealth,</span> and I will be able to see them.
          </p>
        </div>
      </div>

      {/* Footer */}
      <div className="text-start mt-5">
        <h4>{t('What is it for?')}</h4>
        <img
          src={talk} // Bottom image (placeholder)
          alt="Information Graphic"
          className="img-fluid mt-3 full-width-image"
        />
        <p className="what mt-3">
          {t(
            'Realhealth helps store all your lab results in one place and monitor your health. With this app it’s very easy to share your medical data with doctors, nutritionists, and other health specialists like me.'
          )}
        </p>
      </div>
    </div>
  );
};

export default QRRedirect;
