import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logo from '../../images/icons/Content.svg';
import './register.css';
import { useTranslation } from "react-i18next";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Dropdown } from "react-bootstrap";
import deFlag from "../../images/de.png";
import usFlag from "../../images/eng.svg";
import trFlag from "../../images/turkey.svg";
import i18n from "../../i18n";

function RegisterCompany() {
  const [companyName, setCompanyName] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // Form gönderimi için state
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isFormValid = email !== "" && companyName !== "" && businessType !== ""; // Form geçerli mi
  const [currentFlagUrl, setCurrentFlagUrl] = useState(usFlag);
  const [isDoctor, setIsDoctor] = useState(true);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("appLanguage", lng);
    setCurrentFlagUrl(getCurrentFlag(lng));
  };

  const getCurrentFlag = (lang) => {
    switch (lang) {
      case "en":
        return usFlag;
      case "tr":
        return trFlag;
      case "de":
        return deFlag;
      default:
        return usFlag;
    }
  };

  const handleOptionChange = (isDoctorSelected) => {
    setIsDoctor(isDoctorSelected);
    if (isDoctorSelected) {
      navigate("/register");
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    setIsSubmitting(true); // Disable the button at the start of the submission
    const functions = getFunctions();
    const sendCompanyApplicationEmail = httpsCallable(functions, 'sendCompanyApplicationEmail');

    try {
      const result = await sendCompanyApplicationEmail({
        name,
        surname,
        companyName,
        businessType,
        email,
        website,
        title,
      });

      if (result.data.success) {
        toast.success(t("Başvurunuz alındı, en kısa zamanda geri dönüş yapılacak."));
      } else {
        toast.error(t("Başvurunuz gönderilemedi. Lütfen tekrar deneyin."));
      }
    } catch (error) {
      console.error(t("Başvuru sırasında bir hata oluştu:"), error);
      toast.error(t("Bir hata oluştu. Lütfen tekrar deneyin."));
    } finally {
      setIsSubmitting(false); // Re-enable the button after the submission process is finished
    }
};


  return (
    <div className="register">
      <div className="containerr">
        <div className="register-left">
          <div className="subcont">
            <div className="form-containerr">
              <Dropdown>
                <Dropdown.Toggle
                  style={{ backgroundColor: "transparent", border: "none" }}
                  variant="success"
                  id="dropdown-basic"
                  className="btn-info"
                >
                  <img
                    src={currentFlagUrl}
                    alt="Current Language"
                    width="40"
                    height="30"
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => changeLanguage("en")}>
                    <img src={usFlag} alt="English" width="40" height="30" />
                    English
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => changeLanguage("tr")}>
                    <img src={trFlag} alt="Türkçe" width="40" height="30" />
                    Türkçe
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => changeLanguage("de")}>
                    <img src={deFlag} alt="Deutsch" width="40" height="30" />
                    Deutsch
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className="login-header" style={{ marginBottom: '5px', marginTop: '-30px' }}>
                <img src={logo} alt="Realhealth Logo" className="logo-img" />
                <span className="logo-title">Realhealth</span>
              </div>

              <div className="button-group mb-1 ">
                <button
                  className={`option-button ${!isDoctor ? 'selected' : ''}`}
                  onClick={() => handleOptionChange(true)}
                >
                  {t("For Doctors")}
                </button>
                <button
                  className={`option-button ${isDoctor ? 'selected' : ''}`}
                  onClick={() => handleOptionChange(false)}
                >
                  {t("For Businesses")}
                </button>
              </div>
              <form className="login-form" onSubmit={handleRegister}>
                <div className="input-group-register">
                  <label htmlFor="name" className="input-label">{t("Name")}*</label>
                  <input
                    type="text"
                    className="form-input"
                    id="name"
                    onChange={(e) => setName(e.target.value)}
                    placeholder={t("Enter your name")}
                    required
                  />
                </div>
                <div className="input-group-register">
                  <label htmlFor="surname" className="input-label">{t("Surname")}*</label>
                  <input
                    type="text"
                    className="form-input"
                    id="surname"
                    onChange={(e) => setSurname(e.target.value)}
                    placeholder={t("Enter your surname")}
                    required
                  />
                </div>
                <div className="input-group-register">
                  <label htmlFor="email" className="input-label">{t("Email")}*</label>
                  <input
                    type="email"
                    id="email"
                    placeholder={t("Enter your email")}
                    className={`form-input ${email ? "filled" : ""}`}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <label htmlFor="companyName" className="input-label">{t("Company Name")}*</label>
                  <input type="text" id="companyName" className="form-input" value={companyName} onChange={(e) => setCompanyName(e.target.value)} placeholder={t("Enter your company's name")} required />
                </div>
                <div className="input-group-register">
                  <label htmlFor="businessType" className="input-label">{t("Business Type")}*</label>
                  <input type="text" id="businessType" className="form-input" value={businessType} onChange={(e) => setBusinessType(e.target.value)} placeholder={t("Enter your business type")} required />
                </div>
                <div className="input-group-register">
                  <label htmlFor="website" className="input-label">{t("Website Name")}</label>
                  <input type="text" id="website" className="form-input" value={website} onChange={(e) => setWebsite(e.target.value)} placeholder={t("Enter your Website Name")} />
                </div>

                <div className="input-group-register">
                  <label htmlFor="title" className="input-label">{t("Your Title")}*</label>
                  <input type="text" id="title" className="form-input" value={title} onChange={(e) => setTitle(e.target.value)} placeholder={t("Enter your title")} required />
                </div>
                <div className="button-group m-2">
                <button 
    type="submit" 
    className={`form-button ${isFormValid ? "active" : ""}`} 
    disabled={!isFormValid || isSubmitting}
>
    {isSubmitting ? t("Submitting...") : t("Apply")}
</button>

                </div>
                <div className="mt-3">
                  <span className="d-flex align-items-center justify-content-center" style={{ fontWeight: '400', fontSize: '14px', color: '#677184', marginBottom: '0px' }}>
                    {t("Already have an account?")}
                    <a className="m-2 login-buton" style={{ color: '#7978DE', textDecoration: 'none' }} href="/login">{t("Login")}</a>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="register-right">
        </div>
      </div>
    </div>
  );
}

export default RegisterCompany;
