import React, { useContext, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { UserContext } from "../../context/UserContext";
import NavbarMenu from "../navbar/NavbarMenu";
import { CircularProgress, useMediaQuery, Modal, Box } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import avatar from "../../images/avatar.png";
import { doc, updateDoc, arrayRemove, arrayUnion, getDocs } from "firebase/firestore";
import { db, db2, db3, db4, db5, db6, db7, db8, db9 } from "../../firebase"; 
import { DoctorContext } from "../../context/DoctorContext";
import { AuthContext } from "../../context/AuthContext";
import { TeamContext } from "../../context/TeamContext";

import './Pending.css';
const style = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  height: '50%',
  bgcolor: 'background.paper',
  boxShadow: 24,
};
// Yardımcı işlev: Doğru Firestore veritabanını seç
const getSelectedDb = (region) => {
  switch (region.toLowerCase()) {
    case 'amsterdam':
      return db2;
    case 'belgium':
      return db3;
      case 'berlin':
      return db4;
    case 'finland':
      return db5;
    case 'london':
      return db6;
    case 'unitedstatesofamerica':
      return db7;
    case 'warsaw':
      return db8;
    case 'zurich':
      return db9;
    
    default:
      return db;
  }
};

const Pending = ({ open, setOpen, pendingCount, region }) => {
  const { pending, setPending, loading } = useContext(UserContext);
  const { doctors } = useContext(DoctorContext);
  const { teams } = useContext(TeamContext);
  // eslint-disable-next-line no-unused-vars
  const [search, setSearch] = useState('');
  const { t } = useTranslation();
  const [sortModel, setSortModel] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [userTeam, setUserTeam] = useState([]);

  useEffect(() => {
    const savedSortModel = JSON.parse(localStorage.getItem('sortModel'));
    if (savedSortModel) {
      setSortModel(savedSortModel);
    }
  }, []);

  const handleSortModelChange = (newModel) => {
    setSortModel(newModel);
    localStorage.setItem('sortModel', JSON.stringify(newModel));
  };

  const handleDecline = async (pendingId) => {
    const doctorRef = doc(db, 'doctors', currentUser.uid);
    try {
      await updateDoc(doctorRef, {
        pending: arrayRemove(pendingId)
      });
      setPending(prev => prev.filter(p => p.id !== pendingId));
    } catch (error) {
      console.error("Error removing user ID from pending array:", error);
    }
  };

  useEffect(() => {
    let userTeams;
    const doctor = doctors.find((u) => u.id === currentUser.uid) || {};
    if (doctor.role === "doctor") {
      userTeams = teams.filter((u) => u.doctorId === doctor.id);
    } else {
      const user = teams.find((u) => u.id === currentUser.uid);
      if (user && user.doctorId) {
        userTeams = teams.filter((u) => u.doctorId === user.doctorId);
      } else {
        userTeams = [];
      }
    }
    setUserTeam(userTeams);
  }, [teams, currentUser, doctors]);

  const handleAccept = async (pendingId) => {
    const doctorRef = doc(db, 'doctors', currentUser.uid);
    try {
      await updateDoc(doctorRef, {
        pending: arrayRemove(pendingId),
        clients: arrayUnion(pendingId)
      });
      for (const teamMember of userTeam) {
        const teamMemberRef = doc(db, 'teamMembers', teamMember.id);
        await updateDoc(teamMemberRef, {
          clients: arrayUnion(pendingId)
        });
      }
      setPending(prev => prev.filter(p => p.id !== pendingId));
      window.location.reload();
    } catch (error) {
      console.error("Error processing acceptance:", error);
    }
  };

  // Farklı veritabanlarından pending verilerini çekme
  const fetchPendingFromAllDatabases = async () => {
    try {
      const selectedDb = getSelectedDb(region);
      const pendingCollections = [
        selectedDb.collection('pending'), // Bu veritabanından pending verilerini çek
        // İhtiyacınıza göre diğer veritabanları burada eklenebilir
      ];

      const promises = pendingCollections.map(pendingRef => getDocs(pendingRef));
      const results = await Promise.all(promises);

      const allPending = results.reduce((acc, result) => {
        result.forEach(doc => {
          acc.push({ id: doc.id, ...doc.data() });
        });
        return acc;
      }, []);

      setPending(allPending);

    } catch (error) {
      console.error("Error fetching pending data:", error);
    }
  };

  useEffect(() => {
    
    fetchPendingFromAllDatabases();
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region]);

  const isMediumScreen = useMediaQuery('(max-width:768px)');

  const columnVisibilityModel = {
    email: !isMediumScreen,
    genderType: !isMediumScreen,
    updateData: !isMediumScreen,
  };

  const filteredPending = pending.map((pending, index) => {
    const name = pending.name || t("Nameless");
    const email = pending.email || "";
    if (name.toLowerCase().includes(search) || email.toLowerCase().includes(search)) {
      if (!pending.name || !pending.email) {
        console.error("Kullanıcı bilgileri eksik:", pending.name);
      }
      return {
        ...pending,
        id: pending.id || index,
        name,
        email
      };
    }
    return null;
  }).filter(pending => pending !== null);

  const Theme = createTheme({
    palette: {
      mode: 'light',
    },
    components: {
      MuiDataGrid: {
        styleOverrides: {
          row: {
            borderBottom: '2px solid #E2E2FF',
          },
          root: {
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus': {
              outline: 'none',
            },
            '& .css-yrdy0g-MuiDataGrid-columnHeaderRow': {
              borderBottom: '1px solid #E2E2FF',
            },
            '& .MuiDataGrid-row.Mui-hovered': {
              backgroundColor: '#E2E2FF',
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: '#E2E2FF',
            },
            '& .MuiToolbar-root>p': {
              margin: '0',
            }
          },
        },
      },
    },
  });

  const columns = [
    {
      field: "user",
      headerName: t('patients'),
      sortable: true,
      flex: 2,
      height: 100,
      valueGetter: (params) => `${params.row.name} ${params.row.surname}`,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            <Zoom>
              <img onClick={(event) => { event.stopPropagation(); }} className="cellImg object-fit-cover" src={params.row.profileImageUrl ? params.row.profileImageUrl : avatar} alt="avatar" />
            </Zoom>
            <div className="user-info">
              {params.row.name + " " + params.row.surname}
              <div className="user-email">{params.row.email}</div>
            </div>
          </div>
        );
      },
    },
    {
      field: "genderType",
      headerName: t('gender'),
      flex: 1,
      valueFormatter: (params) => t(`Gender.${params.value}`),
    },
    {
      field: "birthday",
      headerName: t('birthday'),
      flex: 1,
      type: 'date',
      valueGetter: (params) => {
        if (params.row.birthday && params.row.birthday.split("/").length === 3) {
          const dateParts = params.row.birthday.split("/");
          return new Date(`${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`);
        }
        return null;
      },
    },
    {
      field: "action",
      headerName: "",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{ gap: '10px' }}>
            <button
              className="btn"
              style={{
                backgroundColor: '#141517',
                color: 'white',
                marginRight: '15px',
              }}
              onClick={() => handleAccept(params.row.id)}
            >
              {t('Accept')}
            </button>
            <button
              className="btn btn-primary"
              style={{
                backgroundColor: 'white',
                color: 'black',
                border: '1px solid #000',
                marginRight: '15px'
              }}
              onClick={() => handleDecline(params.row.id)}
            >
              {t('Decline')}
            </button>
          </div>
        );
      }
    },
  ];

  return (
    <ThemeProvider theme={Theme}>
      <NavbarMenu />
      <button className="pending-button" onClick={() => setOpen(true)}>
        {/* <img src={bell} alt="Bell Icon" style={{ width: '20px', height: '20px',color:'#000' }} /> */}
        {t('Pending Patients')} {pendingCount > 0 ? `(${pendingCount})` : ''}
      </button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <div style={{ position: 'relative' }}>
            <button 
              onClick={() => setOpen(false)}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                background: 'transparent',
                border: 'none',
                fontSize: '36px',
                cursor: 'pointer',
                
              }}
            >
              &times;
            </button>
            {loading ? (
              <div className="d-flex align-items-center justify-content-center vh-100">
                <CircularProgress />
              </div>
            ) : (
              <div className="bg-datatable">
                {pending.length > 0 ? (
                  <div>
                    <div>
                      <div className="row align-items-center pt-3">
                        <div className="col-12 col-sm-5">
                          <h1 className="d-h1 text-lg font-semibold" style={{ fontFamily: 'Poppins', fontSize: '22px', lineHeight: '50px', marginLeft: '15px' }}>
                            {t('Pending Patients')}:
                            <span style={{
                              display: 'inline-block',
                              color: '#677184',
                              fontFamily: 'Poppins',
                              borderRadius: '50%',
                              width: '24px',
                              height: '24px',
                              textAlign: 'center',
                              lineHeight: '24px',
                              marginLeft: '8px'
                            }}>
                              {pending.length}
                            </span>
                          </h1>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 text-md-end text-start">
                          <div className="pt-4 pe-2">
                            {/* Additional elements if needed */}
                          </div>
                        </div>
                      </div>
                      {filteredPending.length > 0 ? (
                        <DataGrid
                          className="datagrid px-3"
                          columnVisibilityModel={columnVisibilityModel}
                          rows={filteredPending}
                          columns={columns}
                          pageSize={9}
                          rowsPerPageOptions={[9]}
                          rowHeight={65}
                          sortModel={sortModel}
                          onSortModelChange={handleSortModelChange}
                        />
                      ) : (
                        <div className="noResults">{t('noResultsFound')}</div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center" style={{ height: '20vh' }}>
                    <h2 className="text-black">{t('No Pending Patients')}</h2>
                  </div>
                )}
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default Pending;